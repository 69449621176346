import { Injectable } from '@angular/core';
import { AbstractSearchItemsService } from './abstract-search-items.service';
import { SearchItemsAction } from '../../document-search/document-search-item/action/search-items.action';

@Injectable({
  providedIn: 'root'
})
export class DocumentSearchItemsService extends AbstractSearchItemsService {

  constructor(
    private documentSearchItemAction: SearchItemsAction
  ) {
    super();
  }

  public getSearchItemsAction(): SearchItemsAction {
    return this.documentSearchItemAction;
  }

}
