import { Injectable } from '@angular/core';
import { AbstractFilterService } from './abstract-filter.service';
import { TaskFilterAction } from '../../task-search/task-filter/action/task-filter.action';

@Injectable({
  providedIn: 'root'
})
export class TaskFilterService extends AbstractFilterService {

  constructor(
    private taskFilterAction: TaskFilterAction
  ) {
    super ();
  }

  public getFilterAction(): TaskFilterAction {
    return this.taskFilterAction;
  }
}
