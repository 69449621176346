import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../store/model';
import { CurrentContextActionConstant, currentContextReducer, CurrentContextState } from '../reducer/current-context.reducer';
import { PayloadAction } from '../../../../store/payload-action';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class CurrentContextAction {
  private localStore: ObservableStore<CurrentContextState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CURRENT_CONTEXT], currentContextReducer);
  }

  public loadCurrentEntityType(entityType: string): PayloadAction<string> {
    if (!entityType) {
      return this.localStore.dispatch(<PayloadAction<string>> {
        type: CurrentContextActionConstant.LOAD_CURRENT_ENTITY_TYPE_FAILED
      });
    }
    return this.localStore.dispatch(<PayloadAction<string>> {
      type: CurrentContextActionConstant.LOAD_CURRENT_ENTITY_TYPE_SUCCEEDED,
      payload: entityType
    });
  }

  public loadCurrentDomain(currentDomain: string): PayloadAction<string> {
    if (!currentDomain) {
      return this.localStore.dispatch(<PayloadAction<string>> {
        type: CurrentContextActionConstant.LOAD_CURRENT_DOMAIN_FAILED
      });
    }
    return this.localStore.dispatch(<PayloadAction<string>> {
      type: CurrentContextActionConstant.LOAD_CURRENT_DOMAIN_SUCCEEDED,
      payload: currentDomain
    });
  }

  public loadCurrentModule(currentModule: string): PayloadAction<string> {
    if (!currentModule) {
      return this.localStore.dispatch(<PayloadAction<string>> {
        type: CurrentContextActionConstant.LOAD_CURRENT_MODULE_FAILED
      });
    }
    return this.localStore.dispatch(<PayloadAction<string>> {
      type: CurrentContextActionConstant.LOAD_CURRENT_MODULE_SUCCEEDED,
      payload: currentModule
    });
  }

  public removeCurrentEntityType(): PayloadAction<string> {
    return this.localStore.dispatch(<PayloadAction<string>> {
      type: CurrentContextActionConstant.REMOVE_CURRENT_ENTITY_TYPE_SUCCEEDED
    });
  }

  public removeCurrentDomain(): PayloadAction<string> {
    return this.localStore.dispatch(<PayloadAction<string>> {
      type: CurrentContextActionConstant.REMOVE_CURRENT_DOMAIN_SUCCEEDED
    });
  }

  public removeCurrentModule(): PayloadAction<string> {
    return this.localStore.dispatch(<PayloadAction<string>> {
      type: CurrentContextActionConstant.REMOVE_CURRENT_MODULE_SUCCEEDED
    });
  }
}
