import { NgModule } from '@angular/core';
// Angular-redux ecosystem stuff.
// @angular-redux/form and @angular-redux/router are optional
// extensions that sync form and route location state between
// our store and Angular.
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
// import { provideReduxForms } from '@angular-redux/form';
// Redux ecosystem stuff.
import { compose } from 'redux';
import { createLogger } from 'redux-logger';
// import * as persistState from 'redux-localstorage';
// import { adapter } from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
// The top-level reducers
import persistState, { mergePersistedState } from 'redux-localstorage';
import * as adapter from 'redux-localstorage/lib/adapters/localStorage';
import { IAppState } from './model';
import { rootReducer, StoreReducers } from './root.reducer';
import { StoreKeys } from '../core/models/store-keys';

@NgModule({
  imports: [NgReduxModule],
  providers: [],
})
export class StoreModule {
  private storeKeys: string[] = [
    StoreKeys.AUTHENTICATION,
    StoreKeys.GED_CFG_FIELD,
    StoreKeys.WKF_CFG_FIELD,
    StoreKeys.MENU_ITEMS,
    StoreKeys.CURRENT_CONTEXT,
    StoreKeys.SEARCH_ITEMS,
    // StoreKeys.FILTERS,
    // StoreKeys.DOCUMENT_SEARCH_RESULT,
    StoreKeys.ENTITY_SEARCH_ITEMS,
    // StoreKeys.ENTITY_FILTERS,
    // StoreKeys.ENTITY_SEARCH_RESULT,
    StoreKeys.DOMAINS
  ];
  private storeKeysToFilter: string[] = [];
  constructor(
    public ngRedux: NgRedux<IAppState>,
    devTools: DevToolsExtension,
  ) {
    this.storeKeys.forEach((storeKey: StoreKeys) => {
      this.storeKeysToFilter.push(StoreReducers.DYNAMIC_SUB_STORES + '.' + storeKey);
    });
    const reducer = compose(
      mergePersistedState()
    )(rootReducer);
    // const middleware = [];
    const storage = compose(
      filter(this.storeKeysToFilter)
    )(adapter(sessionStorage));

    const sessionStorageEnhancer = compose(
      persistState(storage)
    );
    const enhancers = [];
    enhancers.push(sessionStorageEnhancer);
    if (devTools.isEnabled()) {
      enhancers.push(devTools.enhancer());
    }
    ngRedux.configureStore(
      reducer,
      {},
      [createLogger()],
      enhancers);
  }
}
