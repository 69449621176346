import { ToastaService } from 'ngx-toasta';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, OperatorFunction, pipe, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IAppState } from '../../../../../store/model';
import { CdxFile } from '../../../../models/cdx-file';
import { CdxAttachment } from '../../../../models/cdx-attachment';
import { ConfigurationActions } from '../../../../../modules/configuration/configuration.actions';
import { Url } from '../../../../models/url';
import { Utils } from '../../../../utils/utils';
import { CdxComment, CdxReply } from '../../../../models/cdx-comment';
import { ViewService } from '../../../view/service/view.service';
import { UserSocketHttpResponse } from '../../../../models/user-socket-http-response';
import { SocketService } from '../../../../services/socket/socket.service';
import { AuthenticationService } from '../../../../../modules/authentication/authentication.service';
import { ActivityDiff, CdxActivity } from '../../../../models/cdx-activity';
import { EsPage } from '../../../../models/es-page';
import { CriticalDataService } from '../../../critical-data/service/critical-data.service';
import { CriticalDataAction } from '../../../critical-data/action/critical-data.action';
import { InternalRoutes } from '../../../../models/internal-routes';
import { AbstractDetailsService, DELETE_MODE } from '../abstract-details.service';
import { CurrentCommentService } from '../../../current-comment/service/current-comment.service';
import { TaskSearchResultService } from '../../../search-result/service/task-search-result.service';
import { TaskDetailsAction } from '../../action/task/task-details.action';
import { CdxTask } from '../../../../models/cdx-task';
import { TaskDetailsState } from '../../reducer/task/task-details.reducer';
import { UserCredentials } from '../../../../models/UserCredentials';
import { EsAuthor } from '../../../../models/EsAuthor';
import { DocumentDetailsAction } from '../../action/document/document-details.action';
import { TaskFormService } from '../../../task-form/service/task-form.service';
import { Metadata, MetadataCodes } from '../../../../models/metadata';

@Injectable({
  providedIn: 'root'
})
export class TaskDetailsService extends AbstractDetailsService {

  private static colors = new Map<string, string>();
  private taskSearchResultService: TaskSearchResultService;

  // private searchResultService: SearchResultService;


  static getDocumentTypeColor(typeDoc: string): string {
    if (this.colors.get(typeDoc) != null) {
      return this.colors.get(typeDoc);
    }
    let hash = 0;
    for (let i = 0; i < typeDoc.length; i++) {
      hash = typeDoc.charCodeAt(i) + ((hash << 5) - hash);
    }
    let c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    c = '00000'.substring(0, 6 - c.length) + c;
    this.colors.set(typeDoc, '#' + c);
    return this.colors.get(typeDoc);
  }

  constructor(
    httpClient: HttpClient,
    configAction: ConfigurationActions,
    ngRedux: NgRedux<IAppState>,
    toastaService: ToastaService,
    translateService: TranslateService,
    socketService: SocketService,
    authenticationService: AuthenticationService,
    location: Location,
    router: Router,
    criticalDataService: CriticalDataService,
    currentCommentService: CurrentCommentService,
    private taskFormService: TaskFormService,
    private taskDetailsAction: TaskDetailsAction,
    private documentDetailsAction: DocumentDetailsAction,
    private viewService: ViewService
  ) {
    super(httpClient, configAction, ngRedux, toastaService, translateService, socketService, authenticationService, location, router, criticalDataService, currentCommentService);
  }

  private getApiMockUrl(taskId: string): string { // TODO remove API mock
    return `${this.configAction.get('application.API_MOCK_URL')}/assets/mock/task/task.json`;
  }

  private getMockPdf(): string {
    return `${this.configAction.get('application.API_MOCK_URL')}/assets/mock/images/Modele-de-facture.pdf`;
  }

  /*START getting urls*/
  protected getNexiaObjectDetailsUrl(taskId: string): string {
    return Url.TASKS + taskId + '/' + Url.DETAILS;
  }

  protected getInternalRouteDetailsUrl(taskId: string): string[] {
    return ['/' + InternalRoutes.WORKFLOW + '/' + InternalRoutes.TASKS, taskId, InternalRoutes.DETAILS];
  }

  protected getPostPutCommentUrl(comment: CdxComment): string {
    return Url.TASKS + comment.cdx_doc_id + '/' + Url.COMMENTS;
  }
  protected getLoadDeleteCommentUrl(taskId: string, comId: string): string {
    return Url.TASKS + taskId + '/' + Url.COMMENTS + comId;
  }

  protected getPostPutReplyUrl(taskId: string, comId: string): string {
    return Url.TASKS + taskId + '/' + Url.COMMENTS + comId + '/' + Url.REPLY;
  }
  protected getDeleteReplyUrl(taskId: string, comId: string, replyId: string): string {
    return Url.TASKS + taskId + '/' + Url.COMMENTS + comId + '/' + Url.REPLY + replyId + '/delete';
  }

  protected getHistoryUrl(taskId: string): string {
    return Url.TASKS + taskId + '/' + Url.HISTORY;
  }

  protected getLoadDeleteDownloadAttachmentUrl(taskId: string, attachmentId: string): string {
    return Url.TASKS + taskId + '/' + Url.ATTACHMENT + attachmentId;
  }
  protected getUpdateUploadAttachmentUrl(taskId: string): string {
    return Url.TASKS + taskId + '/' + Url.ATTACHMENT;
  }

  protected getRestoreVersionUrl(taskId: string, numVersion: string): string {
    return Url.TASKS + taskId + '/' + Url.RESTORE_REVISION + numVersion;
  }

  protected getCurrentActivityDiffUrl(taskId: string, evtId: number, entityType: string = null): string {
    return Url.TASKS + taskId + '/' + Url.HISTORY_EVT_REV + evtId;
  }

  protected getUpdateTeamsUrl(taskId: string): string {
    return Url.TASKS + taskId + '/' + Url.TEAMS;
  }

  protected getDeleteNexiaObjectUrl(taskId: string): string {
    return Url.TASKS + taskId;
  }

  protected getHardDeleteNexiaObjectsUrl(): string {
    return Url.DOCUMENTS + Url.DELETE;
  }

  protected getUpdateArchiveUrl(): string {
    throw new Error('NOT implemented');
  }

  protected getMetadataUrl(docId: string): string {
    throw new Error('NOT implemented');
  }
  /*END getting urls*/

  /*START calls to details actions*/
  protected detailsActionLoadCommentStart(): void {
    this.taskDetailsAction.loadCommentStart();
  }
  protected detailsActionLoadCommentSucceeded(comment: CdxComment): void {
    this.taskDetailsAction.loadCommentSucceeded(comment);
  }
  protected detailsActionLoadCommentFailed(error: any): void {
    this.taskDetailsAction.loadCommentFailed(error);
  }

  protected detailsActionLoadHistoryStart(): void {
    this.taskDetailsAction.loadHistoryStart();
  }
  protected detailsActionLoadHistorySucceeded(activities: EsPage<CdxActivity>): void {
    this.taskDetailsAction.loadHistorySucceeded(activities);
  }
  protected detailsActionLoadHistoryFailed(error: any): void {
    this.taskDetailsAction.loadHistoryFailed(error);
  }

  protected detailsActionLoadAttachmentStart(): void {
    this.taskDetailsAction.loadAttachmentStart();
  }
  protected detailsActionLoadAttachmentSucceeded(attachment: CdxAttachment, tempAttachmentId: string): void {
    tempAttachmentId ? this.taskDetailsAction.loadAttachmentSucceeded(attachment, tempAttachmentId) : this.taskDetailsAction.loadAttachmentSucceeded(attachment);
  }
  protected detailsActionLoadAttachmentFailed(error: any): void {
    this.taskDetailsAction.loadAttachmentFailed(error);
  }

  protected detailsActionUploadAttachmentSucceeded(attachment: CdxAttachment): void {
    this.taskDetailsAction.uploadAttachmentSucceeded(attachment);
  }

  protected detailsActionLoadMetaStart(): void {
    throw new Error('NOT implemented');
  }
  protected detailsActionLoadMetaSucceeded(meta: Metadata): void {
    throw new Error('NOT implemented');
  }
  protected detailsActionLoadMetaFailed(error: any): void {
    throw new Error('NOT implemented');
  }

  protected detailsActionLoadCurrentActivityDiffStart(): void {
    this.taskDetailsAction.loadCurrentActivityDiffStart();
  }
  protected detailsActionLoadCurrentActivityDiffSucceeded(currentActivityDiff: ActivityDiff): void {
    this.taskDetailsAction.loadCurrentActivityDiffSucceeded(currentActivityDiff);
  }
  protected detailsActionLoadCurrentActivityDiffFailed(error: any): void {
    this.taskDetailsAction.loadCurrentActivityDiffFailed(error);
  }
  /*END calls to details actions*/

  protected searchResultServiceUpdateDocInPage(id: string, meta: Metadata): void {
    throw new Error('NOT implemented');
  }

  /*START COMMON*/
  public loadTaskDetailsVersion(id: string, versionNumber: number): void {
    try {
      this.taskDetailsAction.loadNexiaObjectDetailsStart();
      super._loadNexiaObjectDetailsVersion(id, versionNumber)
        .subscribe((task: CdxTask) => {
          this.taskDetailsAction.loadNexiaObjectDetailsSucceeded(task, true);
        }, (error) => {
          this.taskDetailsAction.loadNexiaObjectDetailsFailed(error);
        });
    } catch (error) {
      this.taskDetailsAction.loadNexiaObjectDetailsFailed(error);
    }
  }

  public loadReadCriticalFieldValue(id: string, fieldcode: string, versionNumber: number = null): void {
    console.log('loadReadCriticalFieldValue of : id :' + id + ' fieldcode :' + fieldcode);
    try {
      const updateDetails: CdxTask = _.cloneDeep(this.ngRedux.getState().dynamicSubStores.taskDetails.datas.details);
      // traitement de vérification d'existence debut
      if (fieldcode !== CriticalDataAction._ALL && !!updateDetails.cdx_datas[fieldcode]) {
        // champ déjà récupéré
        this._addVisibleCriticalField(fieldcode);
        return;
      }
      // traitement de vérification d'existence fin
      this.taskDetailsAction.loadNexiaObjectDetailsStart();
      this._loadReadCriticalFieldValue(id, fieldcode, versionNumber).subscribe(
        (task) => {
          if (fieldcode !== CriticalDataAction._ALL) {
            updateDetails.cdx_datas[fieldcode] = task.cdx_datas[fieldcode];
            this._addVisibleCriticalField(fieldcode);
          } else {
            updateDetails.cdx_datas = task.cdx_datas;
            this.showAllCriticalFieldsValue();
          }
          this.taskDetailsAction.loadNexiaObjectDetailsSucceeded(updateDetails);
        },
        (error: HttpErrorResponse) => this.taskDetailsAction.loadDetailsFailed(error));
    } catch (error) {
      this.taskDetailsAction.loadNexiaObjectDetailsFailed(error);
    }
  }

  public deleteTask(task: CdxTask, mode: DELETE_MODE = DELETE_MODE.SOFT): Observable<boolean> {

    return this._deleteNexiaObject(task.cdx_id, mode)
      .pipe(
        map((userSocketHttpResponse: UserSocketHttpResponse) => {
          // this.taskSearchResultService.deleteStoreDocument(task.cdx_id);
          this.location.back();
          return true;
        }),
        catchError(err => {
          return of(true);
        })
      );
  }

  public hardDeleteTask(task: CdxTask): Observable<boolean> {
    return this.deleteTask(task, DELETE_MODE.HARD);
  }

  public addComment(comment: CdxComment, token: string): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._addComment(comment, token)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, comment.cdx_doc_id);
          this.loadComment(comment.cdx_doc_id, userSocketHttpResponse.id);
          this.loadTaskHistory(comment.cdx_doc_id);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public updateComment(comment: CdxComment, token: string): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._updateComment(comment, token)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, comment.cdx_doc_id);
          this.loadComment(comment.cdx_doc_id, userSocketHttpResponse.id);
          this.loadTaskHistory(comment.cdx_doc_id);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public loadComment(taskId: string, comId: string): void {
    super._loadComment(taskId, comId);
  }

  public deleteComment(comment: CdxComment, token: string): void {
    this._deleteComment(comment, token)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, comment.cdx_doc_id);
          this.loadComment(comment.cdx_doc_id, userSocketHttpResponse.id);
          this.loadTaskHistory(comment.cdx_doc_id);
        });
  }

  public addReply(taskId: string, commentId: string, reply: CdxReply, token: string): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._addReply(taskId, commentId, token, reply)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, taskId);
          this.loadComment(taskId, userSocketHttpResponse.id);
          this.loadTaskHistory(taskId);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public updateReply(taskId: string, commentId: string, reply: CdxReply, token: string): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._updateReply(taskId, commentId, token, reply)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, taskId);
          this.loadComment(taskId, userSocketHttpResponse.id);
          this.loadTaskHistory(taskId);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public deleteReply(taskId: string, commentId: string, replyId: string, token: string): void {
    this._deleteReply(taskId, commentId, token, replyId)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, taskId);
          this.loadComment(taskId, userSocketHttpResponse.id);
          this.loadTaskHistory(taskId);
        },);
  }

  public loadAttachment(taskId: string, attId: string, tempAttachmentId: string = null): void {
    super._loadAttachment(taskId, attId, tempAttachmentId);
  }

  public deleteAttachment(attachment: CdxAttachment, token: string): void {
    this.taskDetailsAction.deleteAttachmentStart();
    super._deleteAttachment(attachment, token)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, attachment.cdx_doc_id);
          this.loadTaskHistory(attachment.cdx_doc_id);
          this.taskDetailsAction.deleteAttachmentSucceeded(attachment);
        },
        (error) => {
          this.taskDetailsAction.deleteAttachmentFailed(error);
        });
  }

  public downloadAttachment(taskId: string, attId: string, token: string, isThumbnail: boolean): Observable<CdxFile> {
    return super._downloadAttachment(taskId, attId, token, isThumbnail);
  }

  public uploadAttachment(taskId: string, file: File, token: string): void {
    super._uploadAttachment(taskId, file, token);
  }

  public updateAttachment(attachment: CdxAttachment, token: string): void {
    super._updateAttachment(attachment, token);
  }

  public loadTaskHistory(taskId: string): void {
    super._loadNexiaObjectHistory(taskId);
  }

  public updateTaskTeams(task: CdxTask, teamIds: string[]): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._updateTeams(task.cdx_id, task.token, teamIds)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          // we load the document because it is possible that the rights have changed
          this.loadTask(task.cdx_id);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public loadCurrentActivityDiff(taskId: string, evtId: number, isJustOpen: boolean): void {
    console.log('loadCurrentActivityDiff of : task id : ' + taskId + 'event id :' + evtId + ' isJustOpen :' + isJustOpen);
    super._loadCurrentActivityDiff(taskId, evtId, isJustOpen);
  }
  /*END COMMON*/

  /*START SPECIFIC*/
  protected updateToken(headers: HttpHeaders, id: string): void {
    const taskDetailsState: TaskDetailsState = this.ngRedux.getState().dynamicSubStores.taskDetails;
    if (!!taskDetailsState && !!taskDetailsState.datas && !!taskDetailsState.datas.details && taskDetailsState.datas.details.cdx_id === id) {
      this.taskDetailsAction.updateToken(headers);
    }
  }

  private readCriticalParams(readCritical: boolean): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      map((params: HttpParams) => {
        if (readCritical) {
          params = params.append('readCritical', 'true');
        }
        return params;
      })
    );
  }

  private getTaskFile(task: CdxTask, path: string): OperatorFunction<HttpParams, HttpResponse<any>> {
    return switchMap(params => {
      return this.httpClient.get(
        `${Url.getProtectedApiBaseUrl(this.configAction)}${Url.TASKS}${task.cdx_id}/${path}${task.cdx_file.cdx_id}`,
        {
          params: params,
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(task.token)
        });
    });
  }

  private getTaskFormatFile(task: CdxTask, formatName: string, formatFileId: string): OperatorFunction<HttpParams, HttpResponse<any>> {
    return switchMap(params => {
      return this.httpClient.get(
        `${Url.getProtectedApiBaseUrl(this.configAction)}${Url.TASKS}${task.cdx_id}/${Url.FILE}${task.cdx_file.cdx_id}/${Url.FORMATS}${formatName}/${formatFileId}`,
        {
          params: params,
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(task.token)
        });
    });
  }

  public loadTask(id: string): void {
    try {
      this.taskDetailsAction.loadDetailsStart();
      // const params: HttpParams = new HttpParams()
      //   .set('cdx_id', id)
      //   .set('_limit', '1');
      this.httpClient.get<CdxTask>(Url.getWkfProtectedApiBaseUrl(this.configAction) + Url.TASKS + id + '/' + Url.DETAILS)
        .subscribe(
          (task: CdxTask) => {
            this.documentDetailsAction.loadDetailsStart();
            this.viewService.getRecapView(task.cdx_type.code);
            // FIXME remove add of token
            task.token = this.addMockedToken();
            this.documentDetailsAction.loadDetailsSucceeded(task, [], [], new EsPage<CdxActivity>(0, [], 0, 0, [], false));
            this.taskFormService.getTaskForm(task.cdx_wkf.cdx_process.task_instance_id);
            // this.currentCommentService.removeCurrentComment();
            this.taskDetailsAction.loadDetailsSucceeded(task, [], [], new EsPage<CdxActivity>(0, [], 0, 0, [], false));
          },
          (error: HttpErrorResponse) => this.taskDetailsAction.loadDetailsFailed(error));
    } catch (error) {
      this.taskDetailsAction.loadDetailsFailed(error);
    }
  }
  // FIXME to be removed
  private addMockedToken(): string {
    return 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhZG1pbkBheGVzcy5mciIsImlkIjoxMDA0LCJmdW5jdGlvbnMiOlsiQURNSU5DT01NRU5UIiwiVVBEQVRFQ09NTUVOVCIsIkRFTEVURUNPTU1FTlQiLCJERUxFVEUiLCJSRUFEQ09NTUVOVCIsIklOREVYIiwiQ1JFQVRFQVRUQUNITUVOVCIsIlVQREFURUFUVEFDSE1FTlQiLCJSRUFEQVRUQUNITUVOVCIsIlJFQUQiLCJBRE1JTkFUVEFDSE1FTlQiLCJSRUFESElTVE9SWSIsIklOREVYRU5USVRZIiwiUkVBRENSSVRJQ0FMIiwiVVBEQVRFIiwiREVMRVRFQVRUQUNITUVOVCIsIkNSRUFURUNPTU1FTlQiXSwib2JqX2lkIjoieDVobHM0QUJwOTVhUmNzWTlIS2EiLCJleHRlcm5hbF9pZCI6Ing1aGxzNEFCcDk1YVJjc1k5SEthIiwib2JqX3ZlcnNpb24iOiIyIiwiY2R4X3R5cGUiOiJkdF9hdmlzX2RfZWNoZWFuY2VfbWVuc3VlbCIsIm9ial9wcml2YWN5IjoiUFVCTElDIiwib2JqX3R5cGUiOiJjZHhfZG9jdW1lbnQiLCJqdGkiOiI4MDE3OTMwNS0xODY4LTQxMzAtOTdlZS1kNTZlN2RhNjA2NWMiLCJpYXQiOjE2NTU5Njg2ODAsImV4cCI6MTcxOTEwMDgwMH0.ZsjhVJusP68sm1HPiT8qNevBm01lYvLS1BVjST1ogNw';
  }

  // public loadTask(id: string): void { // TODO remove mocked method
  //   try {
  //     this.taskDetailsAction.loadDetailsStart();
  //     // const params: HttpParams = new HttpParams()
  //     //   .set('cdx_id', id)
  //     //   .set('_limit', '1');
  //     this.httpClient.get<TaskDetails>(this.getApiMockUrl(id))
  //       .subscribe(
  //         (taskDetails) => {
  //           this.documentDetailsAction.loadDetailsStart();
  //           this.viewService.getRecapView(taskDetails.details.cdx_type.code);
  //           this.documentDetailsAction.loadDetailsSucceeded(taskDetails.details, null, null, null);
  //           this.taskFormService.getTaskForm(taskDetails.details.cdx_wkf.cdx_process.task_id);
  //           if (taskDetails.attachments != null) {
  //             Utils.orderBy(taskDetails.attachments, ['cdx_creation_date']);
  //           }
  //           if (taskDetails.activities != null) {
  //             Utils.orderBy(taskDetails.activities.content, ['created']);
  //           }
  //           // this.currentCommentService.removeCurrentComment();
  //           this.taskDetailsAction.loadDetailsSucceeded(taskDetails.details, taskDetails.comments, taskDetails.attachments, taskDetails.activities);
  //         },
  //         (error: HttpErrorResponse) => this.taskDetailsAction.loadDetailsFailed(error));
  //   } catch (error) {
  //     this.taskDetailsAction.loadDetailsFailed(error);
  //   }
  // }

  public setTask(task: CdxTask, showBlockInfo?: boolean): void {
    try {
      this.taskDetailsAction.loadDetailsStart();
      if (task === undefined || task === null) {
        throw new ErrorEvent('task cannot be null or undefined');
      }
      this.taskDetailsAction.loadDetailsSucceeded(task, null, null, null);
      if (showBlockInfo !== undefined) {
        this.showHideInfosBlock(showBlockInfo);
      }
    } catch (error) {
      this.taskDetailsAction.loadDetailsFailed(error);
    }
  }

  public setTaskSearchResultService(value: TaskSearchResultService) {
    this.taskSearchResultService = value;
  }

  public remove() {
    this.taskDetailsAction.removeAll();
  }

  public removeAttachmentOfStore(taskId: string, attId: string): void {
    this.taskDetailsAction.deleteAttachmentStart();
    const attachment: CdxAttachment = new CdxAttachment();
    attachment.cdx_id = attId;
    attachment.cdx_doc_id = taskId;
    this.taskDetailsAction.deleteAttachmentSucceeded(attachment);
  }

  public downloadTaskFile(task: CdxTask, path: string, readCritical = false): Observable<CdxFile> {
    try {
      return AbstractDetailsService.query()
        .pipe(
          this.readCriticalParams(readCritical),
          this.getTaskFile(task, path),
          map((response: HttpResponse<any>) => {
            this.updateToken(response.headers, task.cdx_id);
            return Utils.httpResponseToCdxFile(response);
          }, (error: HttpErrorResponse) => {
            console.error(error);
            return of(error);
          }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public downloadTaskFormat(task: CdxTask, formatName: string, formatFileId: string, readCritical = false): Observable<CdxFile> {
    try {
      return AbstractDetailsService.query()
        .pipe(
          this.readCriticalParams(readCritical),
          this.getTaskFormatFile(task, formatName, formatFileId),
          map((response: HttpResponse<any>) => {
            this.updateToken(response.headers, task.cdx_id);
            return Utils.httpResponseToCdxFile(response);
          }, (error: HttpErrorResponse) => {
            console.error(error);
            return of(error);
          }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  // public getTaskPdf(): Observable<Uint8Array> {
  //   try {
  //     return this.httpClient.get(
  //       this.getMockPdf(),
  //       {
  //         observe: 'response', responseType: 'arraybuffer'
  //       })
  //       .pipe(map((response: HttpResponse<any>) => {
  //         const uint8View: Uint8Array = new Uint8Array(response.body);
  //         return uint8View;
  //       }, (error: HttpErrorResponse) => {
  //         console.error(error);
  //         return of(error);
  //       }));
  //   } catch (err) {
  //     console.error(err);
  //     return of(err);
  //   }
  // }

  public getTaskPdf(task: CdxTask, fromButton: boolean): Observable<Uint8Array> {
    try {
      let urlGetPdf = `${Url.getWkfProtectedApiBaseUrl(this.configAction)}${Url.TASKS}${task.cdx_wkf.cdx_process.task_instance_id}/${Url.DOCUMENT_PDF}`;
      if (fromButton) {
        urlGetPdf = urlGetPdf + '?readCritical';
      }

      return this.httpClient.get(
        urlGetPdf,
        {
          observe: 'response', responseType: 'arraybuffer'/*,
          headers: Utils.objectTokenHeader(task.token)*/
        })
        .pipe(map((response: HttpResponse<any>) => {
          this.updateToken(response.headers, task.cdx_wkf.cdx_process.task_instance_id);
          const uint8View: Uint8Array = new Uint8Array(response.body);
          return uint8View;
        }, (error: HttpErrorResponse) => {
          console.error(error);
          return of(error);
        }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public downloadThumb(task: CdxTask): Observable<File> {
    if (!task.cdx_file) {
      return of(null);
    }
    try {
      return this.httpClient.get(
        `${Url.getWkfProtectedApiBaseUrl(this.configAction)}${Url.TASKS}${task.cdx_wkf.cdx_process.task_instance_id}/${Url.THUMB}`, // TODO change for task
        {
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(task.token)
        })
        .pipe(map((response: HttpResponse<any>) => {
          const file: File = Utils.createFile(response);
          return file;
        }, (error: HttpErrorResponse) => {
          console.error(error);
          return of(error);
        }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public showHideInfosBlock(isVisible: boolean): void {
    this.taskDetailsAction.showHideInfoBlockSucceeded(isVisible);
  }

  public claimTask(taskId: string): void {
    const user: UserCredentials = this.authenticationService.getUserCredentials();
    try {
      this.httpClient.post(Url.getCamundaProtectedApiBaseUrl(this.configAction) + Url.TASK + taskId + '/' + Url.CLAIM, {userId: '' + user.id})
        .subscribe(() => {
            this.toastaService.info(this.translateService.instant('TOASTER_MESSAGES.[TASK_DETAILS].CLAIM_SUCCEEDED', {user: EsAuthor.getUserLabel(user)}));
          },
          (error: HttpErrorResponse) => {
            this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.[TASK_DETAILS].CLAIM_FAILED'));
          }
        );
    } catch (e) {
      this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.[TASK_DETAILS].CLAIM_FAILED'));
    }
  }
  /*END SPECIFIC*/
}
