import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../store/model';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';
import { SearchResultPagination } from '../../../models/search-result-pagination';
import {
  searchResultPaginationReducer,
  SearchResultPaginationState,
  SearchResultPaginationStateConstant
} from '../reducer/search-result-pagination.reducer';
import { ObjectType } from '../../../models/ObjectType';

@Injectable({
  providedIn: 'root'
})


export class SearchResultPaginationAction {

  private localStore: ObservableStore<SearchResultPaginationState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.SEARCH_RESULT_PAGINATION], searchResultPaginationReducer);
  }


  public loadSearchResultPagination() {
    this.localStore.dispatch({
      type: SearchResultPaginationStateConstant.SEARCH_RESULT_PAGINATION_LOAD,
    });
  }

  public reinitSearchResultPagination(payload: {-readonly [searchTypeKey in ObjectType]?: SearchResultPagination}) {
    this.localStore.dispatch({
      type: SearchResultPaginationStateConstant.SEARCH_RESULT_PAGINATION_REINIT,
      payload: payload
    });
  }


  public updateSearchResultPagination(payload: {-readonly [searchTypeKey in ObjectType]?: SearchResultPagination}) {

    this.localStore.dispatch({
      type: SearchResultPaginationStateConstant.SEARCH_RESULT_PAGINATION_UPDATE,
      payload: payload
    });
  }
}
