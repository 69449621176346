import { Injectable } from '@angular/core';
import { CurrentCommentAction } from '../action/current-comment.action';
import { CdxComment } from '../../../models/cdx-comment';

@Injectable({
  providedIn: 'root'
})
export class CurrentCommentService {

constructor(private currentCommentAction: CurrentCommentAction) { }

  setCurrentComment(comment: CdxComment, isFromError = false) {
    try {
      this.currentCommentAction.loadCurrentCommentStart();
      if (comment === undefined || comment === null) {
        throw new ErrorEvent('comment cannot be null or undefined');
      }
      this.currentCommentAction.loadCurrentCommentSucceeded(comment, isFromError);
    } catch (error) {
      this.currentCommentAction.loadCurrentCommentFailed(error.message);
    }
  }

  removeCurrentComment() {
      this.currentCommentAction.removeComment();
  }

}
