import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '../tooltip/tooltip.module';
import { MobileSearchFiltersComponent } from './components/mobile-search-filters.component';

@NgModule({
  declarations: [
    MobileSearchFiltersComponent
  ],
  imports: [
    PerfectScrollbarModule,
    CommonModule,
    TooltipModule,
    ClickOutsideModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    MobileSearchFiltersComponent
  ]
})
export class MobileSearchFiltersModule { }
