import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../store/model';
import { DomainActionConstant, domainReducer, DomainState } from '../reducer/domain.reducer';
import { PayloadAction } from '../../../../store/payload-action';
import { DomainItem } from '../../../models/domain-item';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';


@Injectable({
  providedIn: 'root'
})
export class DomainAction {
  private localStore: ObservableStore<DomainState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.DOMAINS], domainReducer);
  }

  public loadWorkspaceStarted(): PayloadAction<DomainItem[]> {
    return this.localStore.dispatch(<PayloadAction<DomainItem[]>> {
      type: DomainActionConstant.LOAD_DOMAIN_ITEMS_START
    });
  }

  public loadWorkspaceSucceeded(wsItems: DomainItem[]): PayloadAction<DomainItem[]> {
    return this.localStore.dispatch(<PayloadAction<DomainItem[]>> {
      type: DomainActionConstant.LOAD_DOMAIN_ITEMS_SUCCEEDED,
      payload: wsItems
    });
  }

  public loadWorkspaceFailed(error: any): PayloadAction<DomainItem[]>  {
    return this.localStore.dispatch(<PayloadAction<DomainItem[]>> {
      type: DomainActionConstant.LOAD_DOMAIN_ITEMS_FAILED,
      error: error
    });
  }

  // public updateSelectedWorkspaceSucceeded(...wsItems: DomainItem[]): PayloadAction<DomainItem[]> {
  //   return this.localStore.dispatch(<PayloadAction<DomainItem[]>> {
  //     type: DomainActionConstant.UPDATE_DOMAINS_SELECTED_SUCCEEDED,
  //     payload: wsItems
  //   });
  // }
}
