import { EsAuthor } from './EsAuthor';
import { SearchPath } from './search-path';

export enum ActivityType {
  Create = 'CREATE',
  Update = 'UPDATE',
  UpdateStatus = 'UPDATE_STATUS',
  AddComment = 'ADD_COMMENT',
  UpdateComment = 'UPDATE_COMMENT',
  DeleteComment = 'DELETE_COMMENT',
  AddCommentReply = 'ADD_COMMENT_REPLY',
  UpdateCommentReply = 'UPDATE_COMMENT_REPLY',
  DeleteCommentReply = 'DELETE_COMMENT_REPLY',
  AddAttachment = 'ADD_ATTACHMENT',
  UpdateAttachment = 'UPDATE_ATTACHMENT',
  DeleteAttachment = 'DELETE_ATTACHMENT',
  AttachTeams = 'ATTACH_TEAMS',
}

export enum OnlyOn {
  DIFF = 'differing',
  NEW = 'onlyOnNew',
  OLD = 'onlyOnOld'
}

export class ActivityDifferingDatas {
  oldData: any;
  newData: any;
  searchPath?: SearchPath;
}

export class Diff {
  differing: {[key: string]: any} = {};
  onlyOnNew: {[key: string]: any} = {};
  onlyOnOld: {[key: string]: any} = {};
}

export class TopicSortedDiff {
  formedLinkData: {};
  formedMetadata: any[];
  formedData: any[];
}

export class ActivityTopicSortedDiff {
  id: number;
  diff: TopicSortedDiff;
}

export class ActivityDiff {
  id: number;
  diff: Diff;
}

export class CdxActivity {
  id: number;
  auditData: string;
  eventType: ActivityType;
  created: string;
  objId: string;
  entityType: string;
  userId: string;
  diff: ActivityDiff;
  cdx_author: EsAuthor;
  externalId: string;
  version: string;
 }

 export class OpenCloseActivityItem {
  activity: CdxActivity;
  isOpened: boolean;
  constructor(activity: CdxActivity, isOpened: boolean) {
    this.activity = activity;
    this.isOpened = isOpened;
  }
 }
