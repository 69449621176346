import { Reducer } from 'redux';
import { DraftDatas } from '../../../../models/DraftDatas';

import { PayloadAction } from '../../../../../store/payload-action';

export class DraftDatasState {
  datas: DraftDatas;
  loading: boolean;
  error: any;
}

export const CURRENT_DRAFT_DATAS_INITIAL_STATE: DraftDatasState = {
  datas: null,
  loading: false,
  error: null,
};

export class DraftDatasConstant {

  // static readonly LOAD_DRAFT_DOCUMENT_START = '[DraftDatas].LOAD_DRAFT_DOCUMENT_START';
  // static readonly LOAD_DRAFT_DOCUMENT_SUCCEEDED = '[DraftDatas].LOAD_DRAFT_DOCUMENT_SUCCEEDED';
  // static readonly LOAD_DRAFT_DOCUMENT_FAILED = '[DraftDatas].LOAD_DRAFT_DOCUMENT_FAILED';

  static readonly LOAD_DRAFT_DATAS_START = '[DraftDatas].LOAD_DRAFT_DATAS_START';
  static readonly LOAD_DRAFT_DATAS_SUCCEEDED = '[DraftDatas].LOAD_DRAFT_DATAS_SUCCEEDED';
  static readonly LOAD_DRAFT_DATAS_FAILED = '[DraftDatas].LOAD_DRAFT_DATAS_FAILED';
  static readonly REMOVE_DRAFT_DATAS_SUCCEEDED = '[DraftDatas].REMOVE_DRAFT_DATAS_SUCCEEDED';

  static readonly ADD_DRAFT_OPTIONS = '[DraftDatas].ADD_DRAFT_OPTIONS';
  static readonly REMOVE_DRAFT_OPTIONS = '[DraftDatas].REMOVE_DRAFT_OPTIONS';

}

export const draftDatasReducer: Reducer<DraftDatasState> = (state: DraftDatasState = CURRENT_DRAFT_DATAS_INITIAL_STATE, action: PayloadAction<DraftDatas>) => {
  switch (action.type) {
    // draft datas
    case DraftDatasConstant.LOAD_DRAFT_DATAS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // case DraftDatasConstant.LOAD_DRAFT_DATAS_SUCCEEDED:
    //   let exists = false;
    //   if (action.payload.id === undefined || state.datas.id === action.payload.id) {
    //     exists = true;
    //   }
    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       id: exists ? state.datas.id : action.payload.id,
    //       isLastStep: action.payload.isLastStep !== undefined ? action.payload.isLastStep : state.datas.isLastStep
    //     },
    //     loading: false,
    //     error: null,
    //   };
    case DraftDatasConstant.LOAD_DRAFT_DATAS_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case DraftDatasConstant.LOAD_DRAFT_DATAS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DraftDatasConstant.REMOVE_DRAFT_DATAS_SUCCEEDED:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
    // // draft document
    // case DraftDatasConstant.LOAD_DRAFT_DOCUMENT_START:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };
    // case DraftDatasConstant.LOAD_DRAFT_DOCUMENT_SUCCEEDED:
    //   return {
    //     ...state,
    //     datas: {
    //       ...state.datas,
    //       draftDocument: action.payload.draftDocument
    //     },
    //     loading: true,
    //     error: null,
    //   };
    // case DraftDatasConstant.LOAD_DRAFT_DOCUMENT_FAILED:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error,
    //   };
      // options :
    case DraftDatasConstant.ADD_DRAFT_OPTIONS:
      return {
        ...state,
        datas : {
          ...state.datas,
          options: action.payload.options,
          state: action.payload.state
        },
        loading: false,
        error: null,
      };
    case DraftDatasConstant.REMOVE_DRAFT_OPTIONS:
      return {
        ...state,
        datas : {
          ...state.datas,
          options: action.payload.options,
          state: action.payload.state
        },
        loading: false,
        error: null,
      };
  }
  return state;
};
