import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Observable, SubscriptionLike } from 'rxjs';
import { select } from '@angular-redux/store';
import { InternalRoutes } from '../../../models/internal-routes';
import { CurrentContextState } from '../../../redux/current-context/reducer/current-context.reducer';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';
import { CurrentModule } from '../../../models/current-module';
import { AuthenticationService } from '../../../../modules/authentication/authentication.service';
import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';

@Component({
  selector: 'app-logo-nexia',
  templateUrl: './logo-nexia.component.html',
  styleUrls: ['./logo-nexia.component.scss']
})
export class LogoNexiaComponent implements OnInit, OnDestroy {

  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CURRENT_CONTEXT]) currentContext$: Observable<CurrentContextState>;

  @Input()
  fullWhiteLogo = false;

  @Input()
  fullBlackLogo = false;

  @Input()
  firstLetterBlackLogo = false;

  private subs: SubscriptionLike[] = [];

  public wkfEnabled = false;
  public hasWkfRole = false;
  public isGed = false;
  InternalRoutes = InternalRoutes;

  constructor(
    private authenticationService: AuthenticationService,
    private configurationActions: ConfigurationActions
  ) {
  }

  ngOnInit(): void {
    this.wkfEnabled = this.configurationActions.get('application.WORKFLOW_ENABLED') === 'true';
    this.hasWkfRole = this.authenticationService.checkForGlobalAccessByRole(AuthenticationService.ROLE_WKF);
    this.subs.push(this.currentContext$.pipe(
      filter((currentContext: CurrentContextState) => !!currentContext && !!currentContext.datas && !!currentContext.datas.currentModule && !currentContext.loading),
    ).subscribe((currentContextState: CurrentContextState) => {
      this.isGed = currentContextState.datas.currentModule === CurrentModule.GED;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }
}
