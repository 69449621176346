import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { TeamsPageConstant, teamsPageReducer, TeamsPageState } from '../reducer/teams-page.reducer';
import { IAppState } from '../../../../../store/model';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { Page } from '../../../../models/page';
import { Team } from '../../../../models/team';

@Injectable({
  providedIn: 'root'
})
export class TeamsPageAction {
  private localStore: ObservableStore<TeamsPageState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.TEAMS_PAGE], teamsPageReducer);
  }

  public loadTeamsPageStarted(): void {
    this.localStore.dispatch({
      type: TeamsPageConstant.LOAD_TEAMS_PAGE_START
    });
  }

  public loadTeamsPageSucceeded(page: Page<Team>): void {
    this.localStore.dispatch({
      type: TeamsPageConstant.LOAD_TEAMS_PAGE_SUCCEEDED,
      payload: page
    });
  }

  public loadTeamsPageFailed(error: any) {
    this.localStore.dispatch({
      type: TeamsPageConstant.LOAD_TEAMS_PAGE_FAILED,
      error: error
    });
  }
}
