import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PdfComponent } from './components/pdf/pdf.component';
import { PdfThumbnailComponent } from './components/pdf-thumbnail/pdf-thumbnail.component';
import { PdfViewerCustomComponent } from './components/pdf-viewer-custom/pdf-viewer-custom.component';
import { PdfViewerExtendComponent } from './components/pdf-viewer-extend/pdf-viewer-extend.component';

export * from './components/pdf-viewer-custom/typings';
@NgModule({
  declarations: [PdfComponent, PdfThumbnailComponent, PdfViewerCustomComponent, PdfViewerExtendComponent],
  imports: [
    CommonModule,
    TranslateModule,
    PerfectScrollbarModule
  ],
  exports: [PdfComponent, PdfThumbnailComponent, PdfViewerCustomComponent, PdfViewerExtendComponent],
  entryComponents: [PdfThumbnailComponent]
})
export class PdfModule {}
