import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import * as _ from 'lodash';

import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';
import { MenuAction } from '../action/Menu.action';
import { IAppState } from '../../../../store/model';
import { AuthenticationService } from '../../../../modules/authentication/authentication.service';
import { MenuItem } from '../../../models/menu-item';
import { Url } from '../../../models/url';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  /* tslint:disable-next-line */
  private apiMenu: string;

  constructor(
    private menuAction: MenuAction,
    private http: HttpClient,
    private ngRedux: NgRedux<IAppState>,
    private configAction: ConfigurationActions,
    private authenticationService: AuthenticationService
  ) {
    this.apiMenu = this.configAction.get('application.API_BACK_URL') + '/' + this.configAction.get('application.API_BACK_NAME') +
      '/' + this.configAction.get('application.MENU');
  }

  loadMenuItems(): Observable<boolean> {
    const isLoaded: Subject<boolean> = new Subject<boolean>();
    this.menuAction.loadStarted();
    try {
      this.http.get<MenuItem[]>(Url.getProtectedApiBaseUrl(this.configAction) + Url.PARAMS + Url.MENU).subscribe((res: MenuItem[]) => {
        console.log('menuItems', res);
        res = res.sort((a: MenuItem, b: MenuItem) => a.sort - b.sort);
        this.menuAction.loadSucceeded(res);
        isLoaded.next(true);
        isLoaded.complete();
      }, (error1: ErrorEvent) => {
        this.menuAction.loadFailed(error1.message);
        isLoaded.next(false);
        isLoaded.complete();
      });
    } catch (err) {
      this.menuAction.loadFailed(err);
      isLoaded.next(false);
      isLoaded.complete();
    }
    return isLoaded.asObservable();
  }

  public calcAccessToDomainTeamsByCurrentDomain(currentDomainCode: string): void {
    if (!currentDomainCode) {
      return;
    }
    const storeMenuItems: MenuItem[] = _.cloneDeep(this.ngRedux.getState().dynamicSubStores.menu_items.datas);
    const domainTeamsMenuItem: MenuItem = storeMenuItems.find((menuItem: MenuItem) => MenuItem.getRouterPathByAction(menuItem.action) === '/domain');
    const isActive = this.authenticationService.checkForDomainTeamManagement(currentDomainCode);
    if (!!domainTeamsMenuItem) {
      domainTeamsMenuItem.active = isActive;
      this.menuAction.loadSucceeded(storeMenuItems);
    }
  }
}
