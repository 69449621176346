import { Reducer } from 'redux';

import { Team } from '../../../../models/team';
import { PayloadAction } from '../../../../../store/payload-action';

export class CurrentTeamState {
  datas: Team;
  loading: boolean;
  error: any;
}

const CURRENT_TEAM_INITIAL_STATE: CurrentTeamState = {
  datas: null,
  loading: false,
  error: null,
};

export class CurrentTeamConstant {
  static readonly LOAD_CURRENT_TEAM_START = '[CurrentTeam].LOAD_CURRENT_TEAM_START';
  static readonly LOAD_CURRENT_TEAM_SUCCEEDED = '[CurrentTeam].LOAD_CURRENT_TEAM_SUCCEEDED';
  static readonly LOAD_CURRENT_TEAM_FAILED = '[CurrentTeam].LOAD_CURRENT_TEAM_FAILED';
  static readonly REMOVE_CURRENT_TEAM = '[CurrentTeam].REMOVE_CURRENT_TEAM';
}

export const currentTeamReducer: Reducer<CurrentTeamState> = (state: CurrentTeamState = CURRENT_TEAM_INITIAL_STATE, action: PayloadAction<Team>) => {
  switch (action.type) {
    case CurrentTeamConstant.LOAD_CURRENT_TEAM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CurrentTeamConstant.LOAD_CURRENT_TEAM_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case CurrentTeamConstant.LOAD_CURRENT_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CurrentTeamConstant.REMOVE_CURRENT_TEAM:
      return {
        ...state,
        datas: null,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
