import { NgModule } from '@angular/core';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

import { ThesaurusModalComponent } from './modal/thesaurus-modal/thesaurus-modal.component';
import { LoadableThesaurusService } from './service/loadable-thesaurus.service';
import { TreeService } from './service/tree.service';
import { ThesaurusTreeComponent } from './components/thesaurus-tree/thesaurus-tree.component';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
  declarations: [
    ThesaurusTreeComponent,
    ThesaurusModalComponent
  ],
  entryComponents: [
    ThesaurusModalComponent
  ],
  imports: [
    PerfectScrollbarModule,
    TooltipModule,
    CommonModule,
    CdkTreeModule,
    MatTreeModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  providers: [
    LoadableThesaurusService,
    TreeService
  ]
})
export class ThesaurusModule {
}
