<div *ngIf="wkfEnabled" class="menu-item-content" [matMenuTriggerFor]="menu">
  <ng-container *ngTemplateOutlet="logoTemplate"></ng-container>
</div>
<div *ngIf="!wkfEnabled" class="menu-item-content">
  <a routerLink="/{{InternalRoutes.SEARCH}}">
    <ng-container *ngTemplateOutlet="logoTemplate"></ng-container>
  </a>
</div>

<mat-menu #menu="matMenu">
  <div class="logo-menuItems">
    <a class="menuItem"
        [ngClass]="{'ged': isGed, 'wf': !isGed, 'selected': isGed}" routerLink="/{{InternalRoutes.SEARCH}}">{{'GLOBAL.ged' | translate}}</a>
    <a class="menuItem"
       *ngIf="hasWkfRole"
       routerLink="/{{InternalRoutes.WORKFLOW}}/{{InternalRoutes.SEARCH}}"
       [ngClass]="{'ged': isGed, 'wf': !isGed, 'selected': !isGed, 'disabled': !hasWkfRole}">
      {{'GLOBAL.workflow' | translate}}
    </a>
    <span class="menuItem"
       *ngIf="!hasWkfRole"
       [ngClass]="{'ged': isGed, 'wf': !isGed, 'selected': !isGed, 'disabled': !hasWkfRole}"
       appTooltip="{{'GLOBAL.noAccess' | translate}}">
       {{'GLOBAL.workflow' | translate}}
    </span>
  </div>
</mat-menu>

<ng-template #logoTemplate>
  <div *ngIf="firstLetterBlackLogo">
    <svg width="22px" height="30px" viewBox="0 0 22 30" xmlns="http://www.w3.org/2000/svg">
      <g id="Group-4" fill="#FFFFFE">
        <polyline id="Fill-1" points="21.9414286 30 19.487619 30 2.57666667 4.52095238 2.45380952 4.54142857 2.45380952 23.02 0 23.02 0 0.226666667 2.45380952 0.226666667 19.3647619 25.6442857 19.487619 25.6238095 19.487619 14.1866667 21.9414286 14.1866667 21.9414286 30"></polyline>
        <polygon id="Fill-3" points="0 27.5828571 2.46 27.5828571 2.46 25.282381 0 25.282381"></polygon>
      </g>
      <polygon id="Fill-5" fill="#19B4B4" points="19.4709524 11.9119048 21.9314286 11.9119048 21.9314286 9.61142857 19.4709524 9.61142857"></polygon>
      <g id="Group-9" transform="translate(19.047619, 0.000000)">
        <polygon id="Fill-6" fill="#F6AE8E" points="0.423333333 7.31047619 2.88380952 7.31047619 2.88380952 5.01 0.423333333 5.01"></polygon>
        <polygon id="Fill-8" fill="#EC6259" points="0.423333333 2.52761905 2.88380952 2.52761905 2.88380952 0.226666667 0.423333333 0.226666667"></polygon>
      </g>
    </svg>
  </div>
  <div *ngIf="fullWhiteLogo">
    <svg width="200px" height="40px" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 327.7 102.7" xml:space="preserve">
        <path class="fullWhite-nexiaFill-st0" d="M82.6,83.1h-5.2L41.9,29.6l-0.3,0v38.8h-5.2V20.6h5.2L77.2,74l0.3,0v-24h5.2V83.1z"/>
      <rect x="36.5" y="73.2" class="fullWhite-nexiaFill-st0" width="5.2" height="4.8"/>
      <rect x="77.4" y="40.3" class="fullWhite-nexiaFill-st1" width="5.2" height="4.8"/>
      <rect x="77.4" y="30.6" class="fullWhite-nexiaFill-st2" width="5.2" height="4.8"/>
      <rect x="77.4" y="20.6" class="fullWhite-nexiaFill-st3" width="5.2" height="4.8"/>
      <path class="fullWhite-nexiaFill-st0" d="M132.5,52.3h-26.9v24.9h31.1v4.2h-36V21.4h35.8v4.2h-30.9v22.5h26.9V52.3z"/>
      <path class="fullWhite-nexiaFill-st0" d="M178.2,47l16.9-25.6h6L181.2,51l20.6,30.4h-5.9l-17.6-26.4l-17.5,26.4h-6L175.4,51l-20-29.5h6L178.2,47z"/>
      <path class="fullWhite-nexiaFill-st0" d="M224.8,81.3h-4.9V21.4h4.9V81.3z"/>
      <path class="fullWhite-nexiaFill-st0" d="M280.3,64.6H254L248,81.3h-5.1l22-59.9h4.6l21.9,59.9h-5.1L280.3,64.6z M255.6,60.2h23.1l-11.4-31.8h-0.3
      L255.6,60.2z"/>
    </svg>
  </div>

  <div *ngIf="fullBlackLogo">
    <svg width="200px" height="40px" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 327.7 102.7" xml:space="preserve">
      <path class="fullBlack-nexiaFill-st0" d="M82.6,83.1h-5.2L41.9,29.6l-0.3,0v38.8h-5.2V20.6h5.2L77.2,74l0.3,0v-24h5.2V83.1z"/>
      <rect x="36.5" y="73.2" class="fullBlack-nexiaFill-st0" width="5.2" height="4.8"/>
      <rect x="77.4" y="40.3" class="fullWhite-nexiaFill-st1" width="5.2" height="4.8"/>
      <rect x="77.4" y="30.6" class="fullWhite-nexiaFill-st2" width="5.2" height="4.8"/>
      <rect x="77.4" y="20.6" class="fullWhite-nexiaFill-st3" width="5.2" height="4.8"/>
      <path class="fullBlack-nexiaFill-st0" d="M132.5,52.3h-26.9v24.9h31.1v4.2h-36V21.4h35.8v4.2h-30.9v22.5h26.9V52.3z"/>
      <path class="fullBlack-nexiaFill-st0" d="M178.2,47l16.9-25.6h6L181.2,51l20.6,30.4h-5.9l-17.6-26.4l-17.5,26.4h-6L175.4,51l-20-29.5h6L178.2,47z"/>
      <path class="fullBlack-nexiaFill-st0" d="M224.8,81.3h-4.9V21.4h4.9V81.3z"/>
      <path class="fullBlack-nexiaFill-st0" d="M280.3,64.6H254L248,81.3h-5.1l22-59.9h4.6l21.9,59.9h-5.1L280.3,64.6z M255.6,60.2h23.1l-11.4-31.8h-0.3
      L255.6,60.2z"/>
    </svg>
  </div>
</ng-template>
