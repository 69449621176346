import { EsAuthor } from './EsAuthor';

export enum LockType {
  OFFICE_365 = 'o365',
  DRAFT = 'draft'
}

export class LockInformation {
  lock_type?: LockType;
  office_id?: string;
  link?: string;
  cdx_author?: EsAuthor;
  cdx_creation_date?: Date;
}

export class Lock {
  locked?: boolean;
  lock_information?: LockInformation;
}
