import { Component, ElementRef, HostListener, HostBinding, Input, OnInit, EventEmitter, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {

  _show = false;

  /* tslint:disable:no-input-rename */

  @Input() data: any;

  /* tslint:enable */
  events = new EventEmitter();

  @HostBinding('class.tooltip') true;
  @HostBinding('style.top') hostStyleTop: string;
  @HostBinding('style.left') hostStyleLeft: string;
  @HostBinding('style.z-index') hostStyleZIndex: number;
  @HostBinding('class.tooltip-show') hostClassShow: boolean;
  @HostBinding('class.tooltip-shadow') hostClassShadow: boolean;

  @HostListener('transitionend', ['$event'])
  transitionEnd(event) {
    if (this.show) {
      this.events.emit('shown');
    }
  }

  @Input() set show(value: boolean) {
    if (value) {
      this.setPosition();
    }
    this._show = this.hostClassShow = value;
  }
  get show(): boolean {
    return this._show;
  }

  get placement() {
    return this.data.options.placement;
  }

  get tooltipTitle() {
    return this.data.options.tooltipTitle;
  }

  get isTwoRows() {
    return this.data.options.twoRows;
  }

  get element() {
    return this.data.element;
  }

  get elementPosition() {
    return this.data.elementPosition;
  }

  get options() {
    return this.data.options;
  }

  get value() {
    return this.data.value;
  }

  get tooltipOffset(): number {
    return Number(this.data.options.offset);
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.setPlacementClass();
    this.setZIndex();
    this.setStyles();
  }

  setPosition(): void {
    const isSvg = this.element instanceof SVGElement;
    const tooltip = this.elementRef.nativeElement;

    const elementHeight = isSvg ? this.element.getBBox().height : this.element.offsetHeight;
    const elementWidth = isSvg ? this.element.getBBox().width : this.element.offsetWidth;
    const tooltipHeight = tooltip.clientHeight;
    const tooltipWidth =  tooltip.clientWidth;
    const scrollY = window.pageYOffset;

    const windowInnerWidth = window.innerWidth;
    const windowInnerHeight = window.innerHeight;

    if (this.placement === 'top') {
      const top = (this.elementPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset);
      const maxLeft = (this.elementPosition.left + elementWidth / 2) + tooltipWidth / 2;
      const minLeft = this.elementPosition.left - tooltipWidth / 2 - this.tooltipOffset;
      if (top <= 0 && (minLeft > 0 && maxLeft < windowInnerWidth)) {
        // move to bottom of element
        this.hostStyleTop = (this.elementPosition.top + scrollY) + elementHeight + this.tooltipOffset + 'px';
        // change class from  tooltip-top to tooltip-bottom
        this.renderer.removeClass(this.elementRef.nativeElement, 'tooltip-top');
        this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-bottom');
      } else if (top > 0) {
        this.hostStyleTop = top + 'px';
      }
    }

    if (this.placement === 'bottom') {
      const top = (this.elementPosition.top + scrollY) + elementHeight + this.tooltipOffset;
      const maxLeft = (this.elementPosition.left + elementWidth / 2) + tooltipWidth / 2;
      const minLeft = this.elementPosition.left - tooltipWidth / 2 - this.tooltipOffset;
      if (top >= windowInnerHeight && (minLeft > 0 && maxLeft < windowInnerWidth)) {
        // move to top of element
        this.hostStyleTop = (this.elementPosition.top + scrollY) + elementHeight + this.tooltipOffset + 'px';
        // change class from  tooltip-bottom to tooltip-top
        this.renderer.removeClass(this.elementRef.nativeElement, 'tooltip-bottom');
        this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-top');
      } else if (top < windowInnerHeight) {
        this.hostStyleTop = top + 'px';
      }
    }

    if (this.placement === 'top' || this.placement === 'bottom') {
      const left = (this.elementPosition.left + elementWidth / 2) - tooltipWidth / 2;
      if (left <= 0) {
        // move to right of element
        this.hostStyleLeft = this.elementPosition.left + elementWidth + this.tooltipOffset + 'px';
        // move to center of element
        this.hostStyleTop = (this.elementPosition.top + scrollY) + elementHeight / 2 - tooltip.clientHeight / 2 + 'px';

        // change class from tooltip-top/bottom to tooltip-right
        if (this.placement === 'top') {
          this.renderer.removeClass(this.elementRef.nativeElement, 'tooltip-top');
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, 'tooltip-bottom');
        }
        this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-right');
      } else  if (left + tooltipWidth >= windowInnerWidth) {
        // move to left of element
        this.hostStyleLeft = this.elementPosition.left - tooltipWidth - this.tooltipOffset + 'px';
        // move to center of element
        this.hostStyleTop = (this.elementPosition.top + scrollY) + elementHeight / 2 - tooltip.clientHeight / 2 + 'px';

        // change class from tooltip-top/bottom to tooltip-left
        if (this.placement === 'top') {
          this.renderer.removeClass(this.elementRef.nativeElement, 'tooltip-top');
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, 'tooltip-bottom');
        }
        this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-left');
      } else {
        // center of element
        this.hostStyleLeft = left + 'px';
      }
    }

    if (this.placement === 'left') {
      const left = this.elementPosition.left - tooltipWidth - this.tooltipOffset;
      if (left <= 0) {
        // move to right
        this.hostStyleLeft = this.elementPosition.left + elementWidth + this.tooltipOffset + 'px';
        // change class from tooltip-left to tooltip-right
        this.renderer.removeClass(this.elementRef.nativeElement, 'tooltip-left');
        this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-right');
      } else {
        this.hostStyleLeft = left + 'px';
      }
    }

    if (this.placement === 'right') {
      const maxLeft = this.elementPosition.left + tooltipWidth + this.tooltipOffset;
      const left = this.elementPosition.left + elementWidth + this.tooltipOffset;
      if (maxLeft >= windowInnerWidth) {
        // move to left
        this.hostStyleLeft = this.elementPosition.left - tooltipWidth - this.tooltipOffset + 'px';
        // change class from tooltip-left to tooltip-right
        this.renderer.removeClass(this.elementRef.nativeElement, 'tooltip-right');
        this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-left');
      } else {
        this.hostStyleLeft = left + 'px';
      }
    }

    if (this.placement === 'left' || this.placement === 'right') {
      // safe
      const top = (this.elementPosition.top + scrollY) + elementHeight / 2 - tooltip.clientHeight / 2;
      this.hostStyleTop = top + 'px';
    }
  }

  setPlacementClass(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-' + this.placement);
  }

  setZIndex(): void {
    if (this.options['z-index'] !== 0) {
      this.hostStyleZIndex = this.options['z-index'];
    }
  }

  setStyles() {
    this.hostClassShadow = this.options['shadow'];
  }

}
