import { FromJson } from './fromJson';

export enum TaskFormValueType {
  INTEGER = 'integer',
  STRING = 'String',
  BOOLEAN =  'Boolean',
  OBJECT = 'Object'
}

export class TaskFormValue extends FromJson {
  value: any;
  type: TaskFormValueType;
  valueInfo: any;
}
