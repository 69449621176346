<ng-container *ngIf="!data.showSearchBtn">
  <h2 mat-dialog-title [innerHtml]="'MODAL.title.thesaurusRegionSelector' | translate"></h2>
  <h4 mat-dialog-title [innerHtml]="data.modalTitle"></h4>
</ng-container>
<ng-container *ngIf="data.showSearchBtn">
  <h2 class="title" *ngIf="data.showSearchBtn" [innerHtml]="'MODAL.title.thesaurusNavigation' | translate"></h2>
  <h4 class="subtitle" *ngIf="data.showSearchBtn" [innerHtml]="data.field.label[currentLanguage]"></h4>
</ng-container>
<mat-dialog-content *ngIf="!loading">
  <app-thesaurus-tree *ngIf="!!data && !!data.thesaurusRootCode && data.regionEntrypointCode"
                      [thesaurusRootCode]="data.thesaurusRootCode"
                      [thesaurusEntrypointCode]="data.regionEntrypointCode"
                      [regionPath]="data.selectedRegionItems"
                      [maxSelection]="data.maxSelection"
                      [autopostage]="data.autopostage"
                      [visibility]="data.visibility"
                      [showSearchBtn]="data.showSearchBtn"
                      [currentField]="data.field"
                      (simpleSelection)="simpleSelection($event)"
                      (multipleSelection)="multipleSelection($event)"
                      (searchResultEmitter)="searchResultEvent($event)">

  </app-thesaurus-tree>
</mat-dialog-content>
<div class="showNoResult-container" *ngIf="this.showNoSearchResult" (click)="closeNoSearchResult()">
  <span class="infoText">{{('THESAURUS.noSearchResult.' + searchResultEmitter.currentPage) | translate: {nodeLabel: searchResultEmitter.nodeLabel} }}</span>
</div>
