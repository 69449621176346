
export enum StepType {
  STEP = 'STEP',
  OPTION = 'OPTION',
  RECAP = 'RECAP'
}

export class Step {
  type: StepType;
  current: boolean;
  visited: boolean;
  code: string;
}
