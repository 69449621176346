import { Reducer } from 'redux';
import { PayloadAction } from '../../../../store/payload-action';
import { SearchResultPagination } from '../../../models/search-result-pagination';
import { ObjectType } from '../../../models/ObjectType';

export class SearchResultPaginationState {
  datas: {-readonly [searchTypeKey in ObjectType]?: SearchResultPagination};
  loading: boolean;
  error: any;
}

const initStore: {-readonly [searchTypeKey in ObjectType]?: SearchResultPagination} = SearchResultPagination.getInitStore();

  const INITIAL_STATE: SearchResultPaginationState = {
    datas: initStore,
    loading: false,
    error: null
  };

  export class SearchResultPaginationStateConstant {

    static readonly SEARCH_RESULT_PAGINATION_LOAD = '[SearchResultPagination].SEARCH_RESULT_PAGINATION_LOAD';
    static readonly SEARCH_RESULT_PAGINATION_REINIT = '[SearchResultPagination].SEARCH_RESULT_PAGINATION_REINIT';
    static readonly SEARCH_RESULT_PAGINATION_UPDATE =  '[SearchResultPagination].SEARCH_RESULT_PAGINATION_UPDATE';

  }


  export const searchResultPaginationReducer: Reducer<SearchResultPaginationState> = (
    state: SearchResultPaginationState = INITIAL_STATE, action: PayloadAction< {-readonly [searchTypeKey in ObjectType]?: SearchResultPagination} >
  ) => {
    switch (action.type) {
      case SearchResultPaginationStateConstant.SEARCH_RESULT_PAGINATION_LOAD:
        return {
          ...state,
          loading: false,
          error: null,
        };

      case SearchResultPaginationStateConstant.SEARCH_RESULT_PAGINATION_REINIT:
      case SearchResultPaginationStateConstant.SEARCH_RESULT_PAGINATION_UPDATE:
        return {
          ...state,
          datas: action.payload,
          loading: false,
          error: null,
        };
    }
    return state;
  };

