import { Reducer } from 'redux';
import { PayloadAction } from '../../../../store/payload-action';
import { MaintenanceInfo } from '../../../models/maintenance-info';


export interface MaintenanceInfoState {
  datas: MaintenanceInfo;
  loading: boolean;
  error: any;
}
const MAINTENANCE_INFO_INITIAL_STATE: MaintenanceInfoState = {
  datas: null,
  loading: false,
  error: null,
};

export class MaintenanceInfoActionConstant {
  static readonly LOAD_MAINTENANCE_INFO_START = 'LOAD_MAINTENANCE_INFO_START';
  static readonly LOAD_MAINTENANCE_INFO_SUCCEEDED = 'LOAD_MAINTENANCE_INFO_SUCCEEDED';
  static readonly LOAD_MAINTENANCE_INFO_FAILED = 'LOAD_MAINTENANCE_INFO_FAILED';
  static readonly REMOVE_MAINTENANCE_INFO_SUCCEEDED = 'REMOVE_MAINTENANCE_INFO_SUCCEEDED';
}

export const maintenanceInfoReducer: Reducer<MaintenanceInfoState> = (state: MaintenanceInfoState = MAINTENANCE_INFO_INITIAL_STATE, action: PayloadAction<MaintenanceInfo>) => {
  switch (action.type) {
    case MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MaintenanceInfoActionConstant.REMOVE_MAINTENANCE_INFO_SUCCEEDED:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
  }
  return state;
};
