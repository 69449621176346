import { Injectable } from '@angular/core';
import { AbstractFilterService } from './abstract-filter.service';
import { FilterAction } from '../../document-search/document-filter/action/filter.action';

@Injectable({
  providedIn: 'root'
})
export class DocumentFilterService extends AbstractFilterService {

  constructor(
    private documentFilterAction: FilterAction
  ) {
    super ();
  }

  public getFilterAction(): FilterAction {
    return this.documentFilterAction;
  }
}
