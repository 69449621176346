import { Reducer } from 'redux';
import { PayloadAction } from '../../../../store/payload-action';

export class CurrentContext {
  currentEntityType: string;
  currentDomain: string;
  currentModule: string;
}

export class CurrentContextState {
  datas: CurrentContext;
  loading: boolean;
  error: any;
}

const CURRENT_CONTEXT_INITIAL_STATE: CurrentContextState = {
  datas: {
    currentEntityType: null,
    currentDomain: null,
    currentModule: null
  },
  loading: false,
  error: null,
};

export class CurrentContextActionConstant {
  static readonly LOAD_CURRENT_ENTITY_TYPE_SUCCEEDED = '[CurrentContext]: LOAD_CURRENT_ENTITY_TYPE_SUCCEEDED';
  static readonly LOAD_CURRENT_ENTITY_TYPE_FAILED = '[CurrentContext]: LOAD_CURRENT_ENTITY_TYPE_FAILED';
  static readonly REMOVE_CURRENT_ENTITY_TYPE_SUCCEEDED = '[CurrentContext]: REMOVE_CURRENT_ENTITY_TYPE_SUCCEEDED';

  static readonly LOAD_CURRENT_DOMAIN_SUCCEEDED = '[CurrentContext]: LOAD_CURRENT_DOMAIN_SUCCEEDED';
  static readonly LOAD_CURRENT_DOMAIN_FAILED = '[CurrentContext]: LOAD_CURRENT_DOMAIN__FAILED';
  static readonly REMOVE_CURRENT_DOMAIN_SUCCEEDED = '[CurrentContext]: REMOVE_CURRENT_DOMAIN__SUCCEEDED';

  static readonly LOAD_CURRENT_MODULE_SUCCEEDED = '[CurrentContext]: LOAD_CURRENT_MODULE_SUCCEEDED';
  static readonly LOAD_CURRENT_MODULE_FAILED = '[CurrentContext]: LOAD_CURRENT_MODULE_FAILED';
  static readonly REMOVE_CURRENT_MODULE_SUCCEEDED = '[CurrentContext]: REMOVE_CURRENT_MODULE_SUCCEEDED';

}

export const currentContextReducer: Reducer<CurrentContextState> = (state: CurrentContextState = CURRENT_CONTEXT_INITIAL_STATE, action: PayloadAction<string>) => {
  switch (action.type) {
    case CurrentContextActionConstant.LOAD_CURRENT_ENTITY_TYPE_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentEntityType: action.payload
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.LOAD_CURRENT_DOMAIN_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentDomain: action.payload
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.LOAD_CURRENT_MODULE_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentModule: action.payload
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.LOAD_CURRENT_ENTITY_TYPE_FAILED:
    case CurrentContextActionConstant.LOAD_CURRENT_DOMAIN_FAILED:
    case CurrentContextActionConstant.LOAD_CURRENT_MODULE_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CurrentContextActionConstant.REMOVE_CURRENT_ENTITY_TYPE_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentEntityType: null
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.REMOVE_CURRENT_DOMAIN_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentDomain: null
        },
        loading: false,
        error: null,
      };
    case CurrentContextActionConstant.REMOVE_CURRENT_MODULE_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          currentModule: null
        },
        loading: false,
        error: null,
      };
  }
  return state;
};
