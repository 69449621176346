import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { RouterPathConstant, RouterPathState, routerPathReducer } from '../reducer/router-path.reducer';

import { IAppState } from '../../../../store/model';
import { RouterPathInfo } from '../../../models/router-path-info';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class RouterPathAction {
  private localStore: ObservableStore<RouterPathState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.ROUTER_PATH], routerPathReducer);
  }

  public loadRouterPathStart(): void {
    this.localStore.dispatch({
      type: RouterPathConstant.LOAD_ROUTER_PATH_START
    });
  }

  public loadRouterPathSucceeded(routerPathInfo: RouterPathInfo): void {
    if (!routerPathInfo.history[0]) {
      this.loadRouterPathFailed('path cannot be null or undefined');
      return;
    }
    this.localStore.dispatch({
      type: RouterPathConstant.LOAD_ROUTER_PATH_SUCCEEDED,
      payload: routerPathInfo
    });
  }

  public loadRouterPathFailed(error: any) {
    this.localStore.dispatch({
      type: RouterPathConstant.LOAD_ROUTER_PATH_FAILED,
      error: error
    });
  }

  public removeAll() {
    this.localStore.dispatch({
      type: RouterPathConstant.REMOVE_ALL_SUCCEEDED
    });
  }

}
