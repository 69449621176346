import { Reducer } from 'redux';
import { DomainItem } from '../../../models/domain-item';
import { PayloadAction } from '../../../../store/payload-action';

export class DomainState {
  datas: DomainItem[];
  loading: boolean;
  error: any;
}

const DOMAIN_INITIAL_STATE: DomainState = {
  datas: [],
  loading: false,
  error: null,
};

export class DomainActionConstant {
  static readonly LOAD_DOMAIN_ITEMS_START = 'LOAD_DOMAIN_ITEMS_START';
  static readonly LOAD_DOMAIN_ITEMS_SUCCEEDED = 'LOAD_DOMAIN_ITEMS_SUCCEEDED';
  static readonly LOAD_DOMAIN_ITEMS_FAILED = 'LOAD_DOMAIN_ITEMS_FAILED';

  // static readonly UPDATE_DOMAINS_SELECTED_SUCCEEDED = 'UPDATE_DOMAINS_SELECTED_SUCCEEDED';
}

export const domainReducer: Reducer<DomainState> = (state: DomainState = DOMAIN_INITIAL_STATE, action: PayloadAction<DomainItem[]>) => {
  switch (action.type) {
    case DomainActionConstant.LOAD_DOMAIN_ITEMS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DomainActionConstant.LOAD_DOMAIN_ITEMS_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case DomainActionConstant.LOAD_DOMAIN_ITEMS_FAILED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: action.error,
      };
    // case DomainActionConstant.UPDATE_DOMAINS_SELECTED_SUCCEEDED:
    //   const datas = [];
    //   state.datas.forEach(item => {
    //     item.current = !!action.payload.find(value => value.code === item.code);
    //     datas.push(item);
    //   });
    //   // state.datas.forEach(item => {
    //   //   item.current = !!action.payload.find(value => value.code === item.code);
    //   // });
    // return {
    //     ...state,
    //     datas: datas,
    //     loading: false,
    //     error: null,
    //   };
  }
  return state;
};
