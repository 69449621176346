import { View } from './view';
import { DraftDocument } from './DraftDocument';
import { DraftState } from '../redux/indexation/service/indexation.service';

export class DraftDatas {
  id: string; // @deprecated
  isLastStep: boolean; // @deprecated
  currentStep: number; // @deprecated
  views: View[] = [];
  draftDocument: DraftDocument;
  state: DraftState;
  options: string[] = [];
}
