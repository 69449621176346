import { Injectable } from '@angular/core';
import { EntitySearchItemsAction } from '../../entity-search/entity-search-item/action/entity-search-items.action';
import { AbstractSearchItemsService } from './abstract-search-items.service';

@Injectable({
  providedIn: 'root'
})
export class EntitySearchItemsService extends AbstractSearchItemsService {

  constructor(
    private entitySearchItemAction: EntitySearchItemsAction
  ) {
    super();
  }

  public getSearchItemsAction(): EntitySearchItemsAction {
    return this.entitySearchItemAction;
  }

}
