import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { EntityCardComponent } from './components/card/entity-card/entity-card.component';
import { EntityCardActionsComponent } from './components/card/entity-card-actions/entity-card-actions.component';
import { EntityBadgeComponent } from './components/entity-badge/entity-badge.component';
import { EntitiesPopupComponent } from './components/entities-popup/entities-popup.component';

@NgModule({
  declarations: [
    EntityCardComponent,
    EntityCardActionsComponent,
    EntityBadgeComponent,
    EntitiesPopupComponent
  ],
    imports: [
      CommonModule,
      TranslateModule,
      RouterModule.forChild([])
    ],
  exports: [
    EntityCardComponent,
    EntityCardActionsComponent,
    EntityBadgeComponent,
    EntitiesPopupComponent
  ]
})
export class EntityModule { }
