import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import * as Hammer from 'hammerjs';

import { HttpErrorInterceptor } from './modules/http-error-interceptor/http-error-interceptor';
import { SocketService } from './core/services/socket/socket.service';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { appRoutes } from './app.routes';
import { ConfigurationModule } from './modules/configuration/configuration.module';
import { CoreModule } from './core/core.module';
import { StoreModule } from './store/store.module';
import { AppComponent } from './app.component';
import { ToasterModule } from './core/ui/toaster/toaster.module';
import { AuthenticationInterceptor } from './modules/authentication/authentication-interceptor';
import { ConfigurationActions } from './modules/configuration/configuration.actions';
import { ErrorComponent } from './core/ui/error/error.component';
import { RouterPathService } from './core/redux/router-path/service/router-path.service';
import { IAppState } from './store/model';
import { MenuService } from './core/redux/menu/service/menu.service';
import { MaintenanceInfoAction } from './core/redux/maintenance-info/action/maintenance-info.action';
import { StoreReducers } from './store/root.reducer';
import { StoreKeys } from './core/models/store-keys';
import { AlertModalModule } from './modules/alert-message/alert-modal.module';
import { Utils } from './core/utils/utils';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'swipe': { direction: Hammer.DIRECTION_ALL }
  };
}
/* tslint:disable:no-unused-variable */

// export function HttpLoaderFactory(http: HttpClient) {
//   return new MultiTranslateHttpLoader(http, [
//     {prefix: './assets/i18n/', suffix: '.json'},
//     {prefix: 'http://localhost:8080/rest/i18n/', suffix: ''},
//   ]);
// }

/** Interceptors list */
const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  }
];

const azureMsalProtectedResourceMap = new Map();
const azureMsalGraphApiUrl = Utils.getApplicationConfigData('AZURE_MSAL_GRAPH_API_URL');
if (Utils.notNullAndNotUndefined(azureMsalGraphApiUrl) && azureMsalGraphApiUrl.length > 0 ) {
  azureMsalProtectedResourceMap.set(azureMsalGraphApiUrl , ['user.read', 'Mail.ReadWrite']);
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent
  ],
    imports: [
        RouterModule.forRoot(appRoutes, {
            onSameUrlNavigation: 'reload'
        }),
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        StoreModule,
        CoreModule,
        ConfigurationModule,
        AuthenticationModule,
        ToasterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        LoggerModule.forRoot({
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.OFF,
            enableSourceMaps: true
        }),
        AlertModalModule,

      MsalModule.forRoot( new PublicClientApplication({
        auth: {
          clientId: Utils.getApplicationConfigData('AZURE_MSAL_AUTH_CLIENTID'), // Application (client) ID from the app registration ex : '64881562-4553-46af-ac8a-89aa603ea1fb'
          // 'https://login.microsoftonline.com/4ca5ac27-3da0-450c-9ddb-b3e19aeccf21', // 'https://login.microsoftonline.com/TENANTID' ex : 'https://login.microsoftonline.com/4ca5ac27-3da0-450c-9ddb-b3e19aeccf21'
          authority: Utils.getApplicationConfigData('AZURE_MSAL_AUTH_AUTHORITY'),
          redirectUri: Utils.getApplicationConfigData('AZURE_MSAL_AUTH_REDIRECT_URI') // 'http://localhost:4200' // This is your redirect URI
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        }
      }), {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read', 'Mail.ReadWrite', 'Mail.Send']
        }
      }, {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: azureMsalProtectedResourceMap
      })
    ],
  providers: [
    ...interceptors,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {

  constructor(
    private maintenanceInfoAction: MaintenanceInfoAction,
    private translateService: TranslateService,
    private configurationAction: ConfigurationActions,
    private ngRedux: NgRedux<IAppState>,
    private socketService: SocketService,
    private menuService: MenuService,
    private routerPathService: RouterPathService
    ) {
      this.configurationAction.loadConfiguration('application')
        .then(() => {
          this.configurationAction.loadConfiguration('version')
          .then(() => {
            this.routerPathService.onInit();
          })
          .then(() => {
            const authenticated$: Observable<boolean> = this.ngRedux.select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.AUTHENTICATION, 'authenticated']);
            authenticated$.subscribe(authenticated => {
            if (authenticated) {
              this.socketService.init();
              // this.menuService.loadMenuItems();
            }
          });
        });
      });
  }
}
