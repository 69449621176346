import { Reducer } from 'redux';
import { PayloadAction } from '../../../../../store/payload-action';
import { TeamUser } from '../../../../models/team-user';
import { Page } from '../../../../models/page';

export class CurrentTeamUsersPageState {
  datas: Page<TeamUser>;
  loading: boolean;
  error: any;
}

const CURRENT_TEAM_USERS_PAGE_INITIAL_STATE: CurrentTeamUsersPageState = {
  datas: null,
  loading: false,
  error: null,
};

export class CurrentTeamUsersPageConstant {
  static readonly LOAD_CURRENT_TEAM_USERS_PAGE_START = '[CurrentTeamUsersPage].LOAD_CURRENT_TEAM_USERS_PAGE_START';
  static readonly LOAD_CURRENT_TEAM_USERS_PAGE_SUCCEEDED = '[CurrentTeamUsersPage].LOAD_CURRENT_TEAM_USERS_PAGE_SUCCEEDED';
  static readonly LOAD_CURRENT_TEAM_USERS_PAGE_FAILED = '[CurrentTeamUsersPage].LOAD_CURRENT_TEAM_USERS_PAGE_FAILED';
}

export const currentTeamUsersPageReducer: Reducer<CurrentTeamUsersPageState> = (state: CurrentTeamUsersPageState = CURRENT_TEAM_USERS_PAGE_INITIAL_STATE, action: PayloadAction<Page<TeamUser>>) => {
  switch (action.type) {
    case CurrentTeamUsersPageConstant.LOAD_CURRENT_TEAM_USERS_PAGE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CurrentTeamUsersPageConstant.LOAD_CURRENT_TEAM_USERS_PAGE_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case CurrentTeamUsersPageConstant.LOAD_CURRENT_TEAM_USERS_PAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
