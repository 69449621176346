import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { Reducer } from 'redux';
import { GedCfgFieldConstant, gedCfgFieldReducer, GedFieldState } from '../../reducer/ged/ged-cfg-field.reducer';
import { Field } from '../../../../models/field';
import { IAppState } from '../../../../../store/model';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { AbstractCfgFieldAction } from '../abstract-cfg-field.action';

@Injectable({
  providedIn: 'root'
})
export class GedCfgFieldAction extends AbstractCfgFieldAction {

  constructor(
    ngRedux: NgRedux<IAppState>
  ) {
    super(ngRedux);
  }

  protected getCfgFieldStorePath(): string[] {
    return [StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.GED_CFG_FIELD];
  }

  protected getCfgFieldReducer(): Reducer<GedFieldState> {
    return gedCfgFieldReducer;
  }

  public loadStarted(): void {
    this._dispatchType(GedCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_START);
  }

  public loadSucceeded(mapField: {[key: string]: Field}): void {
    this._dispatchSuccess(GedCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_SUCCEEDED, mapField);
  }

  public loadFailed(error: any) {
    this._dispatchFail(GedCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_FAILED, error);
  }

  public removeAllStarted(): void {
    this._dispatchType(GedCfgFieldConstant.REMOVE_ALL_CFG_FIELD_START);
  }

  public removeAllSucceeded(): void {
    this._dispatchType(GedCfgFieldConstant.REMOVE_ALL_CFG_FIELD_SUCCEEDED);
  }

  public removeAllFailed(error: any): void {
    this._dispatchFail(GedCfgFieldConstant.REMOVE_ALL_CFG_FIELD_FAILED, error);
  }

}
