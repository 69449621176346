import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ToastaService } from 'ngx-toasta';
import { NgRedux } from '@angular-redux/store';
import { forkJoin, Observable, of, OperatorFunction, pipe } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';
import { IAppState } from '../../../../store/model';
import { Url } from '../../../models/url';
import { EsPage } from '../../../models/es-page';
import { Utils } from '../../../utils/utils';
import { Field } from '../../../models/field';
import { WkfFieldState } from '../reducer/wkf/wkf-cfg-field.reducer';
import { ListValueForAutocomplete } from '../../../models/list-value-for-autocomplete';
import { GedFieldState } from '../reducer/ged/ged-cfg-field.reducer';
import { SearchPath } from '../../../models/search-path';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractCfgFieldService {

  protected static readonly FIELD_PAGE_SIZE = 24;
  protected static readonly PATTERN_PARAM = '_pattern';
  protected static readonly EXCLUDE_PARAM = '_exclude';
  static readonly I18N_SUFFIX = 'i18n';
  static readonly CODE_SUFFIX = 'code';

  protected apiConfigGetFields: string;

  protected static query(): Observable<HttpParams> {
    const httpParams = new HttpParams();
    return of(httpParams);
  }

  // protected static pathToCode(path: string): string {
  //   const newPath = AbstractCfgFieldService.removeSuffixFromPath(path);
  //   return newPath.substring(newPath.lastIndexOf('.') + 1);
  // }

  protected static removeSuffixFromPath(path: string): string {
    let newPath = path;
    const fieldCode = path.substring(path.indexOf('cdx_datas.') + 10);
    if (fieldCode.indexOf('.') > 0) {
      newPath = path.substring(0, path.lastIndexOf('.'));
    }
    return newPath;
  }

  constructor(
    protected http: HttpClient,
    protected configAction: ConfigurationActions,
    protected ngRedux: NgRedux<IAppState>,
    protected toastaService: ToastaService
  ) {
    this.init();
  }

  /*START getting urls*/
  protected abstract getApiBaseUrl(): string;
  /*END getting urls*/
  /*START calling actions*/
  protected abstract cfgFieldActionLoadStarted(): void;
  protected abstract cfgFieldActionLoadSucceeded(keyValue: {[key: string]: Field}): void;
  protected abstract cfgFieldActionLoadFailed(error: any): void;
  protected abstract cfgFieldActionRemoveAllStarted(): void;
  protected abstract cfgFieldActionRemoveAllSucceeded(): void;
  /*END calling actions*/
  /*START getting fields store*/
  protected abstract getCfgFieldStore(): Observable<GedFieldState | WkfFieldState>;
  protected abstract getCfgFieldStoreState(): GedFieldState | WkfFieldState;
  /*END getting fields store*/

  /*START methods called from abstract && extenders*/
  protected _getStoreFieldByCode(fieldsState: GedFieldState | WkfFieldState, fieldCode: string): Field {
    if (!!fieldsState && !!fieldsState.datas) {
      return fieldsState.datas[fieldCode];
    }
    return null;
  }
  /*END methods called from abstract && extenders*/

  /*START methods called from abstract*/

  private init(): void {
    this.apiConfigGetFields = this.getApiBaseUrl();
  }

  private _loadFields(...fields: string[]): Observable<{ [key: string]: Field }> {
    this.cfgFieldActionLoadStarted();
    try {
      const observable = this._requestFields(fields);
      observable
        .subscribe((keyValueList: { [key: string]: Field }) => {
          this.cfgFieldActionLoadSucceeded(keyValueList);
        }, (response: HttpErrorResponse) => {
          this.cfgFieldActionLoadFailed(response.error);
        });
      return observable;
    } catch (error) {
      this.cfgFieldActionLoadFailed(error);
    }
  }

  private _httpRequestFields(fields: string[]): Observable<Object> {
    const fieldJoin = fields.join();
    const httpParams = (new HttpParams()).set('code', fieldJoin);
    const objectObservable = this.http.get(this.apiConfigGetFields, {params: httpParams});
    return objectObservable;
  }

  private _requestFields(fields: string[]): Observable<{[key: string]: Field}> {
    return this._httpRequestFields(fields)
      .pipe(
        map((body: any) => {
          const keyValueList: { [key: string]: Field } = {};
          Object.keys(body).forEach(code => {
            if (!!body[code]) {
              keyValueList[code] = Field.fromJson(body[code], Field);
            }
          });
          return keyValueList;
        })
      );
  }

  /*END methods called from abstract*/

  /*START public methods*/
  public loadFields(...fields: string[]): Observable<{ [p: string]: Field }> {
    let firstPass = true;
    fields = fields.filter((value, index, self) => self.indexOf(value) === index && value.length);
    if (!fields.length) {
      return of({});
    }
    // TODO to be removed after API is ready to send the error on every field...
    const cfgFieldStoreState: GedFieldState | WkfFieldState = this.getCfgFieldStoreState();
    if (!!cfgFieldStoreState && !!cfgFieldStoreState.error) {
      this.cfgFieldActionLoadStarted();
      this.toastaService.error(cfgFieldStoreState.error.detail);
      this.cfgFieldActionLoadSucceeded(cfgFieldStoreState.datas);
    }
    const observable = this.getCfgFieldStore().pipe(
      filter((state: GedFieldState | WkfFieldState) => undefined === state || (!state.loading && state.error == null)),
      tap((state: GedFieldState | WkfFieldState) => {
        if (!state) {
          if (firstPass) {
            firstPass = false;
            this._loadFields(...fields);
          }
          return;
        }
        const existingFields = state.datas;
        const unknowFields = [];
        for (let index = 0; index < fields.length; index++) {
          const fieldCode = fields[index];
          if (undefined === existingFields[fieldCode]) {
            unknowFields.push(fieldCode);
          }
        }
        if (firstPass && unknowFields.length > 0) {
          firstPass = false;
          this._loadFields(...unknowFields);
        }
      })
    );

    observable.subscribe();
    // l'observable retourné :
    return observable.pipe(
      filter((state: GedFieldState | WkfFieldState) => undefined !== state && !state.loading && state.error == null), // chargé, sans erreur
      map((state: GedFieldState | WkfFieldState) => state.datas),
      map(fieldMap => {  // contient uniquement les champs requêtés
        const result: {[key: string]: Field} = {};
        fields.forEach(fieldCode => {
          result[fieldCode] = fieldMap[fieldCode];
        });
        return result;
      }),
      filter(fieldMap => { // contient tous les champs requêtés
        const foundedFields: Field[] = Object.values(fieldMap);
        return foundedFields.length > 0 && !foundedFields.some(value => !value);
      }),
      take(1) // one shot
    ); // pie
  }

  public loadFieldsFromPath(...paths: string[]): Observable<{ [p: string]: Field }> {
    if (paths.length === 0) {
      return of({});
    }
    const codePathMap: { [p: string]: string } = {};
    // paths.forEach(path => codePathMap[AbstractCfgFieldService.pathToCode(path)] = path);
    paths.forEach(path => codePathMap[new SearchPath(path).code] = path);
    return this.loadFields(
      ...Object.keys(codePathMap))
      .pipe(
        map((fieldMap: { [p: string]: Field }) => {
          const pathFieldMap: { [p: string]: Field } = {};
          Object.keys(fieldMap).forEach(code => pathFieldMap[codePathMap[code]] = fieldMap[code]);
          return pathFieldMap;
        })
      );
  }

  public removeFields(): void {
    this.cfgFieldActionRemoveAllStarted();
    this.cfgFieldActionRemoveAllSucceeded();
  }

  // TODO see if needs WKF cfgFieldsService also, if not, to be moved in gedFieldsService

  public searchFieldListValues(fieldCode: string, pattern = '', excludes: string[], size: number = AbstractCfgFieldService.FIELD_PAGE_SIZE, page: number = 0): Observable<EsPage<ListValueForAutocomplete>> {
    return AbstractCfgFieldService.query()
      .pipe(
        // this.coordParams(size, page),
        this.setPattern(pattern),
        this.excludes(excludes),
        this.requestFieldList(fieldCode)
      );
  }

  public getFieldListValues(fieldCode: string, fieldListValues: string[]): Observable<ListValueForAutocomplete[]> {
    const requests$: Observable<ListValueForAutocomplete>[] = [];
    fieldListValues.forEach((value: string) => {
      if (Utils.notNullAndNotUndefined(value)) {
        const fieldListValue = this.getFieldListValue(fieldCode, value)
          .pipe(catchError(err => {
            return of({
              value: value,
              label: value,
              status: 'DELETED'
            } as ListValueForAutocomplete);
          }))
        ;
        requests$.push(fieldListValue);
      }
    });
    return forkJoin(requests$);
  }

  public getFieldListValue(fieldCode: string, fieldListValues: string): Observable<ListValueForAutocomplete> {
    try {
      return AbstractCfgFieldService.query().pipe(
        this.setListValueToParams(fieldListValues),
        this.requestFieldListValueObject(fieldCode)
      );
    } catch (e) {
      return of(e);
    }
  }

  private setListValueToParams(fieldListValue: string): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      map((params: HttpParams) => {
        if (!!fieldListValue) {
          params = params.append('value', fieldListValue);
        }
        return params;
      })
    );
  }

  private requestFieldListValueObject(fieldCode: string): OperatorFunction<HttpParams, ListValueForAutocomplete> {
    return switchMap(params => {
      return this.http.get<ListValueForAutocomplete>(this.apiConfigGetFields + fieldCode + '/' + Url.LIST_ITEM, { params : params});
    });
  }

  private setPattern(pattern: string = ''): OperatorFunction<HttpParams, HttpParams> {
    return map((params: HttpParams) => params.append(AbstractCfgFieldService.PATTERN_PARAM, pattern));
  }

  private excludes(excludeValues: string[]): OperatorFunction<HttpParams, HttpParams> {
    return map((params: HttpParams) => {
      let excludeValueList = '';
      if (excludeValues.length) {
        excludeValues.forEach((excludedValue: string, index: number) => {
          excludeValueList = excludeValueList + excludedValue;
          if (index < excludeValues.length - 1) {
            excludeValueList = excludeValueList + ',';
          }
        });
      }
      return params.append(AbstractCfgFieldService.EXCLUDE_PARAM, excludeValueList);
    });
  }

  private requestFieldList(fieldCode: string): OperatorFunction<HttpParams, EsPage<ListValueForAutocomplete>> {
    return switchMap(params => {
      return this.http.get<EsPage<ListValueForAutocomplete>>(this.apiConfigGetFields + fieldCode + '/' + Url.LIST, { params });
    });
  }
  /*END public methods*/
}
