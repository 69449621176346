import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizeFirstLetterPipe } from './capitalize-firstLetter.pipe';

@NgModule({
  declarations: [CapitalizeFirstLetterPipe],
  imports: [
    CommonModule
  ],
  exports: [CapitalizeFirstLetterPipe]
})
export class CapitalizeFirstLetterModule { }
