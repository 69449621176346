import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { CdxComment } from '../../../models/cdx-comment';
import { IAppState } from '../../../../store/model';
import {
  CurrentCommentConstant, CurrentCommentContainer,
  currentCommentReducer,
  CurrentCommentState
} from '../reducer/current-comment.reducer';
import { StoreKeys } from '../../../models/store-keys';
import { StoreReducers } from '../../../../store/root.reducer';

@Injectable({
  providedIn: 'root'
})
export class CurrentCommentAction {
  private localStore: ObservableStore<CurrentCommentState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CURRENT_COMMENT], currentCommentReducer);
  }

  public loadCurrentCommentStart(): void {
    this.localStore.dispatch({
      type: CurrentCommentConstant.LOAD_CURRENT_COMMENT_START
    });
  }

  public loadCurrentCommentSucceeded(comment: CdxComment, isFromError: boolean): void {
    if (!comment) {
      this.loadCurrentCommentFailed('comment cannot be null or undefined');
      return;
    }
    const currentCommentContainer: CurrentCommentContainer = new CurrentCommentContainer(comment, isFromError);
    this.localStore.dispatch({
      type: CurrentCommentConstant.LOAD_CURRENT_COMMENT_SUCCEEDED,
      payload: currentCommentContainer
    });
  }

  public loadCurrentCommentFailed(error: any) {
    this.localStore.dispatch({
      type: CurrentCommentConstant.LOAD_CURRENT_COMMENT_FAILED,
      error: error
    });
  }

  public removeComment() {
    this.localStore.dispatch({
      type: CurrentCommentConstant.REMOVE_CURRENT_COMMENT_SUCCEEDED
    });
  }


}
