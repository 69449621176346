import { Injectable } from '@angular/core';
import { MaintenanceInfoAction } from '../action/maintenance-info.action';
import { MaintenanceInfo, MaintenanceType } from '../../../models/maintenance-info';
import { AuthGard } from '../../../../app.guards';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceInfoService {
  private authGuard: AuthGard;

  constructor(
    private maintenanceInfoAction: MaintenanceInfoAction
  ) {}

  public loadMaintenanceInfo(maintenanceInfo: MaintenanceInfo): void {
    this.maintenanceInfoAction.loadMaintenanceInfoSucceeded(maintenanceInfo);

    if (maintenanceInfo.type === MaintenanceType.MAINTENANCE_OFF) {
      this.authGuard.setAppLoaded(false);
    }
  }

  public removeMaintenanceInfo(): void {
    this.maintenanceInfoAction.removeMaintenanceInfoSucceeded();
  }

  public setAuthGuard(authGuard: AuthGard): void {
    this.authGuard = authGuard;
  }

}
