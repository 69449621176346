import { NgRedux } from '@angular-redux/store';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IAppState } from '../../../../store/model';
import { Url } from '../../../models/url';
import { DomainState } from '../reducer/domain.reducer';
import { DomainItem } from '../../../models/domain-item';
import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';
import { CfgDocumentInfoService } from '../../document-info/service/cfg-document-info.service';
import { DomainAction } from '../action/domain.action';
import { CurrentContextService } from '../../current-context/service/current-context.service';
import { MenuService } from '../../menu/service/menu.service';
import { DocumentFilterService } from '../../filter/service/document-filter.service';
import { EntityFilterService } from '../../filter/service/entity-filter.service';
import { TaskFilterService } from '../../filter/service/task-filter.service';
import { DocumentSearchItemsService } from '../../search-item/service/document-search-item.service';
import { EntitySearchItemsService } from '../../search-item/service/entity-search-items.service';
import { TaskSearchItemsService } from '../../search-item/service/task-search-item.service';
import { SearchResultPaginationService } from '../../search-result-pagination/service/search-result-pagination.service';
import { DocumentDetailsService } from '../../details/service/document/document-details.service';
import { TaskDetailsService } from '../../details/service/task/task-details.service';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  current: DomainItem;
  constructor(
    private documentFilterService: DocumentFilterService,
    private entityFilterService: EntityFilterService,
    private taskFilterService: TaskFilterService,
    private domainAction: DomainAction,
    private menuService: MenuService,
    private currentContextService: CurrentContextService,
    private httpClient: HttpClient,
    private documentDetailsService: DocumentDetailsService,
    private documentSearchItemsService: DocumentSearchItemsService,
    private entitySearchItemsService: EntitySearchItemsService,
    private taskSearchItemsService: TaskSearchItemsService,
    private taskDetailsService: TaskDetailsService,
    private cfgDocumentInfoService: CfgDocumentInfoService,
    private searchResultServicePagination: SearchResultPaginationService,
    private configAction: ConfigurationActions,
    private ngRedux: NgRedux<IAppState>,
    private translateService: TranslateService
  ) {
  }

  private createDomainItem(response: any[], responseItem: any): DomainItem {
    const domainItem: DomainItem = new DomainItem();
    domainItem.code = responseItem.code ;
    // domainItem.label = 'lbl_' + responseItem.code ;
    domainItem.label = this.translateService.instant('domain.'+responseItem.code+'.label');
    // domainItem.text = 'txt_' + responseItem.code ;
    domainItem.text = this.translateService.instant('domain.'+responseItem.code+'.text');
    domainItem.entityType = responseItem.entityType.code ;
    domainItem.region = responseItem.region;
    domainItem.entrypoint = responseItem.entrypoint;
    // domainItem.current = response.indexOf(responseItem) === 0;
    return domainItem;
  }

  public loadWorkspaceItems(): Observable<boolean> {
    const isLoaded: Subject<boolean> = new Subject<boolean>();
    try {
      this.domainAction.loadWorkspaceStarted();
      const wsItems: DomainItem[] = [];
      this.httpClient.get(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOMAINS)
        .subscribe((response: any[]) => {
          response.forEach(element => {
            const item = this.createDomainItem(response, element);
            wsItems.push(item);
          });
          let currentDomainCode: string = this.currentContextService.getCurrentDomain();
          if (!currentDomainCode && wsItems.length) {
            currentDomainCode = wsItems[0].code;
            this.currentContextService.setCurrentDomain(currentDomainCode);
          }
          this.menuService.calcAccessToDomainTeamsByCurrentDomain(currentDomainCode);
          this.domainAction.loadWorkspaceSucceeded(wsItems);
          isLoaded.next(true);
          isLoaded.complete();
        },
        (error: HttpErrorResponse) => {
          this.domainAction.loadWorkspaceFailed(error);
          isLoaded.next(false);
          isLoaded.complete();
        });
      } catch (error) {
        this.domainAction.loadWorkspaceFailed(error);
        isLoaded.next(false);
        isLoaded.complete();
        console.log('error ', error);
      }
    return isLoaded.asObservable();
   }

  public setCurrentWorkspace(...workspaceItems: DomainItem[]) {
    this.resetStore();
    this.currentContextService.setCurrentDomain(workspaceItems[0].code);
    this.menuService.calcAccessToDomainTeamsByCurrentDomain(workspaceItems[0].code);
    // this.domainAction.updateSelectedWorkspaceSucceeded(...workspaceItems);
  }

  private resetStore(): void {
    this.documentFilterService.removeAllFilters();
    this.entityFilterService.removeAllFilters();
    this.taskFilterService.removeAllFilters();
    this.documentSearchItemsService.removeAllSearchItems();
    this.entitySearchItemsService.removeAllSearchItems();
    this.taskSearchItemsService.removeAllSearchItems();
    this.documentDetailsService.remove();
    this.taskDetailsService.remove();
    this.cfgDocumentInfoService.removeAllCfgDocumentInfo();
    this.searchResultServicePagination.reinitSearchResultPagination();
  }

  public getDomains():  DomainItem[] {
    const domainState: DomainState = this.ngRedux.getState().dynamicSubStores.domains;
    if (!domainState) {
      return [];
    }
    return domainState.datas;
  }
  /**
   * @deprecated
   */
  getCurrentDomain(): DomainItem {
    const domainState: DomainState = this.ngRedux.getState().dynamicSubStores.domains;
    const currentDomainCode: string = this.ngRedux.getState().dynamicSubStores.currentContext.datas.currentDomain;
    domainState.datas.forEach( domainItem => {
      if ( domainItem.code === currentDomainCode ) {
        this.current = domainItem ;
        return;
      }});
    return this.current;
  }

}
