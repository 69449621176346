import { HttpResponse } from '@angular/common/http';
import { SocketData } from './SocketData';

export class UserSocketHttpResponse {
  id: string;
  socketData: SocketData;
  httpResponse: HttpResponse<any>;

  constructor(id: string, socketData: SocketData, httpResponse: HttpResponse<any>) {
    this.id = id;
    this.socketData = socketData;
    this.httpResponse = httpResponse;
  }
}
