import { Reducer } from 'redux';
import { PayloadAction } from '../../../../store/payload-action';
import { Utils } from '../../../utils/utils';

export class CriticalData {
  viewCriticalFields: string[];
  visibleCriticalFields: string[];
  visibleCriticalPdf?: boolean;
}

export class CriticalDataState {
  datas: CriticalData;
  loading: boolean;
  error: any;
}

const CRITICAL_DATA_INITIAL_STATE: CriticalDataState = {
  datas: null,
  loading: false,
  error: null,
};

export class CriticalDataActionConstant {
  static readonly LOAD_CRITICAL_STARTED = '[CriticalData]: LOAD_CRITICAL_STARTED';
  static readonly LOAD_CRITICAL_DATA_SUCCEEDED = '[CriticalData]: LOAD_CRITICAL_DATA_SUCCEEDED';
  static readonly LOAD_VIEW_CRITICAL_DATA_SUCCEEDED = '[CriticalData]: LOAD_VIEW_CRITICAL_DATA_SUCCEEDED';
  static readonly LOAD_CRITICAL_DATA_ALL_SUCCEEDED = '[CriticalData]: LOAD_CRITICAL_DATA_ALL_SUCCEEDED';
  static readonly LOAD_CRITICAL_PDF_SUCCEEDED = '[CriticalData]: LOAD_CRITICAL_PDF_SUCCEEDED';
  static readonly REMOVE_CRITICAL_DATA_SUCCEEDED = '[CriticalData]: REMOVE_CRITICAL_DATA_SUCCEEDED';
  static readonly REMOVE_VIEW_CRITICAL_DATA_SUCCEEDED = '[CriticalData]: REMOVE_VIEW_CRITICAL_DATA_SUCCEEDED';
  static readonly SET_CRITICAL_DATA_TO_NULL_SUCCEEDED = '[CriticalData]: SET_CRITICAL_DATA_TO_NULL_SUCCEEDED';

}

export const criticalDataReducer: Reducer<CriticalDataState> = (state: CriticalDataState = CRITICAL_DATA_INITIAL_STATE, action: PayloadAction<CriticalData>) => {
  switch (action.type) {
    case CriticalDataActionConstant.LOAD_CRITICAL_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CriticalDataActionConstant.LOAD_CRITICAL_DATA_SUCCEEDED:
      const visibleCriticalFields: string[] = Utils.notNullAndNotUndefined(state.datas) && Utils.notNullAndNotUndefined(state.datas.visibleCriticalFields) ? [...state.datas.visibleCriticalFields] : [];
      visibleCriticalFields.push(...action.payload.visibleCriticalFields);
      return {
        ...state,
        datas: {
          ...state.datas,
          visibleCriticalFields
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.LOAD_VIEW_CRITICAL_DATA_SUCCEEDED:
      // let viewCriticalFields: string[] = Utils.notNullAndNotUndefined(state.datas) && Utils.notNullAndNotUndefined(state.datas.viewCriticalFields) ? [...state.datas.viewCriticalFields] : [];
      // viewCriticalFields = [...viewCriticalFields, ...action.payload.viewCriticalFields];
      return {
        ...state,
        datas: {
          ...state.datas,
          viewCriticalFields: action.payload.viewCriticalFields
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.LOAD_CRITICAL_DATA_ALL_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          visibleCriticalFields: action.payload.visibleCriticalFields
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.LOAD_CRITICAL_PDF_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          visibleCriticalPdf: action.payload.visibleCriticalPdf
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.REMOVE_CRITICAL_DATA_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          visibleCriticalFields: []
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.REMOVE_VIEW_CRITICAL_DATA_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          viewCriticalFields: []
        },
        loading: false,
        error: null,
      };
    case CriticalDataActionConstant.SET_CRITICAL_DATA_TO_NULL_SUCCEEDED:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
  }
  return state;
};
