import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../../store/model';
import { FilterActionConstant, filterReducer, FilterState } from '../reducer/filter.reducer';
import { SearchItem } from '../../../../models/search-item';
import { Aggregate } from '../../../../models/aggregate';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class FilterAction {

  private localStore: ObservableStore<FilterState>;


  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.FILTERS], filterReducer);
  }

  public addFilterStarted(): void {
    this.localStore.dispatch({
      type: FilterActionConstant.ADD_FILTER_ITEMS_START
    });
  }

  public addFilterSucceeded(filterItem: SearchItem, agg: Aggregate): void {
    this.localStore.dispatch({
      type: FilterActionConstant.ADD_FILTER_ITEMS_SUCCEEDED,
      payload: {
        filterItems: [filterItem],
        lastAgg: agg}
    });
  }

  public addFilterFailed(error: any) {
    this.localStore.dispatch({
      type: FilterActionConstant.ADD_FILTER_ITEMS_FAILED,
      error: error
    });
  }

  public removeFilterStarted(): void {
    this.localStore.dispatch({
      type: FilterActionConstant.REMOVE_FILTER_ITEM_STARTED
    });
  }

  public removeFilterSucceeded(filterItem: SearchItem) {
    this.localStore.dispatch({
      type: FilterActionConstant.REMOVE_FILTER_ITEM_SUCCEEDED,
      payload: {
        filterItems: [filterItem],
        lastAgg: null}
    });
  }

  public removeFiltersAndKeepLastAgg(): void {
    this.localStore.dispatch({
      type: FilterActionConstant.REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED
    });
  }

  public removeFilterFailed(error: any) {
    this.localStore.dispatch({
      type: FilterActionConstant.REMOVE_FILTER_ITEM_FAILED,
      error: error
    });
  }


  public removeAllFiltersSucceeded() {
    this.localStore.dispatch({
      type: FilterActionConstant.REMOVE_ALL_FILTER_ITEMS_SUCCEEDED
    });
  }


  updateFilterStarted() {
    this.localStore.dispatch({
      type: FilterActionConstant.UPDATE_FILTER_ITEMS_START
    });
  }

  updateFilterSucceeded(filterItem: SearchItem, curAggregate: Aggregate) {
    this.localStore.dispatch({
      type: FilterActionConstant.UPDATE_FILTER_ITEMS_SUCCEEDED,
      payload: {
        filterItems: [filterItem],
        lastAgg: curAggregate}
    });
  }

  updateFilterFailed(error: any) {
    this.localStore.dispatch({
      type: FilterActionConstant.UPDATE_FILTER_ITEMS_FAILED,
      error: error
    });
  }
}
