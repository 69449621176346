import { Reducer } from 'redux';
import { PayloadAction } from '../../../../store/payload-action';
import { View } from './../../../models/view';

export interface ViewState {
  datas: {[key: string]: View};
  loading: boolean;
  error: any;
}

const VIEW_INITIAL_STATE: ViewState = {
  datas: {},
  loading: false,
  error: null,
};

export class ViewConstant {
  static readonly ADD_VIEW_START = '[View].ADD_VIEW_START';
  static readonly ADD_VIEW_SUCCEEDED = '[View].ADD_VIEW_SUCCEEDED';
  static readonly ADD_VIEW_FAILED = '[View].ADD_VIEW_FAILED';

  static readonly REMOVE_ALL_VIEWS_START = '[View].REMOVE_ALL_VIEWS_START';
  static readonly REMOVE_ALL_VIEWS_SUCCEEDED = '[View].REMOVE_ALL_VIEWS_SUCCEEDED';
  static readonly REMOVE_ALL_VIEWS_FAILED = '[View].REMOVE_ALL_VIEWS_FAILED';
}

export const viewReducer: Reducer<ViewState> = (state: ViewState = VIEW_INITIAL_STATE, action: PayloadAction<{[key: string]: View}>): ViewState => {
  switch (action.type) {
    // ADD MULTIPLE
    case ViewConstant.ADD_VIEW_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ViewConstant.ADD_VIEW_SUCCEEDED:
      return {
        ...state,
        datas: {...state.datas, ...action.payload},
        loading: false,
        error: null,
      };
    case ViewConstant.ADD_VIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    // REMOVE ALL
      case ViewConstant.REMOVE_ALL_VIEWS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ViewConstant.REMOVE_ALL_VIEWS_SUCCEEDED:
      return {
        ...state,
        datas: {},
        loading: false,
        error: null,
      };
    case ViewConstant.REMOVE_ALL_VIEWS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
