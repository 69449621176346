import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { ViewConstant, ViewState, viewReducer } from '../reducer/view.reducer';
import { View } from '../../../models/view';
import { IAppState } from '../../../../store/model';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class ViewAction {
  private viewStore: ObservableStore<ViewState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.viewStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.VIEW], viewReducer);
  }

  public loadViewStart(): void {
    this.viewStore.dispatch({
      type: ViewConstant.ADD_VIEW_START
    });
  }

  public loadViewSucceeded(mapView: {[key: string]: View}): void {
    this.viewStore.dispatch({
      type: ViewConstant.ADD_VIEW_SUCCEEDED,
      payload: mapView
    });
  }

  public loadViewFailed(error: any): void {
    this.viewStore.dispatch({
      type: ViewConstant.ADD_VIEW_FAILED,
      error: error
    });
  }


  public removeAllViewsStart(): void {
    this.viewStore.dispatch({
      type: ViewConstant.REMOVE_ALL_VIEWS_START
    });
  }

  public removeAllViewsSucceeded(): void {
    this.viewStore.dispatch({
      type: ViewConstant.REMOVE_ALL_VIEWS_SUCCEEDED
    });
  }

  public removeAllViewsFailed(error: any): void {
    this.viewStore.dispatch({
      type: ViewConstant.REMOVE_ALL_VIEWS_FAILED,
      error: error
    });
  }

}
