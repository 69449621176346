import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../../store/model';
import { ListIndexationConstant, listIndexationReducer, ListIndexationState } from '../reducer/list-indexation.reducer';
import { EsPage } from '../../../../models/es-page';
import { DraftDocument } from '../../../../models/DraftDocument';
import { Aggregate } from '../../../../models/aggregate';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class ListIndexationAction {
  private localStore: ObservableStore<ListIndexationState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.LIST_INDEXATION], listIndexationReducer);
  }

  public loadStarted(): void {
    this.localStore.dispatch({
      type: ListIndexationConstant.LOAD_LIST_INDEXATION_START
    });
  }

  public loadSucceeded(page: EsPage<DraftDocument>): void {
    this.localStore.dispatch({
      type: ListIndexationConstant.LOAD_LIST_INDEXATION_SUCCEEDED,
      payload: page
    });
  }

  public loadFailed(error: any) {
    this.localStore.dispatch({
      type: ListIndexationConstant.LOAD_LIST_INDEXATION_FAILED,
      error: error
    });
  }

  public loadAggregateStart() {
    this.localStore.dispatch({
       type: ListIndexationConstant.LOAD_AGGREGATE_START
    });
  }

  public loadAggregateFailed(error: any) {
    this.localStore.dispatch({
       type: ListIndexationConstant.LOAD_AGGREGATE_FAILED,
      error: error
    });
  }

  public loadAggregateSucceeded(agg: Aggregate) {
    const page: EsPage<DraftDocument> = new EsPage<DraftDocument>(0, [], 0, 0, [agg], false);
    this.localStore.dispatch({
      type: ListIndexationConstant.LOAD_AGGREGATE_SUCCEEDED,
      payload: page
    });
  }
}
