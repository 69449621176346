import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutModule } from '../../core/ui/layout/layout.module';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { AlertModalService } from './service/alert-modal.service';

@NgModule({
  declarations: [AlertModalComponent],
  exports: [
    AlertModalComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    MatDialogModule,
    TranslateModule
  ],
  providers: [
    AlertModalService
  ]
})
export class AlertModalModule {
}
