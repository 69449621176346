import { Reducer } from 'redux';
import { SearchItem } from '../../../../models/search-item';
import { Aggregate } from '../../../../models/aggregate';
import { PayloadAction } from '../../../../../store/payload-action';


export interface TaskFilterDatas {
  filterItems: SearchItem[];
  lastAgg: Aggregate;
}
export interface TaskFilterState {
  datas: TaskFilterDatas;
  loading: boolean;
  error: any;

}

const FILTER_ITEMS_INITIAL_STATE: TaskFilterState = {
  datas: {
    filterItems: [],
    lastAgg: null
  },
  loading: false,
  error: null
};

export class TaskFilterActionConstant {
  static readonly ADD_FILTER_ITEMS_START = '[FilterTask].ADD_FILTER_ITEMS_START';
  static readonly ADD_FILTER_ITEMS_SUCCEEDED = '[FilterTask].ADD_FILTER_ITEMS_SUCCEEDED';
  static readonly ADD_FILTER_ITEMS_FAILED = '[FilterTask].ADD_FILTER_ITEMS_FAILED';

  static readonly UPDATE_FILTER_ITEMS_START = '[FilterTask].UPDATE_FILTER_ITEMS_START';
  static readonly UPDATE_FILTER_ITEMS_SUCCEEDED = '[FilterTask].UPDATE_FILTER_ITEMS_SUCCEEDED';
  static readonly UPDATE_FILTER_ITEMS_FAILED = '[FilterTask].UPDATE_FILTER_ITEMS_FAILED';

  static readonly REMOVE_FILTER_ITEM_STARTED = '[FilterTask].REMOVE_FILTER_ITEM_STARTED';
  static readonly REMOVE_FILTER_ITEM_SUCCEEDED = '[FilterTask].REMOVE_FILTER_ITEM_SUCCEEDED';
  static readonly REMOVE_FILTER_ITEM_FAILED = '[FilterTask].REMOVE_FILTER_ITEM_FAILED';

  static readonly REMOVE_ALL_FILTER_ITEMS_SUCCEEDED = 'REMOVE_ALL_FILTER_ITEMS_SUCCEEDED';
  static readonly REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED = 'REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED';

}

export const taskFilterReducer: Reducer<TaskFilterState> = (state: TaskFilterState = FILTER_ITEMS_INITIAL_STATE, action: PayloadAction<TaskFilterDatas>) => {
  switch (action.type) {
    case TaskFilterActionConstant.ADD_FILTER_ITEMS_START:
    case TaskFilterActionConstant.UPDATE_FILTER_ITEMS_START:
    case TaskFilterActionConstant.REMOVE_FILTER_ITEM_STARTED:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TaskFilterActionConstant.ADD_FILTER_ITEMS_SUCCEEDED:
      const add = state.datas;
      if (!action.payload.filterItems[0].operator) {
        add.filterItems = add.filterItems.concat(action.payload.filterItems[0]);
      } else {
        const existingFilterItemRange = add.filterItems.find(existingFilterItem => existingFilterItem.operator === action.payload.filterItems[0].operator && existingFilterItem.path === action.payload.filterItems[0].path);
        if (!existingFilterItemRange) {
          add.filterItems = add.filterItems.concat(action.payload.filterItems[0]);
        } else {
          add.filterItems[add.filterItems.indexOf(existingFilterItemRange)] = action.payload.filterItems[0];
        }
      }
      add.lastAgg = action.payload.lastAgg;
      return {
        ...state,
        datas: add,
        loading: false,
        error: null,
      };
    case TaskFilterActionConstant.UPDATE_FILTER_ITEMS_SUCCEEDED:
      const toReplace: SearchItem = action.payload.filterItems[0];
      let found = false;
      let searchItems = state.datas.filterItems.map(aFilterItem => {
        if (aFilterItem.path === toReplace.path && aFilterItem.operator === toReplace.operator) {
          found = true;
          return toReplace;
        } else {
          return aFilterItem;
        }
      });
      if (!found) {
        searchItems = [...searchItems, toReplace];
      }
      return {
        ...state,
        datas: {
          ...state.datas,
          filterItems: searchItems,
          lastAgg: action.payload.lastAgg
        },
        loading: false,
        error: null,
      };
      case TaskFilterActionConstant.ADD_FILTER_ITEMS_FAILED:
      case TaskFilterActionConstant.UPDATE_FILTER_ITEMS_FAILED:
      case TaskFilterActionConstant.REMOVE_FILTER_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TaskFilterActionConstant.REMOVE_ALL_FILTER_ITEMS_SUCCEEDED:
      return {
        ...state,
        datas: {
          filterItems: [],
          lastAgg: null
        },
        loading: false,
        error: null,
      };
    case TaskFilterActionConstant.REMOVE_FILTERS_AND_KEEP_LAST_AGG_SUCCEEDED:
      return {
        ...state,
        datas: {
          ...state.datas,
          filterItems: []
        },
        loading: false,
        error: null
      };
    case TaskFilterActionConstant.REMOVE_FILTER_ITEM_SUCCEEDED:
      const filterItem: SearchItem = action.payload.filterItems[0];
      let oldLastAgg = state.datas.lastAgg;
      let remove: SearchItem[];
      if (!filterItem.operator) {
        remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.value !== filterItem.value);
      } else {
        remove = state.datas.filterItems.filter(item => item.path !== filterItem.path || item.operator !== filterItem.operator);
      }
      if (remove.length === 0) {
        oldLastAgg = null;
      }
      return {
        ...state,
        datas: {
          filterItems: remove,
          lastAgg: oldLastAgg
        },
        loading: false,
        error: null,
      };
  }
  return state;
};
