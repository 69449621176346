import { NgRedux, select } from '@angular-redux/store';
import { map } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastaService } from 'ngx-toasta';
import { Injectable } from '@angular/core';
import { ConfigurationActions } from '../../../../../modules/configuration/configuration.actions';
import { Field } from '../../../../models/field';
import { IAppState } from '../../../../../store/model';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { WkfFieldState } from '../../reducer/wkf/wkf-cfg-field.reducer';
import { AbstractCfgFieldService } from '../abstract-cfg-field.service';
import { WkfCfgFieldAction } from '../../action/wkf/wkf-cfg-field.action';
import { SearchPath } from '../../../../models/search-path';
import { GedCfgFieldService } from '../ged/ged-cfg-field.service';

@Injectable({
  providedIn: 'root'
})
export class WkfCfgFieldService extends AbstractCfgFieldService {

  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.WKF_CFG_FIELD]) wkfCfgFields$: Observable<WkfFieldState>;

  constructor(
    http: HttpClient,
    configAction: ConfigurationActions,
    ngRedux: NgRedux<IAppState>,
    toastaService: ToastaService,
    private wkfCfgFieldAction: WkfCfgFieldAction,
    private gedCfgFieldService: GedCfgFieldService
  ) {
    super(http, configAction, ngRedux, toastaService);
  }

  protected getApiBaseUrl(): string {
    return `${this.configAction.get('application.API_MOCK_URL')}/assets/mock/fields/wkf-fields.json`;
  }

  protected getCfgFieldStore(): Observable<WkfFieldState> {
    return this.wkfCfgFields$;
  }

  protected cfgFieldActionLoadStarted(): void {
    this.wkfCfgFieldAction.loadStarted();
  }
  protected cfgFieldActionLoadSucceeded(val: {[key: string]: Field}): void {
    this.wkfCfgFieldAction.loadSucceeded(val);
  }
  protected cfgFieldActionLoadFailed(error: any): void {
    this.wkfCfgFieldAction.loadFailed(error);
  }
  protected cfgFieldActionRemoveAllStarted(): void {
    this.wkfCfgFieldAction.removeAllStarted();
  }
  protected cfgFieldActionRemoveAllSucceeded(): void {
    this.wkfCfgFieldAction.removeAllSucceeded();
  }

  protected getCfgFieldStoreState(): WkfFieldState {
    return this.ngRedux.getState().dynamicSubStores.wkfCfgField;
  }

  public get(fieldCode: string): Field {
    const fieldsState: WkfFieldState = this.getCfgFieldStoreState();
    return this._getStoreFieldByCode(fieldsState, fieldCode);
  }

  public loadFieldsFromPath(...paths): Observable<{ [p: string]: Field }> {
    let loadGedCfgFiedlds: Observable<{ [p: string]: Field }> = of({});
    let loadWkfCfgFiedlds: Observable<{ [p: string]: Field }> = of({});
    const gedCfgFieldsCode: string[] = [];
    const wkfCfgFieldsCode: string[] = [];
    paths.forEach((path: string) => {
      const searchPath: SearchPath = new SearchPath(path);
      searchPath.isWkf ? wkfCfgFieldsCode.push(path) : gedCfgFieldsCode.push(path);
    });
    if (gedCfgFieldsCode.length) {
      loadGedCfgFiedlds = this.gedCfgFieldService.loadFieldsFromPath(...gedCfgFieldsCode);
    }
    if (wkfCfgFieldsCode.length) {
      loadWkfCfgFiedlds = super.loadFieldsFromPath(...wkfCfgFieldsCode);
    }
    return forkJoin([loadGedCfgFiedlds, loadWkfCfgFiedlds]).pipe(
      map(([gedCfgFields, wkfCfgFields]: [{ [p: string]: Field }, { [p: string]: Field }]) => {
        return {...gedCfgFields, ...wkfCfgFields};
      })
    );
  }
}
