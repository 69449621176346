import { ToastaService } from 'ngx-toasta';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';

import { IAppState } from '../../store/model';
import { AuthenticationActionConstants, authenticationReducer, AuthenticationState } from './authentication.reducer';
import { StoreKeys } from '../../core/models/store-keys';
import { StoreReducers } from '../../store/root.reducer';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationAction {

  private localStore: ObservableStore<AuthenticationState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private translateService: TranslateService,
    private toastaService: ToastaService,
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.AUTHENTICATION], authenticationReducer);
  }

  public loadAuthenticationStart(): void {
    this.localStore.dispatch({
      type: AuthenticationActionConstants.LOAD_AUTH_START
    });
  }

  public loadAuthenticationSucceeded(role: string): void {
    this.localStore.dispatch({
      type: AuthenticationActionConstants.LOAD_AUTH_SUCCEEDED,
      payload: { role }
    });
  }

  public loadAuthenticationFailed(error: any) {
    this.localStore.dispatch({
      type: AuthenticationActionConstants.LOAD_AUTH_FAILED,
      error: error
    });
    this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.ERROR'));
  }

  public logoutSucceeded(): void {
    this.localStore.dispatch({
      type: AuthenticationActionConstants.LOGOUT_SUCCEEDED
    });
  }
}
