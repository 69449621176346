import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../store/model';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';
import { TaskFormConstant, taskFormReducer, TaskFormState } from '../reducer/task-form.reducer';
import { TaskForm } from '../../../models/task-form';

@Injectable({
  providedIn: 'root'
})
export class TaskFormAction {
  private taskFormStore: ObservableStore<TaskFormState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.taskFormStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.TASK_FORM], taskFormReducer);
  }

  public loadTaskFormStart(): void {
    this.taskFormStore.dispatch({
      type: TaskFormConstant.ADD_TASK_FORM_START
    });
  }

  public loadTaskFormSucceeded(taskForm: TaskForm): void {
    this.taskFormStore.dispatch({
      type: TaskFormConstant.ADD_TASK_FORM_SUCCEEDED,
      payload: taskForm
    });
  }

  public loadTaskFormFailed(error: any): void {
    this.taskFormStore.dispatch({
      type: TaskFormConstant.ADD_TASK_FORM_FAILED,
      error: error
    });
  }


  public removeTaskFormSucceeded(): void {
    this.taskFormStore.dispatch({
      type: TaskFormConstant.REMOVE_TASK_FORM_SUCCEEDED
    });
  }
}
