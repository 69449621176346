export enum MaintenanceType {
  MAINTENANCE_PRE = 'MAINTENANCE_PRE',
  MAINTENANCE_ON = 'MAINTENANCE_ON',
  MAINTENANCE_OFF = 'MAINTENANCE_OFF'
}
export class MaintenanceInfo {
  type: MaintenanceType;
  ttl: number;
  date: number;
  message?: string;
}
