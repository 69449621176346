import { Aggregate } from './aggregate';

export class EsPage<T> {
  constructor(
    public numberOfElements: number,
    public content: T[],
    public size: number,
    public page,
    public aggs: Aggregate[],
    public lowerBounded: boolean
  ) {
  }
}
