import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../../store/model';
import { EntitySearchItemsState, EntitySearchItemsActionConstant, entitySearchItemsReducer } from '../reducer/entity-search-items.reducer';
import { SearchItem } from '../../../../models/search-item';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class EntitySearchItemsAction {

  private localStore: ObservableStore<EntitySearchItemsState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.ENTITY_SEARCH_ITEMS], entitySearchItemsReducer);
  }

  public addSearchItemStarted() {
    this.localStore.dispatch({
      type: EntitySearchItemsActionConstant.ADD_ENTITY_SEARCH_ITEM_START
    });
  }

  public addSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch({
      type: EntitySearchItemsActionConstant.ADD_ENTITY_SEARCH_ITEM_SUCCEEDED,
      payload: searchItem
    });
  }

  public addSearchItemFailed(error: any) {
    this.localStore.dispatch({
      type: EntitySearchItemsActionConstant.ADD_ENTITY_SEARCH_ITEM_FAILED,
      error: error
    });
  }

  public removeSearchItemStarted() {
    this.localStore.dispatch({
      type: EntitySearchItemsActionConstant.REMOVE_ENTITY_SEARCH_ITEM_START
    });
  }

  public removeSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch({
      type: EntitySearchItemsActionConstant.REMOVE_ENTITY_SEARCH_ITEM_SUCCEEDED,
      payload: searchItem
    });
  }

  public removeSearchItemFailed(error: any) {
    this.localStore.dispatch({
      type: EntitySearchItemsActionConstant.REMOVE_ENTITY_SEARCH_ITEM_FAILED,
      error: error
    });
  }

  public removeAllSearchItemsSucceeded() {
    this.localStore.dispatch({
      type: EntitySearchItemsActionConstant.REMOVE_ALL_ENTITY_SEARCH_ITEMS_SUCCEEDED
    });
  }
}
