import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ScrollEventService {
  private _scrollEvent$: Subject<any> = new Subject<any>();
  public scrollEvent$: Observable<any> = this._scrollEvent$.asObservable();

  public onScroll(scrollEvent: any): void {
    this._scrollEvent$.next(scrollEvent);
  }

  public resetScrollEvent(): void {
    this._scrollEvent$.next({x: 0, y: 0});
  }
}
