import { FromJson } from './fromJson';
import { TaskFormSelectValue } from './task-form-select-value';
import { TaskFormValidationData } from './task-form-validation-data';

export enum TaskFormFieldType {
  TEXTFIELD = 'textfield',
  CHECKBOX =  'checkbox',
  NUMBER = 'number',
  SELECT = 'select',
  BUTTON = 'button'
}

export class TaskFormField extends FromJson {
  label: string;
  type: TaskFormFieldType;
  id: string;
  key: string;
  description?: string;
  defaultValue?: any;
  disabled?: boolean;
  validate?: TaskFormValidationData;
  properties?: any;
  values?: TaskFormSelectValue[];
  value?: any;
}
