export enum FormatEditionType {
  EMAIL = 'email',
  URL = 'url',
  REGEX = 'regex',
  PHONE = 'phone',
  POS = 'pos',
  SPOS = 'spos'
}

export enum FormatDisplayType {
  EMAIL = 'email',
  URL = 'url',
  PHONE = 'phone',
  VIEWER = 'viewer',
  XSLT = 'xslt'
}

export enum ValueTransform {
  UC = 'uc',
  LC = 'lc',
  C = 'c'
}

export class EditionValidation {
  type?: FormatEditionType;
  value?: string;
  errMsg?: {[key: string]: string};
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export class EditionFormat {
  validation?: EditionValidation;
  transform?: ValueTransform;
}

export class LocaleOptions {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  useGrouping?: boolean;
}

export class DisplayFormat {
  type?: FormatDisplayType;
  useLocale?: boolean;
  localeOptions: LocaleOptions;
  hidden: boolean;
}

export class FieldFormat {
  edition: EditionFormat;
  display: DisplayFormat;
}

export class PhoneValue {
  number: string;
  nationalNumber: string;
  countryCode: string;
  dialCode: string;
  e164Number: string;
  internationalNumber: string;
}
