import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { cfgDocumentInfoReducer, CgfDocumentInfoConstant, DocumentInfoState } from '../reducer/cfg-document-info.reducer';
import { IAppState } from '../../../../store/model';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';


// static readonly ADD_CFG_DOCUMENT_INFO_START = 'ADD_CFG_DOCUMENT_INFO_START';
// static readonly ADD_CFG_DOCUMENT_INFO_SUCCEEDED = 'ADD_CFG_DOCUMENT_INFO_SUCCEEDED';
// static readonly ADD_CFG_DOCUMENT_INFO_FAILED = 'ADD_CFG_DOCUMENT_INFO_FAILED';
//
// static readonly REMOVE_ALL_CFG_DOCUMENT_INFO_START = 'REMOVE_ALL_CFG_DOCUMENT_INFO_START';
// static readonly REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED = 'REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED';
// static readonly REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED = 'REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED';
@Injectable({
  providedIn: 'root'
})
export class CfgDocumentInfoAction {
  private cfgDocumentInfoStore: ObservableStore<DocumentInfoState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.cfgDocumentInfoStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CFG_DOCUMENT_INFO], cfgDocumentInfoReducer);
  }

  public loadDocumentInfoStart(): void {
    this.cfgDocumentInfoStore.dispatch({
      type: CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_START
    });
  }

  public loadDocumentInfoSucceeded(documentinfos: {[key: string]: string[]}): void {
    this.cfgDocumentInfoStore.dispatch({
      type: CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_SUCCEEDED,
      payload: documentinfos
    });
  }

  public loadDocumentInfoFailed(error: any): void {
    this.cfgDocumentInfoStore.dispatch({
      type: CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_FAILED,
      error: error
    });
  }


  public removeAllDocumentInfoStart(): void {
    this.cfgDocumentInfoStore.dispatch({
      type: CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_START
    });
  }

  public removeAllDocumentInfoSucceeded(): void {
    this.cfgDocumentInfoStore.dispatch({
      type: CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED
    });
  }

  public removeAllDocumentInfoFailed(error: any): void {
    this.cfgDocumentInfoStore.dispatch({
      type: CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED,
      error: error
    });
  }

}
