import { SearchPath } from './search-path';
import { FieldType } from './field';


export class AggregateParamsBuilder {
  private _path: SearchPath;
  private _options: AggregateOptions;
  private _fieldType: FieldType;

  public path(path: SearchPath) {
    this._path = path;
    return this;
  }

  public options(options: AggregateOptions) {
    this._options = options;
    return this;
  }
  public fieldType(fieldType: FieldType) {
    this._fieldType = fieldType;
    return this;
  }

  public build(): AggregateRequestParams {
    const ap: AggregateRequestParams = new AggregateRequestParams();
    ap.setPath(this._path);
    const options = {
      ...this._options
    };
    ap.setOptions(options);
    if (ap.isMeta()) {
      if (!!this._fieldType) {
        console.error('Meta field knows is type');
      }
      const metaDatasFieldType = SearchPath.getMetaDatasFieldType(ap.getSearchPath().code);
      ap.setFieldType(metaDatasFieldType);
    } else {
      if (!this._fieldType) {
        console.error('FieldType must be defined for not-meta datas');
      } else {
        ap.setFieldType(this._fieldType);
      }
    }
    return ap;
  }

}

export class AggregateOptions {
  size: number;
  min: any;
  max: any;
  itempath: string[];
}

export class AggregateRequestParams {

  public static readonly AGGREGATE_OPTION_SEPARATOR = ':';
  public static readonly DEFAULT_DIAGRAM_BUCKETS_SIZE = '20';
  public static readonly DEFAULT_AGGREGATE_SIZE = 5;

  private path: string;
  private searchPath: SearchPath;
  private fieldType: FieldType;
  private options: AggregateOptions;

  setPath(searchPath: SearchPath) {
    this.searchPath = searchPath;
  }

  setOptions(options: AggregateOptions) {
    this.options = options;
  }

  setFieldType(fieldType: FieldType) {
    this.fieldType = fieldType;
  }

  public isMeta(): boolean {
    return this.searchPath.isMeta;
  }
  public getCode(): string {
    return '' + this.searchPath.code;
  }
  getSearchPath() {
    return this.searchPath;
  }

  public getAggregatePath(): string {
    let completedPath;
    switch (this.fieldType) {
      case FieldType.DATE:
      case FieldType.DECIMAL:
      case FieldType.INTEGER:
        completedPath = this.searchPath.path;
        if (this.options.min && this.options.max) {
          completedPath += AggregateRequestParams.AGGREGATE_OPTION_SEPARATOR + (this.options.size ? this.options.size : AggregateRequestParams.DEFAULT_DIAGRAM_BUCKETS_SIZE);
          completedPath += AggregateRequestParams.AGGREGATE_OPTION_SEPARATOR + this.options.min + AggregateRequestParams.AGGREGATE_OPTION_SEPARATOR + this.options.max;
        }
        break;
      case FieldType.STRING:
      case FieldType.BOOLEAN: // ??
      case FieldType.AUTHOR:
      case FieldType.LIST:
          completedPath = this.searchPath.path;
        if (this.options.size) {
          completedPath += AggregateRequestParams.AGGREGATE_OPTION_SEPARATOR + this.options.size;
        }
        break;
      case FieldType.THESAURUS:
        completedPath = this.searchPath.path;
        const last = completedPath.lastIndexOf(SearchPath.PATH_PART_SEPARATOR + SearchPath.SUFFIX_I18N);
        if (last > -1) {
          completedPath = completedPath.substring(0, last) + SearchPath.PATH_PART_SEPARATOR + SearchPath.SUFFIX_VALUE;
        }
        if (!!this.options.itempath && !!this.options.itempath.length) {
          completedPath += AggregateRequestParams.AGGREGATE_OPTION_SEPARATOR + (this.options.size ? this.options.size : AggregateRequestParams.DEFAULT_AGGREGATE_SIZE);
          completedPath += AggregateRequestParams.AGGREGATE_OPTION_SEPARATOR + this.options.itempath.join(',');
        }
        break;
    }
    return completedPath;

  }

}
