import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { DraftDatas } from '../../../../models/DraftDatas';
import { DraftDatasState, DraftDatasConstant, draftDatasReducer } from '../reducer/draft-datas.reducer';
import { IAppState } from '../../../../../store/model';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class DraftDatasAction {
  private localStore: ObservableStore<DraftDatasState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.DRAFT_DATAS], draftDatasReducer);
  }

  public loadDraftDatasStarted(): void {
    this.localStore.dispatch({
      type: DraftDatasConstant.LOAD_DRAFT_DATAS_START
    });
  }

  public loadDraftDatasSucceeded(draftDatas: DraftDatas): void {
    this.localStore.dispatch({
      type: DraftDatasConstant.LOAD_DRAFT_DATAS_SUCCEEDED,
      payload: draftDatas
    });
  }

  public loadDraftDatasFailed(error: any) {
    this.localStore.dispatch({
      type: DraftDatasConstant.LOAD_DRAFT_DATAS_FAILED,
      error: error
    });
  }

  public removeSucceeded(): void {
    this.localStore.dispatch({
      type: DraftDatasConstant.REMOVE_DRAFT_DATAS_SUCCEEDED
    });
  }

  public addOptions(draftDatas: DraftDatas) {
    this.localStore.dispatch({
      type: DraftDatasConstant.ADD_DRAFT_OPTIONS,
      payload: draftDatas
    });
  }

  public removeOptions(draftDatas: DraftDatas) {
    this.localStore.dispatch({
      type: DraftDatasConstant.REMOVE_DRAFT_OPTIONS,
      payload: draftDatas
    });
  }

}
