import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IndexationDocTypesState, IndexationDocTypesConstant, indexationDocTypesReducer } from '../reducer/indexation-doc-types.reducer';
import { IAppState } from '../../../../../store/model';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class IndexationDocTypesAction {
  private localStore: ObservableStore<IndexationDocTypesState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.INDEXATION_DOC_TYPES], indexationDocTypesReducer);
  }

  public loadStarted(): void {
    this.localStore.dispatch({
      type: IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_START
    });
  }

  public loadSucceeded(indexationDocTypes: Map<string, string[]>): void {
    this.localStore.dispatch({
      type: IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_SUCCEEDED,
      payload: indexationDocTypes
    });
  }

  public loadFailed(error: any) {
    this.localStore.dispatch({
      type: IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_FAILED,
      error: error
    });
  }
}
