import { Reducer } from 'redux';

import { Page } from '../../../../models/page';
import { Team } from '../../../../models/team';
import { PayloadAction } from '../../../../../store/payload-action';

export class TeamsPageState {
  datas: Page<Team>;
  loading: boolean;
  error: any;
}

const TEAMS_PAGE_INITIAL_STATE: TeamsPageState = {
  datas: null,
  loading: false,
  error: null,
};

export class TeamsPageConstant {
  static readonly LOAD_TEAMS_PAGE_START = '[TeamsPage].LOAD_TEAMS_PAGE_START';
  static readonly LOAD_TEAMS_PAGE_SUCCEEDED = '[TeamsPage].LOAD_TEAMS_PAGE_SUCCEEDED';
  static readonly LOAD_TEAMS_PAGE_FAILED = '[TeamsPage].LOAD_TEAMS_PAGE_FAILED';
}

export const teamsPageReducer: Reducer<TeamsPageState> = (state: TeamsPageState = TEAMS_PAGE_INITIAL_STATE, action: PayloadAction<Page<Team>>) => {
  switch (action.type) {
    case TeamsPageConstant.LOAD_TEAMS_PAGE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TeamsPageConstant.LOAD_TEAMS_PAGE_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case TeamsPageConstant.LOAD_TEAMS_PAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
