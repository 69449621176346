import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { CardComponent } from './components/card/card.component';
import { DocumentCardThumbComponent } from './components/card-thumb/document-card-thumb/document-card-thumb.component';
import { DraftCardThumbComponent } from './components/card-thumb/draft-card-thumb/draft-card-thumb.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { DocumentCardActionsComponent } from './components/card-actions/document-card-actions/document-card-actions.component';
import { DraftCardActionsComponent } from './components/card-actions/draft-card-actions/draft-card-actions.component';
import { DocumentCardInfosComponent } from './components/card-infos/document-card-infos/document-card-infos.component';
import { DraftCardInfosComponent } from './components/card-infos/draft-card-infos/draft-card-infos.component';
import { DocumentDownloadMenuComponent } from './components/card-download-menu/document-download-menu/document-download-menu.component';
import { EntityModule } from '../entity/entity.module';
import { TaskCardInfosComponent } from './components/card-infos/task-card-infos/task-card-infos.component';
import { TaskCardThumbComponent } from './components/card-thumb/task-card-thumb/task-card-thumb.component';
import { CardSelectComponent } from './components/card-select/card-select.component';
import { BarSelectComponent } from './components/bar-select/bar-select.component';
import { ButtonModule } from '../buttons/button.module';

@NgModule({
  declarations: [
    CardComponent,
    DocumentCardThumbComponent,
    DraftCardThumbComponent,
    DocumentCardActionsComponent,
    DraftCardActionsComponent,
    DocumentCardInfosComponent,
    DraftCardInfosComponent,
    DocumentDownloadMenuComponent,
    TaskCardInfosComponent,
    TaskCardThumbComponent,
    CardSelectComponent,
    BarSelectComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    TooltipModule,
    MatMenuModule,
    MatIconModule,
    EntityModule,
    ButtonModule
  ],
  exports: [
    CardComponent,
    DocumentCardThumbComponent,
    DraftCardThumbComponent,
    DocumentCardActionsComponent,
    DraftCardActionsComponent,
    DocumentCardInfosComponent,
    DraftCardInfosComponent,
    DocumentDownloadMenuComponent,
    TaskCardInfosComponent,
    TaskCardThumbComponent,
    CardSelectComponent,
    BarSelectComponent
  ]
})
export class CardModule { }
