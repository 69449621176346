import { HttpHeaders } from '@angular/common/http';
import { ToastaService } from 'ngx-toasta';
import { Injectable } from '@angular/core';
import { Reducer } from 'redux';
import { NgRedux } from '@angular-redux/store';
import { TranslateService } from '@ngx-translate/core';

import { EsPage } from '../../../../models/es-page';

import { IAppState } from '../../../../../store/model';
import { CdxAttachment } from '../../../../models/cdx-attachment';
import { ActivityDiff, CdxActivity } from '../../../../models/cdx-activity';
import { CdxComment } from '../../../../models/cdx-comment';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { TaskDetailsConstant, taskDetailsReducer, TaskDetailsState } from '../../reducer/task/task-details.reducer';
import { TaskDetails } from '../../../../models/task-details';
import { CdxTask } from '../../../../models/cdx-task';
import { AbstractDetailsAction } from '../abstract-details.action';
import { Utils } from '../../../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class TaskDetailsAction extends AbstractDetailsAction {

  constructor(
    ngRedux: NgRedux<IAppState>,
    translateService: TranslateService,
    toastaService: ToastaService,
  ) {
    super(ngRedux, translateService, toastaService);
  }

  protected getDetailsStorePath(): string[] {
    return [StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.TASK_DETAILS];
  }

  protected getDetailsReducer(): Reducer<TaskDetailsState> {
    return taskDetailsReducer;
  }

  public loadDetailsStart(): void {
    this._dispatchType(TaskDetailsConstant.LOAD_DETAILS_START);
  }

  public loadNexiaObjectDetailsStart(): void {
    this._dispatchType(TaskDetailsConstant.LOAD_TASK_DETAILS_START);
  }

  public loadCurrentActivityDiffStart(): void {
    this._dispatchType(TaskDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_START);
  }

  public loadDetailsSucceeded(cdxTask: CdxTask, comments: CdxComment[], attachments: CdxAttachment[], activities: EsPage<CdxActivity>): void {
    if (!cdxTask && !comments && !attachments && !activities) {
      this.loadDetailsFailed('tasks, comments, attachments and activities cannot be null or undefined');
      return;
    }
    const currentTaskDetails: TaskDetails = {
      details: cdxTask,
      comments: comments,
      attachments: attachments,
      activities: activities,
      token: cdxTask.token,
      currentActivityDiff: new ActivityDiff()
    };
    this._dispatchSuccess(TaskDetailsConstant.LOAD_DETAILS_SUCCEEDED, currentTaskDetails);
  }

  public loadNexiaObjectDetailsSucceeded(updateDetailsData: CdxTask, loadPdf = false): void {
    const payload = new TaskDetails();
    payload.details = updateDetailsData;
    payload.loadPdf = loadPdf;
    this._dispatchSuccess(TaskDetailsConstant.LOAD_TASK_DETAILS_SUCCEEDED, payload);
  }

  public loadCurrentActivityDiffSucceeded(currentActivityDiff: ActivityDiff): void {
    const payload = new TaskDetails();
    payload.currentActivityDiff = currentActivityDiff;
    this._dispatchSuccess(TaskDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED, payload);
  }

  public loadDetailsFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.LOAD_DETAILS_FAILED, error);
  }

  public loadNexiaObjectDetailsFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.LOAD_TASK_DETAILS_FAILED, error);
  }

  public loadCurrentActivityDiffFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_FAILED, error);
  }

  public addCommentsStart(): void {
    this._dispatchType(TaskDetailsConstant.ADD_COMMENT_START);
  }

  public addCommentsFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.ADD_COMMENT_FAILED, error);
  }

  public updateCommentsStart(): void {
    this._dispatchType(TaskDetailsConstant.UPDATE_COMMENT_START);
  }

  public updateCommentsFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.UPDATE_COMMENT_FAILED, error);
  }

  public loadCommentStart(): void {
    this._dispatchType(TaskDetailsConstant.LOAD_COMMENT_START);
  }

  public loadCommentSucceeded(comment: CdxComment): void {
    if (!comment) {
      this.loadCommentFailed('Comment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.comments = [comment];
    this._dispatchSuccess(TaskDetailsConstant.LOAD_COMMENT_SUCCEEDED, payload);
  }

  public loadCommentFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.LOAD_COMMENT_FAILED, error);
  }

  public deleteCommentStart(): void {
    this._dispatchType(TaskDetailsConstant.DELETE_COMMENT_START);
  }

  public deleteCommentFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.DELETE_COMMENT_FAILED, error);
  }

  public addReplyStart(): void {
    this._dispatchType(TaskDetailsConstant.ADD_REPLY_START);
  }

  public addReplyFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.ADD_REPLY_FAILED, error);
  }

  public updateReplyStart(): void {
    this._dispatchType(TaskDetailsConstant.ADD_REPLY_START);
  }

  public updateReplyFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.UPDATE_REPLY_FAILED, error);
  }

  public deleteReplyStart(): void {
    this._dispatchType(TaskDetailsConstant.DELETE_REPLY_START);
  }

  public deleteReplyFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.DELETE_REPLY_FAILED, error);
  }

  public loadHistoryStart(): void {
    this._dispatchType(TaskDetailsConstant.LOAD_HISTORY_START);
  }

  public loadHistorySucceeded(activities: EsPage<CdxActivity>): void {
    if (!activities) {
      this.loadHistoryFailed('History cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.activities = activities;
    this._dispatchSuccess(TaskDetailsConstant.LOAD_HISTORY_SUCCEEDED, payload);
  }

  public loadHistoryFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.LOAD_HISTORY_FAILED, error);
  }

  public loadAttachmentStart(): void {
    this._dispatchType(TaskDetailsConstant.LOAD_ATTACHMENT_START);
  }

  public loadAttachmentSucceeded(attachment: CdxAttachment, tempAttachmentId: string = null): void {
    if (!attachment) {
      this.loadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.attachments = [attachment];
    if (tempAttachmentId) {
      const attachmentToBeChanged: CdxAttachment = new CdxAttachment();
      attachmentToBeChanged.cdx_id = tempAttachmentId;
      payload.attachments.push(attachmentToBeChanged);
    }
    this._dispatchSuccess(TaskDetailsConstant.LOAD_ATTACHMENT_SUCCEEDED, payload);
  }

  public loadAttachmentFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.LOAD_ATTACHMENT_FAILED, error);
  }

  public uploadAttachmentStart(): void {
    this._dispatchType(TaskDetailsConstant.UPLOAD_ATTACHMENT_START);
  }

  public uploadAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.uploadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(TaskDetailsConstant.UPLOAD_ATTACHMENT_SUCCEEDED, payload);
  }

  public uploadAttachmentFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.UPLOAD_ATTACHMENT_FAILED, error);
  }

  public deleteAttachmentStart(): void {
    this._dispatchType(TaskDetailsConstant.DELETE_ATTACHMENT_START);
  }

  public deleteAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.deleteAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(TaskDetailsConstant.DELETE_ATTACHMENT_SUCCEEDED, payload);
  }

  public deleteAttachmentFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.DELETE_ATTACHMENT_FAILED, error);
  }

  public updateAttachmentStart(): void {
    this._dispatchType(TaskDetailsConstant.UPDATE_ATTACHMENT_START);
  }

  public updateAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.updateAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new TaskDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(TaskDetailsConstant.UPDATE_ATTACHMENT_SUCCEEDED, payload);
  }

  public updateAttachmentFailed(error: any): void {
    this._dispatchFail(TaskDetailsConstant.UPDATE_ATTACHMENT_FAILED, error);
  }

  public removeAll(): void {
    this._dispatchType(TaskDetailsConstant.REMOVE_ALL_SUCCEEDED);
  }

  public updateToken(headers: HttpHeaders): void {
    const token = headers.get('Object-Authorization');
    if (Utils.notNullAndNotUndefined(token)) {
      const payload = new TaskDetails();
      payload.token = token;
      this._dispatchSuccess(TaskDetailsConstant.UPDATE_TOKEN, payload);
    }
  }

  public showHideInfoBlockSucceeded(isVisible: boolean): void {
    const payload = new TaskDetails();
    payload.displayInfoBlock = isVisible;
    this._dispatchSuccess(TaskDetailsConstant.LOAD_DISPLAY_BLOCK_SUCCEEDED, payload);
  }
}
