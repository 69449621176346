import { Reducer } from 'redux';
import { PayloadAction } from '../../../../../store/payload-action';
import { Field } from '../../../../models/field';


export interface GedFieldState {
  datas: {[key: string]: Field};
  loading: boolean;
  error: any;
}

const GED_CFG_INITIAL_STATE: GedFieldState = {
  datas: {},
  loading: false,
  error: null,
};

export class GedCfgFieldConstant {
  static readonly ADD_MULTIPLE_CFG_FIELD_START = '[GED]ADD_MULTIPLE_CFG_FIELD_START';
  static readonly ADD_MULTIPLE_CFG_FIELD_SUCCEEDED = '[GED]ADD_MULTIPLE_CFG_FIELD_SUCCEEDED';
  static readonly ADD_MULTIPLE_CFG_FIELD_FAILED = '[GED]ADD_MULTIPLE_CFG_FIELD_FAILED';

  static readonly REMOVE_ALL_CFG_FIELD_START = '[GED]REMOVE_ALL_CFG_FIELD_START';
  static readonly REMOVE_ALL_CFG_FIELD_SUCCEEDED = '[GED]REMOVE_ALL_CFG_FIELD_SUCCEEDED';
  static readonly REMOVE_ALL_CFG_FIELD_FAILED = '[GED]REMOVE_ALL_CFG_FIELD_FAILED';


  // static readonly STORE_PATH = [StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CFG_FIELD];
  // static readonly STORE_PATH_DATAS = [...CgfFieldConstant.STORE_PATH, 'datas'];
  // static readonly STORE_PATH_ERROR = [...CgfFieldConstant.STORE_PATH, 'error'];
  // static readonly STORE_PATH_LOADING = [...CgfFieldConstant.STORE_PATH, 'loading'];
}

export const gedCfgFieldReducer: Reducer<GedFieldState> = (state: GedFieldState = GED_CFG_INITIAL_STATE, action: PayloadAction<{[key: string]: Field}>): GedFieldState => {
  switch (action.type) {
    // ADD MULTIPLE
    case GedCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GedCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_SUCCEEDED:
      return {
        ...state,
        datas: {...state.datas, ...action.payload},
        loading: false,
        error: null,
      };
    case GedCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      // REMOVE ALL
    case GedCfgFieldConstant.REMOVE_ALL_CFG_FIELD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GedCfgFieldConstant.REMOVE_ALL_CFG_FIELD_SUCCEEDED:
      return {
        ...state,
        datas: {},
        loading: false,
        error: null,
      };
    case GedCfgFieldConstant.REMOVE_ALL_CFG_FIELD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
