<div class="dataContent" perfectScrollbar>

  <mat-tree *ngIf="!isLoading" [dataSource]="dataSource" [treeControl]="treeControl">

    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [ngClass]="{'line': node.level !== 0, 'checked': isNodeChecked(node)}" >
      <button mat-icon-button disabled class="btn-tree"
              [ngClass]="{'active': treeControl.isExpanded(node) || isLastNodeChecked(node)}"></button>
      <mat-checkbox *ngIf="maxSelection > 1 && ifAutopostageAndMultiSelection(node)" class="checklist-leaf-node"
                    [ngClass]="{'checked': isNodeChecked(node)}"
                    [checked]="isNodeChecked(node)"
                    [disabled]="regionPath.length >= maxSelection && !isLastNodeChecked(node) || regionPath.length < maxSelection && isNodeChecked(node) && !isLastNodeChecked(node)"
                    (change)="nodeToggled(node, $event)">{{node.label}}</mat-checkbox>
      <span *ngIf="maxSelection <= 1 || !ifAutopostageAndMultiSelection(node)"
            [ngClass]="{'checked': isNodeChecked(node), 'nodeActive': ifAutopostageAndMultiSelection(node)}"
            (click)="ifAutopostageAndMultiSelection(node) ? onSimpleSelection(node) : ''">{{node.label}}</span>
      <span *ngIf="showSearchBtn"
            class="search-btn icon icon-search"
            appTooltip="{{('THESAURUS.searchByThesaurusValue.' + this.currentPage) | translate: {nodeLabel: node.label} }}"
            [ngClass]="{'disabled': searchIsLoading.loading}"
            (click)="!searchIsLoading.loading ? searchByThesaurusValue(node) : $event.preventDefault()">
        <mat-spinner class="spinner" diameter="28" strokeWidth="2" *ngIf="searchIsLoading.loading && searchIsLoading.nodeValue === node.value"></mat-spinner>
      </span>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding  [ngClass]="{'line': node.level !== 0, 'checked': isNodeChecked(node)}">
      <button mat-icon-button matTreeNodeToggle class="btn-tree"
              [ngClass]="{'active': treeControl.isExpanded(node) || isLastNodeChecked(node), 'root': node.level === 0, 'disabled': searchIsLoading.loading}"
              [attr.aria-label]="'Toggle ' + node.label">
        <mat-icon class="mat-icon-rtl-mirror" [ngClass]="{'disabled': searchIsLoading.loading}">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-checkbox *ngIf="maxSelection > 1 && ifAutopostageAndMultiSelection(node)"
                    [ngClass]="{'checked': isNodeChecked(node)}"
                    [checked]="isNodeChecked(node)"
                    [disabled]="regionPath.length >= maxSelection && !isLastNodeChecked(node) || regionPath.length < maxSelection && isNodeChecked(node) && !isLastNodeChecked(node)"
                    (change)="nodeToggled(node, $event)">{{node.label}}</mat-checkbox>
      <span *ngIf="maxSelection <= 1 || !ifAutopostageAndMultiSelection(node)"
            [ngClass]="{'checked': isNodeChecked(node), 'nodeActive': ifAutopostageAndMultiSelection(node)}"
            (click)="ifAutopostageAndMultiSelection(node) ? onSimpleSelection(node) : ''">{{node.label}}</span>
      <span *ngIf="showSearchBtn"
            class="search-btn icon icon-search"
            appTooltip="{{('THESAURUS.searchByThesaurusValue.' + this.currentPage) | translate: {nodeLabel: node.label} }}"
            [ngClass]="{'disabled': searchIsLoading.loading}"
            (click)="!searchIsLoading.loading ? searchByThesaurusValue(node) : $event.preventDefault()">
        <mat-spinner class="spinner" diameter="28" strokeWidth="2" *ngIf="searchIsLoading.loading && searchIsLoading.nodeValue === node.value"></mat-spinner>
      </span>
      <mat-progress-bar *ngIf="node.isLoading"
                        mode="indeterminate"
                        class="example-tree-progress-bar"></mat-progress-bar>
    </mat-tree-node>

<!--    when: ejecte le noeud de l'iterator -->
    <mat-tree-node *matTreeNodeDef="let node; when: isLoadNextPage" matTreeNodePadding>
      <span class="clickable" (click)="loadNextPage(node)">{{'TREE.showMoreNodes' | translate}}</span>
    </mat-tree-node>

  </mat-tree>
</div>
