import { Reducer } from 'redux';

export interface AuthenticationState {
  authenticated: boolean;
  role: string;
  loading: boolean;
  error: any;
}

export const AUTH_INITIAL_STATE: AuthenticationState = {
  authenticated: false,
  role: undefined,
  error: null,
  loading: false
};


export class AuthenticationActionConstants {
  static readonly SET_AUTH_OK = 'SET_AUTH_OK';

  static readonly LOAD_AUTH_START = 'LOAD_AUTH_START';
  static readonly LOAD_AUTH_SUCCEEDED = 'LOAD_AUTH_SUCCEEDED';
  static readonly LOAD_AUTH_FAILED = 'LOAD_AUTH_FAILED';

  static readonly LOGOUT_SUCCEEDED = 'LOGOUT_SUCCEEDED';
  static readonly LOGOUT_FAILED = 'LOGOUT_FAILED';
}

export const authenticationReducer: Reducer<AuthenticationState> = (state = AUTH_INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthenticationActionConstants.LOAD_AUTH_START:
      return {
        ...state,
        authenticated: false,
        role: undefined,
        loading: true,
        error: null,
      };
    case AuthenticationActionConstants.LOAD_AUTH_SUCCEEDED:
      return {
        ...state,
        role: action.payload.role,
        authenticated: true,
        loading: false,
        error: null,
      };
    case AuthenticationActionConstants.LOAD_AUTH_FAILED:
      return {
        ...state,
        authenticated: false,
        role: undefined,
        loading: false,
        error: action.payload,
      };
    case AuthenticationActionConstants.SET_AUTH_OK:
      return {
        ...state,
        authenticated: action.payload.value,
        role: action.payload.role
      };
    case AuthenticationActionConstants.LOGOUT_SUCCEEDED:
      return {
        ...state,
        authenticated: false,
        role: undefined,
        loading: false,
        error: null,
      };
    case AuthenticationActionConstants.LOGOUT_FAILED:
      return {
        ...state,
        authenticated: false,
        role: undefined,
        loading: false,
        error: action.payload,
      };
  }
  return state;
};
