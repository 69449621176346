import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { InternalRoutes } from '../../models/internal-routes';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {

  InternalRoutes = InternalRoutes;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private logger: NGXLogger) { }
  error: any;
  subs = [];

  ngOnInit() {
    this.subs.push(this.activeRoute.queryParams.subscribe(
      params => {
        if (this.error) {
          this.logger.error('ErrorComponent: ', this.error);
        }
      }
    ));
  }

  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }

  backToHome() {
    this.router.navigate([''], {replaceUrl: true});
  }
}
