import { Reducer } from 'redux';
import { PayloadAction } from '../../../../store/payload-action';

export interface DocumentInfoState {
  datas: {[key: string]: string[]};
  loading: boolean;
  error: any;
}

const MENU_INITIAL_STATE: DocumentInfoState = {
  datas: {},
  loading: false,
  error: null,
};

export class CgfDocumentInfoConstant {
  static readonly ADD_CFG_DOCUMENT_INFO_START = 'ADD_CFG_DOCUMENT_INFO_START';
  static readonly ADD_CFG_DOCUMENT_INFO_SUCCEEDED = 'ADD_CFG_DOCUMENT_INFO_SUCCEEDED';
  static readonly ADD_CFG_DOCUMENT_INFO_FAILED = 'ADD_CFG_DOCUMENT_INFO_FAILED';

  static readonly REMOVE_ALL_CFG_DOCUMENT_INFO_START = 'REMOVE_ALL_CFG_DOCUMENT_INFO_START';
  static readonly REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED = 'REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED';
  static readonly REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED = 'REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED';
}

export const cfgDocumentInfoReducer: Reducer<any> = (state: DocumentInfoState = MENU_INITIAL_STATE, action: PayloadAction<{[key: string]: string[]}>): DocumentInfoState => {
  switch (action.type) {
    // ADD MULTIPLE
    case CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_SUCCEEDED:
      return {
        ...state,
        datas: {...state.datas, ...action.payload},
        loading: false,
        error: null,
      };
    case CgfDocumentInfoConstant.ADD_CFG_DOCUMENT_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    // REMOVE ALL
      case CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_SUCCEEDED:
      return {
        ...state,
        datas: {},
        loading: false,
        error: null,
      };
    case CgfDocumentInfoConstant.REMOVE_ALL_CFG_DOCUMENT_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
