import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../../store/model';
import { CacheFormConstant, cacheFormReducer, CacheFormState } from '../reducer/cache-form.reducer';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class CacheFormAction {
  private localStore: ObservableStore<CacheFormState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CACHE_FORM], cacheFormReducer);
  }

  public addCacheFormStart(): void {
    this.localStore.dispatch({
      type: CacheFormConstant.ADD_CACHE_FORM_START
    });
  }

  public addCacheFormSucceeded(value: {[key: string]: any}): void {
    this.localStore.dispatch({
      type: CacheFormConstant.ADD_CACHE_FORM_SUCCEEDED,
      payload: value
    });
  }

  public addCacheFormFailed(error: any): void {
    this.localStore.dispatch({
      type: CacheFormConstant.ADD_CACHE_FORM_FAILED,
      error: error
    });
  }

  public updateDataInCacheFormSucceeded(formData: {[key: string]: any}): void {
    this.localStore.dispatch({
      type: CacheFormConstant.UPDATE_CACHE_FORM_SUCCEEDED,
      payload: formData
    });
  }

  public removeCacheFormSucceeded(formKey: string): void {
    const formCacheToBeDeleted: {[key: string]: any} = {};
    formCacheToBeDeleted[formKey] = {};
    this.localStore.dispatch({
      type: CacheFormConstant.REMOVE_CACHE_FORM_SUCCEEDED,
      payload: formCacheToBeDeleted
    });
  }
}
