<div class="logo-colors">
  <div class="layer-1"></div>
  <div class="layer-2"></div>
  <div class="layer-3"></div>
</div>

<div class="error">
  <div class="error_logo">
    <app-logo-nexia [fullBlackLogo]="true"></app-logo-nexia>
  </div>
  <div class="error_wrapper">
    <div class="error_picto">
      <img src="../../../../assets/images/error_robot.png" alt="error image robot">
    </div>
    <div class="error_content">
      <div class="error_title">
        Une erreur est survenue. {{error}}
      </div>
      <div class="error_text">
        Il y a sans doute quelque chose de cassé,<br/>
        revenez sur nexia au plus vite.
      </div>
      <button (click)="backToHome()" class="error_btn btn btn-primary" >
        Revenir sur nexia
      </button>
    </div>
  </div>
</div>
