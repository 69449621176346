import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store/model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationActions {
  constructor(private ngRedux: NgRedux<IAppState>) {
}

get(action: string) {
    let obj = this.ngRedux.getState().configuration;
    const propparts = action.split('.');
    for (let i = 0; i < propparts.length; i++) {
      if (typeof obj[propparts[i]] === 'undefined') {
        return null;
      }
        obj = obj[propparts[i]];
    }
    return obj;
  }

loadConfiguration(configFile): Promise<boolean> {
      this.ngRedux.dispatch({
        type: 'LOAD_CONFIG_START', configFile : configFile });
        const currentLocation = document.location;
        const localUrl = `${currentLocation.protocol}//${currentLocation.hostname}:${currentLocation.port}`;
      // this.localStore.dispatch({
      //   type: 'LOAD_LANGUAGES_START'
      // });
      return new Promise((resolve) => {
      fetch(`${localUrl}/assets/config/${configFile}.json`, {
        method: 'GET',
        headers: {
          // 'cache-control': 'no-cache',
          // 'Content-Type': 'application/json',
        },
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error(`Bad response from server`);
          }
          return res.json();
        })
        .then(res => {
          this.ngRedux.dispatch({
            type: 'LOAD_CONFIG_SUCCEEDED',
            configFile : configFile,
            payload:  res
          });
          resolve(true);
        })
        .catch(err => {
          this.ngRedux.dispatch({
            type: 'LOAD_CONFIG_FAILED',
            configFile : configFile,
            payload: err
          });
          resolve(false);
        });
    });
  }
}
