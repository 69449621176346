import { ToastaService } from 'ngx-toasta';
import * as _ from 'lodash';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { combineLatest, Observable, of, OperatorFunction, pipe, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationResult } from '@azure/msal-common';
import { Router } from '@angular/router';
import { IAppState } from '../../../../../store/model';
import { CdxFile } from '../../../../models/cdx-file';
import { CdxAttachment } from '../../../../models/cdx-attachment';
import { ConfigurationActions } from '../../../../../modules/configuration/configuration.actions';
import { Url } from '../../../../models/url';
import { Utils } from '../../../../utils/utils';
import { CdxComment, CdxReply } from '../../../../models/cdx-comment';
import { DocumentDetails } from '../../../../models/document-details';
import { CdxDocument } from '../../../../models/cdx-document';
import { ViewService } from '../../../view/service/view.service';
import { UserSocketHttpResponse } from '../../../../models/user-socket-http-response';
import { SocketService } from '../../../../services/socket/socket.service';
import { AuthenticationService } from '../../../../../modules/authentication/authentication.service';
import { ActivityDiff, CdxActivity } from '../../../../models/cdx-activity';
import { EsPage } from '../../../../models/es-page';
import { CriticalDataService } from '../../../critical-data/service/critical-data.service';
import { CriticalDataAction } from '../../../critical-data/action/critical-data.action';
import { InternalRoutes } from '../../../../models/internal-routes';
import { DocumentSearchResultService } from '../../../search-result/service/document-search-result.service';
import { AbstractDetailsService, DELETE_MODE } from '../abstract-details.service';
import { CurrentCommentService } from '../../../current-comment/service/current-comment.service';
import { DocumentDetailsAction } from '../../action/document/document-details.action';
import { DocumentDetailsState } from '../../reducer/document/document-details.reducer';
import { ArchiveFormat } from '../../../../models/ArchiveFormat';
import { Metadata, MetadataCodes } from '../../../../models/metadata';
import { Lock, LockInformation, LockType } from '../../../../models/lock';
import { ShareFileService } from '../../../../services/azure/share-file/share-file.service';
import { BlobFile } from '../../../../models/blob-file';
import { ShareLinkData } from '../../../../models/share-link-data';
import { IndexationService } from '../../../indexation/service/indexation.service';
import { View } from '../../../../models/view';
import { DraftDocument } from '../../../../models/DraftDocument';
import { HttpErrorInterceptor } from '../../../../../modules/http-error-interceptor/http-error-interceptor';

@Injectable({
  providedIn: 'root'
})
export class DocumentDetailsService extends AbstractDetailsService {

  private static colors = new Map<string, string>();
  private documentSearchResultService: DocumentSearchResultService;

  // private searchResultService: SearchResultService;


  static getDocumentTypeColor(typeDoc: string): string {
    if (this.colors.get(typeDoc) != null) {
      return this.colors.get(typeDoc);
    }
    let hash = 0;
    for (let i = 0; i < typeDoc.length; i++) {
      hash = typeDoc.charCodeAt(i) + ((hash << 5) - hash);
    }
    let c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    c = '00000'.substring(0, 6 - c.length) + c;
    this.colors.set(typeDoc, '#' + c);
    return this.colors.get(typeDoc);
  }

  static query(): Observable<HttpParams> {
    const httpParams = new HttpParams();
    return of(httpParams);
  }

  constructor(
    httpClient: HttpClient,
    configAction: ConfigurationActions,
    ngRedux: NgRedux<IAppState>,
    toastaService: ToastaService,
    translateService: TranslateService,
    socketService: SocketService,
    authenticationService: AuthenticationService,
    location: Location,
    router: Router,
    criticalDataService: CriticalDataService,
    currentCommentService: CurrentCommentService,
    private indexationService: IndexationService,
    private viewService: ViewService,
    private documentDetailsAction: DocumentDetailsAction,
    private shareFileService: ShareFileService
  ) {
    super(httpClient, configAction, ngRedux, toastaService, translateService, socketService, authenticationService, location, router, criticalDataService, currentCommentService);
  }

  /*START getting urls*/
  protected getNexiaObjectDetailsUrl(docId: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.DETAILS;
  }

  protected getInternalRouteDetailsUrl(docId: string): string[] {
    return ['/' + InternalRoutes.DOCUMENTS, docId, InternalRoutes.DETAILS];
  }

  protected getPostPutCommentUrl(comment: CdxComment): string {
    return Url.DOCUMENTS + comment.cdx_doc_id + '/' + Url.COMMENTS;
  }
  protected getLoadDeleteCommentUrl(docId: string, comId: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.COMMENTS + comId;
  }

  protected getPostPutReplyUrl(docId: string, comId: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.COMMENTS + comId + '/' + Url.REPLY;
  }
  protected getDeleteReplyUrl(docId: string, comId: string, replyId: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.COMMENTS + comId + '/' + Url.REPLY + replyId + '/delete';
  }

  protected getHistoryUrl(docId: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.HISTORY;
  }

  protected getLoadDeleteDownloadAttachmentUrl(docId: string, attachmentId: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.ATTACHMENT + attachmentId;
  }
  protected getUpdateUploadAttachmentUrl(docId: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.ATTACHMENT;
  }

  protected getRestoreVersionUrl(docId: string, numVersion: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.RESTORE_REVISION + numVersion;
  }

  protected getCurrentActivityDiffUrl(docId: string, evtId: number, entityType: string = null): string {
    return Url.DOCUMENTS + docId + '/' + Url.HISTORY_EVT_REV + evtId;
  }

  protected getUpdateTeamsUrl(docId: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.TEAMS;
  }

  protected getDeleteNexiaObjectUrl(docId: string): string {
    return Url.DOCUMENTS + docId;
  }

  protected getHardDeleteNexiaObjectsUrl(): string {
    return Url.DOCUMENTS + Url.DELETE;
  }

  protected getUpdateArchiveUrl(): string {
    return Url.DOCUMENTS;
  }

  protected getMetadataUrl(docId: string): string {
    return Url.DOCUMENTS + docId + '/' + Url.METADATA;
  }
  /*END getting urls*/

  /*START calls to details actions*/
  protected detailsActionLoadCommentStart(): void {
    this.documentDetailsAction.loadCommentStart();
  }
  protected detailsActionLoadCommentSucceeded(comment: CdxComment): void {
    this.documentDetailsAction.loadCommentSucceeded(comment);
  }
  protected detailsActionLoadCommentFailed(error: any): void {
    this.documentDetailsAction.loadCommentFailed(error);
  }

  protected detailsActionLoadHistoryStart(): void {
    this.documentDetailsAction.loadHistoryStart();
  }
  protected detailsActionLoadHistorySucceeded(activities: EsPage<CdxActivity>): void {
    this.documentDetailsAction.loadHistorySucceeded(activities);
  }
  protected detailsActionLoadHistoryFailed(error: any): void {
    this.documentDetailsAction.loadHistoryFailed(error);
  }

  protected detailsActionLoadAttachmentStart(): void {
    this.documentDetailsAction.loadAttachmentStart();
  }
  protected detailsActionLoadAttachmentSucceeded(attachment: CdxAttachment, tempAttachmentId: string): void {
    tempAttachmentId ? this.documentDetailsAction.loadAttachmentSucceeded(attachment, tempAttachmentId) : this.documentDetailsAction.loadAttachmentSucceeded(attachment);
  }
  protected detailsActionLoadAttachmentFailed(error: any): void {
    this.documentDetailsAction.loadAttachmentFailed(error);
  }

  protected detailsActionUploadAttachmentSucceeded(attachment: CdxAttachment): void {
    this.documentDetailsAction.uploadAttachmentSucceeded(attachment);
  }

  protected detailsActionLoadMetaStart(): void {
    this.documentDetailsAction.loadMetaStart();
  }
  protected detailsActionLoadMetaSucceeded(meta: Metadata): void {
    this.documentDetailsAction.loadMetaSucceeded(meta);
  }
  protected detailsActionLoadMetaFailed(error: any): void {
    this.documentDetailsAction.loadMetaFailed(error);
  }

  protected detailsActionLoadCurrentActivityDiffStart(): void {
    this.documentDetailsAction.loadCurrentActivityDiffStart();
  }
  protected detailsActionLoadCurrentActivityDiffSucceeded(currentActivityDiff: ActivityDiff): void {
    this.documentDetailsAction.loadCurrentActivityDiffSucceeded(currentActivityDiff);
  }
  protected detailsActionLoadCurrentActivityDiffFailed(error: any): void {
    this.documentDetailsAction.loadCurrentActivityDiffFailed(error);
  }

  protected searchResultServiceUpdateDocInPage(docId: string, meta: Metadata): void {
    this.documentSearchResultService.updateDocumentInSearchResultPage(docId, meta);
  }
  /*END calls to details actions*/

  /*START COMMON*/
  /**
   * Charge le document à une révision donnée.
   * Les métadatas chargées sont celle de la version courante du document
   * @param id
   * @param versionNumber
   */
  public loadDocumentDetailsVersion(id: string, versionNumber: number): void {
    try {
      this.documentDetailsAction.loadNexiaObjectDetailsStart();
      combineLatest([super._loadNexiaObjectDetailsVersion(id, versionNumber), this.getMetadata(id)])
        .subscribe((res: [CdxDocument, Metadata]) => {
            const document: CdxDocument = res[0];
            document.metadata = res[1];
            this.documentDetailsAction.loadNexiaObjectDetailsSucceeded(document, true);
          },
          (error) => {
            this.documentDetailsAction.loadNexiaObjectDetailsFailed(error);
          });
    } catch (error) {
      this.documentDetailsAction.loadNexiaObjectDetailsFailed(error);
    }
  }

  public loadReadCriticalFieldValue(id: string, fieldcode: string, versionNumber: number = null): void {
    console.log('loadReadCriticalFieldValue of : id :' + id + ' fieldcode :' + fieldcode);
    try {
      const updateDetails: CdxDocument = _.cloneDeep(this.ngRedux.getState().dynamicSubStores.documentDetails.datas.details);
      // traitement de vérification d'existence debut
      if (fieldcode !== CriticalDataAction._ALL && !!updateDetails.cdx_datas[fieldcode]) {
        // champ déjà récupéré
        this._addVisibleCriticalField(fieldcode);
        return;
      }
      // traitement de vérification d'existence fin
      this.documentDetailsAction.loadNexiaObjectDetailsStart();
      this._loadReadCriticalFieldValue(id, fieldcode, versionNumber).subscribe(
        (doc) => {
          if (fieldcode !== CriticalDataAction._ALL) {
            updateDetails.cdx_datas[fieldcode] = doc.cdx_datas[fieldcode];
            this._addVisibleCriticalField(fieldcode);
          } else {
            updateDetails.cdx_datas = doc.cdx_datas;
            this.showAllCriticalFieldsValue();
          }
          this.documentDetailsAction.loadNexiaObjectDetailsSucceeded(updateDetails);
        },
        (error: HttpErrorResponse) => this.documentDetailsAction.loadDetailsFailed(error));
    } catch (error) {
      this.documentDetailsAction.loadNexiaObjectDetailsFailed(error);
    }
  }

  public deleteDocument(document: CdxDocument, mode: DELETE_MODE = DELETE_MODE.SOFT): Observable<boolean> {

    return this._deleteNexiaObject(document.cdx_id, mode)
      .pipe(
        map((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.documentSearchResultService.deleteStoreDocument(document.cdx_id);
          this.location.back();
          return true;
        }),
        catchError(err => {
          return of(true);
        })
      );
  }

  public hardDeleteDocument(document: CdxDocument): Observable<boolean> {
    return this.deleteDocument(document, DELETE_MODE.HARD);
    // return this._hardDeleteNexiaObject(document.cdx_id)
    //   .pipe(
    //     map((userSocketHttpResponse: UserSocketHttpResponse) => {
    //       this.documentSearchResultService.deleteStoreDocument(document.cdx_id);
    //       this.location.back();
    //       return true;
    //     }),
    //     catchError(err => {
    //       this._errorManagement(new Error('TOASTER_MESSAGES.[DocumentDetails].DELETE_DOCUMENT_FAILED'));
    //       return of(true);
    //     })
    //   );
  }

  public addComment(comment: CdxComment, token: string): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._addComment(comment, token)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, comment.cdx_doc_id);
          this.loadComment(comment.cdx_doc_id, userSocketHttpResponse.id);
          this.loadDocumentHistory(comment.cdx_doc_id);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public updateComment(comment: CdxComment, token: string): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._updateComment(comment, token)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, comment.cdx_doc_id);
          this.loadComment(comment.cdx_doc_id, userSocketHttpResponse.id);
          this.loadDocumentHistory(comment.cdx_doc_id);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public loadComment(docId: string, comId: string): void {
    super._loadComment(docId, comId);
  }

  public deleteComment(comment: CdxComment, token: string): void {
    this._deleteComment(comment, token)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, comment.cdx_doc_id);
          this.loadComment(comment.cdx_doc_id, userSocketHttpResponse.id);
          this.loadDocumentHistory(comment.cdx_doc_id);
        });
  }

  public addReply(docId: string, commentId: string, reply: CdxReply, token: string): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._addReply(docId, commentId, token, reply)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, docId);
          this.loadComment(docId, userSocketHttpResponse.id);
          this.loadDocumentHistory(docId);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public updateReply(docId: string, commentId: string, reply: CdxReply, token: string): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._updateReply(docId, commentId, token, reply)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, docId);
          this.loadComment(docId, userSocketHttpResponse.id);
          this.loadDocumentHistory(docId);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public deleteReply(docId: string, commentId: string, replyId: string, token: string): void {
    this._deleteReply(docId, commentId, token, replyId)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, docId);
          this.loadComment(docId, userSocketHttpResponse.id);
          this.loadDocumentHistory(docId);
        });
  }

  public loadAttachment(docId: string, attId: string, tempAttachmentId: string = null): void {
    super._loadAttachment(docId, attId, tempAttachmentId);
  }

  public deleteAttachment(attachment: CdxAttachment, token: string): void {
    this.documentDetailsAction.deleteAttachmentStart();
    super._deleteAttachment(attachment, token)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          this.updateToken(userSocketHttpResponse.httpResponse.headers, attachment.cdx_doc_id);
          this.loadDocumentHistory(attachment.cdx_doc_id);
          this.documentDetailsAction.deleteAttachmentSucceeded(attachment);
        },
        (error) => {
          this.documentDetailsAction.deleteAttachmentFailed(error);
        });
  }

  public downloadAttachment(docId: string, attId: string, token: string, isThumbnail: boolean): Observable<CdxFile> {
    return super._downloadAttachment(docId, attId, token, isThumbnail);
  }

  public uploadAttachment(docId: string, file: File, token: string): void {
    super._uploadAttachment(docId, file, token);
  }

  public updateAttachment(attachment: CdxAttachment, token: string): void {
    super._updateAttachment(attachment, token);
  }

  public updateMetaArchive(meta: Metadata, action: string, objectId: string, token: string | null = null, updateLock = true): Observable<boolean> {
    return super._updateMetaArchive(meta, action, objectId, token, updateLock);
  }

  public loadDocumentHistory(docId: string): void {
    super._loadNexiaObjectHistory(docId);
  }

  public updateDocumentTeams(document: CdxDocument, teamIds: string[]): Observable<boolean> {
    const booleanSubject$: Subject<boolean> = new Subject<boolean>();
    this._updateTeams(document.cdx_id, document.token, teamIds)
      .subscribe((userSocketHttpResponse: UserSocketHttpResponse) => {
          // we load the document because it is possible that the rights have changed
          this.loadDocument(document.cdx_id);
          booleanSubject$.next(false);
          booleanSubject$.complete();
        },
        (error) => {
          booleanSubject$.next(true);
          booleanSubject$.complete();
        });
    return booleanSubject$.asObservable();
  }

  public loadCurrentActivityDiff(docId: string, evtId: number, isJustOpen: boolean): void {
    console.log('loadCurrentActivityDiff of : document id : ' + docId + 'event id :' + evtId + ' isJustOpen :' + isJustOpen);
    super._loadCurrentActivityDiff(docId, evtId, isJustOpen);
  }
  /*END COMMON*/

  /*START SPECIFIC*/
  protected updateToken(headers: HttpHeaders, id: string): void {
    const documentDetailsState: DocumentDetailsState = this.ngRedux.getState().dynamicSubStores.documentDetails;
    if (!!documentDetailsState && !!documentDetailsState.datas && !!documentDetailsState.datas.details && documentDetailsState.datas.details.cdx_id === id) {
      this.documentDetailsAction.updateToken(headers);
    }
  }

  private readCriticalParams(readCritical: boolean): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      map((params: HttpParams) => {
        if (readCritical) {
          params = params.append('readCritical', 'true');
        }
        return params;
      })
    );
  }

  private getDocumentFile(document: CdxDocument, path: string): OperatorFunction<HttpParams, HttpResponse<any>> {
    return switchMap(params => {
      return this.httpClient.get(
        `${Url.getProtectedApiBaseUrl(this.configAction)}${Url.DOCUMENTS}${document.cdx_id}/${path}${document.cdx_file.cdx_id}`,
        {
          params: params,
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(document.token)
        });
    });
  }

  public getZipDocumentFile(token: string): OperatorFunction<HttpParams, HttpResponse<any>> {
    return switchMap(params => {
      return this.httpClient.get(
        `${Url.getProtectedApiBaseUrl(this.configAction)}${Url.FILE}${Url.ZIP}`,
        {
          params: params,
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(token)
        });
    });
  }

  public  addParamFormat( format: ArchiveFormat ): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
          params = params.append('format', format.toString());
      return params;
  });
  }

  public addParamDocIds( docs: CdxDocument[]): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!docs && docs.length) {
        docs.forEach((doc: CdxDocument) => {
          params = params.append('documentIds', doc.cdx_id);
        });
      }
      return params;
    });
  }

  public getZip( documents: CdxDocument[], format: ArchiveFormat ): Observable<CdxFile> {
    try {
      return this._getFilesAsZip(documents, format).pipe(
        map((response: HttpResponse<any>) => {
          return Utils.httpResponseToCdxFile(response);
        }, (error: HttpErrorResponse) => {
          console.error(error);
          return of(error);
        })
      );

    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  private _downloadDocumentFile(document: CdxDocument, path: string, readCritical = false): Observable<any> {
    try {
      return AbstractDetailsService.query()
        .pipe(
          this.readCriticalParams(readCritical),
          this.getDocumentFile(document, path),
          map((response: HttpResponse<any>) => {
            this.updateToken(response.headers, document.cdx_id);
            return response;
          }, (error: HttpErrorResponse) => {
            console.error(error);
            return of(error);
          }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }
  public _getFile(document: CdxDocument, format: ArchiveFormat): Observable<HttpResponse<ArrayBuffer>> {
    let path;
    if (format === ArchiveFormat.ANNOTED_PDF) {
      path = Url.COMMENTS_FILE;
    } else if (format === ArchiveFormat.ORIGIN) {
      path = Url.FILE;
    } else {
      path = Url.DOCUMENT_PDF;
    }
    return this._downloadDocumentFile(document, path);
  }

  public _getFilesAsZip(documents: CdxDocument[], format: ArchiveFormat): Observable<HttpResponse<ArrayBuffer>> {
    return DocumentDetailsService.query().pipe(
      this.addParamDocIds(documents),
      this.addParamFormat(format),
      this.getZipDocumentFile(documents[0].token)
    );
  }

  public addBodyDocIds( docs: CdxDocument[]): OperatorFunction<HttpParams, Array<string>> {
    return map((params) => {
      const docIds: Array<string> = [];
      if (!!docs && docs.length) {
        docs.forEach((doc: CdxDocument) => {
          docIds.push(doc.cdx_id);
        });
      }
      return docIds;
    });
  }

  public getExcel(documents: CdxDocument[]): Observable<CdxFile> {
    return this._getExcelFile(documents).pipe(
      map((response: HttpResponse<any>) => {
        return Utils.httpResponseToCdxFile(response);
      }, (error: HttpErrorResponse) => {
        console.error(error);
        return of(error);
      })
    );
  }

  public _getExcelFile(documents: CdxDocument[]): Observable<HttpResponse<ArrayBuffer>> {
    return DocumentDetailsService.query().pipe(
      this.addBodyDocIds(documents),
      this.getExcelFile()
    );
  }

  private getExcelFile(): OperatorFunction<Array<string>, HttpResponse<any>> {
    return switchMap(body => {
      return this.httpClient.post(
        `${Url.getProtectedApiBaseUrl(this.configAction)}${Url.FILE}${Url.TO_EXCEL}`, body,
        {
          observe: 'response', responseType: 'arraybuffer'
        });
    });
  }


  public getDocumentFormatFile(document: CdxDocument, formatName: string, formatFileId: string): OperatorFunction<HttpParams, HttpResponse<any>> {
    return switchMap(params => {
      return this.httpClient.get(
        `${Url.getProtectedApiBaseUrl(this.configAction)}${Url.DOCUMENTS}${document.cdx_id}/${Url.FILE}${document.cdx_file.cdx_id}/${Url.FORMATS}${formatName}/${formatFileId}`,
        {
          params: params,
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(document.token)
        });
    });
  }

  public loadDocument(id: string): void {
    try {
      this.documentDetailsAction.loadDetailsStart();
      // const params: HttpParams = new HttpParams()
      //   .set('cdx_id', id)
      //   .set('_limit', '1');
      this.getDocDetailsById(id)
        .subscribe(
          (docDetails) => {
            this.viewService.getRecapView(docDetails.details.cdx_type.code);
            if (docDetails.attachments != null) {
              Utils.orderBy(docDetails.attachments, ['cdx_creation_date']);
            }
            if (docDetails.activities != null) {
              Utils.orderBy(docDetails.activities.content, ['created']);
            }
            this.currentCommentService.removeCurrentComment();
            this.documentDetailsAction.loadDetailsSucceeded(docDetails.details, docDetails.comments, docDetails.attachments, docDetails.activities);
          },
          (error: HttpErrorResponse) => this.documentDetailsAction.loadDetailsFailed(error));
    } catch (error) {
      this.documentDetailsAction.loadDetailsFailed(error);
    }
  }

  public getDocDetailsById(id: string): Observable<DocumentDetails> {
    return this.httpClient.get<DocumentDetails>(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOCUMENTS + id);
  }

  public setDocument(document: CdxDocument, showBlockInfo?: boolean): void {
    try {
      this.documentDetailsAction.loadDetailsStart();
      if (document === undefined || document === null) {
        throw new ErrorEvent('document cannot be null or undefined');
      }
      this.documentDetailsAction.loadDetailsSucceeded(document, null, null, null);
      if (showBlockInfo !== undefined) {
        this.showHideInfosBlock(showBlockInfo);
      }
    } catch (error) {
      this.documentDetailsAction.loadDetailsFailed(error);
    }
  }

  public setDocumentSearchResultService(value: DocumentSearchResultService) {
    this.documentSearchResultService = value;
  }

  public remove() {
    this.documentDetailsAction.removeAll();
  }

  public removeAttachmentOfStore(docId: string, attId: string): void {
    this.documentDetailsAction.deleteAttachmentStart();
    const attachment: CdxAttachment = new CdxAttachment();
    attachment.cdx_id = attId;
    attachment.cdx_doc_id = docId;
    this.documentDetailsAction.deleteAttachmentSucceeded(attachment);
  }

  public downloadDocumentFile(document: CdxDocument, path: string, readCritical = false): Observable<CdxFile> {
    try {
      return this._downloadDocumentFile(document, path, readCritical)
        .pipe(map((response: HttpResponse<any>) => {
          return Utils.httpResponseToCdxFile(response);
        }));
      // return AbstractDetailsService.query()
      //   .pipe(
      //     this.readCriticalParams(readCritical),
      //     this.getDocumentFile(document, path),
      //     map((response: HttpResponse<any>) => {
      //       this.updateToken(response.headers, document.cdx_id);
      //       return Utils.httpResponseToCdxFile(response);
      //     }, (error: HttpErrorResponse) => {
      //       console.error(error);
      //       return of(error);
      //     }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public getOrigFileAsByteArray(document: CdxDocument, path: string, readCritical = false): Observable<BlobFile | null> {
    try {
      return AbstractDetailsService.query()
        .pipe(
          this.readCriticalParams(readCritical),
          this.getDocumentFile(document, path),
          map((response: HttpResponse<any>) => {
            this.updateToken(response.headers, document.cdx_id);
            return Utils.httpResponseToBlobFile(response);
          }, (error: HttpErrorResponse) => {
            console.error(error);
            return of(null);
          })
        );
    } catch (e) {
      return of(null);
    }
  }

  public downloadDocumentFormat(document: CdxDocument, formatName: string, formatFileId: string, readCritical = false): Observable<CdxFile> {
    try {
      return AbstractDetailsService.query()
        .pipe(
          this.readCriticalParams(readCritical),
          this.getDocumentFormatFile(document, formatName, formatFileId),
          map((response: HttpResponse<any>) => {
            this.updateToken(response.headers, document.cdx_id);
            return Utils.httpResponseToCdxFile(response);
          }, (error: HttpErrorResponse) => {
            console.error(error);
            return of(error);
          }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public getDocumentPdf(document: CdxDocument, fromButton: boolean): Observable<Uint8Array> {
    try {
      let urlGetPdf = `${Url.getProtectedApiBaseUrl(this.configAction)}${Url.DOCUMENTS}${document.cdx_id}/${Url.DOCUMENT_PDF}${document.cdx_file.cdx_id}`;
      if (fromButton) {
        urlGetPdf = urlGetPdf + '?readCritical';
      }

      return this.httpClient.get(
        urlGetPdf,
        {
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(document.token)
        })
        .pipe(map((response: HttpResponse<any>) => {
          this.updateToken(response.headers, document.cdx_id);
          const uint8View: Uint8Array = new Uint8Array(response.body);
          return uint8View;
        }, (error: HttpErrorResponse) => {
          console.error(error);
          return of(error);
        }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public downloadThumb(document: CdxDocument): Observable<File> {
    if (!document.cdx_file) {
      return of(null);
    }
    try {
      return this.httpClient.get(
        `${Url.getProtectedApiBaseUrl(this.configAction)}${Url.DOCUMENTS}${document.cdx_id}/${Url.THUMB}${document.cdx_file.cdx_id}`,
        {
          observe: 'response', responseType: 'arraybuffer',
          headers: Utils.objectTokenHeader(document.token).set(HttpErrorInterceptor.BYPASS_HEADER, '')
        })
        .pipe(map((response: HttpResponse<any>) => {
          return Utils.createFile(response);
        }, (error: HttpErrorResponse) => {
          console.error(error);
          return of(error);
        }));
    } catch (err) {
      console.error(err);
      return of(err);
    }
  }

  public showHideInfosBlock(isVisible: boolean): void {
    this.documentDetailsAction.showHideInfoBlockSucceeded(isVisible);
  }

  public checkOfficeLogin(): Observable<AuthenticationResult> {
    return this.shareFileService.checkOfficeLogin();
  }

  public checkoutArchiveToSharePoint(isCritical, meta: Metadata, document: CdxDocument): Observable<boolean> {
    // LOCK FILE IN NEXIA
    if (!meta) {
      meta = new Metadata();
    }
    if (!meta.archive_lock) {
      meta.archive_lock = new Lock();
    }
    meta.archive_lock.locked = true;
    return this.updateMetaArchive(meta, AbstractDetailsService.LOCK_ARCHIVE, document.cdx_id, document.token, false).pipe(
      switchMap((isUpdated: boolean) => {
        if (isUpdated) {
          return this.shareFileService.checkLoginAndUpsertShareFolder();
        } else {
          return of(null);
        }
      }),
      switchMap((shareFolderCreated: boolean | null) => {
        console.log(shareFolderCreated);
        if (Utils.notNullAndNotUndefined(shareFolderCreated)) {
          if (shareFolderCreated) {
            return this.getOrigFileAsByteArray(document, Url.FILE, isCritical).pipe(
              catchError((error) => {
                meta.archive_lock.locked = false;
                meta.archive_lock.lock_information = null;
                return this.updateMetaArchive(meta, AbstractDetailsService.UNLOCK_ARCHIVE, document.cdx_id).pipe(
                  switchMap((isUpdated: boolean) => {
                    return of(null);
                  })
                );
              })
            );
          } else {
            return this.updateMetaArchive(meta, AbstractDetailsService.UNLOCK_ARCHIVE, document.cdx_id).pipe(
              switchMap((isUpdated: boolean) => {
                return of(null);
              })
            );
          }
        }
        return of(null);
      }),
      switchMap((origFile: BlobFile) => {
        if (origFile) {
          return this.shareFileService.addFileToDriveAndCreateShareLink(origFile).pipe(
            catchError((error) => {
              meta.archive_lock.locked = false;
              meta.archive_lock.lock_information = null;
              return this.updateMetaArchive(meta, AbstractDetailsService.UNLOCK_ARCHIVE, document.cdx_id).pipe(
                switchMap((isUpdated: boolean) => {
                  return of(null);
                })
              );
            })
          );
        }
        return of(null);
      }),
      switchMap((shareLinkData: ShareLinkData) => {
        if (!shareLinkData) {
          return of(false);
        }
        console.log('fileId', shareLinkData.fileId);
        console.log('shareLink', shareLinkData.shareLink);
        if (!meta.archive_lock.lock_information) {
          meta.archive_lock.lock_information = new LockInformation();
        }
        meta.archive_lock.lock_information.link = shareLinkData.shareLink;
        meta.archive_lock.lock_information.lock_type = LockType.OFFICE_365;
        meta.archive_lock.lock_information.office_id = shareLinkData.fileId;
        window.open(shareLinkData.shareLink, '_blank');
        return this.updateMetaArchive(meta, AbstractDetailsService.UPDATE_LOCK_ARCHIVE_INFO, document.cdx_id);
      })
    );
  }

  public checkinArchive(meta: Metadata, docId: string): Observable<boolean> {
    const driveId: string = meta.archive_lock.lock_information.office_id;
    return this.shareFileService.checkloginAndCheckinFile(driveId).pipe(
      switchMap((file: File) => {
        return super.updateArchive(docId, file);
      }),
      switchMap((updateDone: boolean) => {
        if (updateDone) {
          return this.deleteDriveItem(meta).pipe(
            map(res => true),
            catchError(err => of(false))
          );
        }
        return of(false);
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  public loadMetadata(objectId: string): Observable<boolean> {
    return this._loadMetadata(objectId);
  }

  public deleteDriveItem(meta: Metadata): Observable<HttpResponse<any>> {

    try {
      const driveId: string = meta.archive_lock.lock_information.office_id;
      meta.archive_lock.locked = false;
      meta.archive_lock.lock_information = null;
      return this.shareFileService.deleteDriveItem(driveId);
    } catch (e) {
      return of (null);
    }
  }

  public deleteDriveItemAndUnlockArchive(meta: Metadata, objectId: string): Observable<boolean> {
    return this.deleteDriveItem(meta).pipe(
      catchError((error) => {
        console.log(error);
        return of(true);
      }),
      switchMap((response: HttpResponse<any>) => {
        return this.updateMetaArchive(meta, AbstractDetailsService.UNLOCK_ARCHIVE, objectId);
      })

    );
  }

  public deleteDraft(objectId: string): Observable<boolean> {
    const deleteDraft$: Subject<boolean> = new Subject<boolean>();
    this.indexationService.deleteDraft(objectId)
      .subscribe(value => {
        this.loadMetadata(objectId);
        deleteDraft$.next(true);
      });
    return deleteDraft$;
  }
  /*END SPECIFIC*/
}
