import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AzureService, UploadSessionObject } from '../azure.service';
import { AuthenticationService } from '../../../../modules/authentication/authentication.service';
import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';
import { HttpErrorInterceptor } from '../../../../modules/http-error-interceptor/http-error-interceptor';
import { BlobFile } from '../../../models/blob-file';
import { ShareLinkData } from '../../../models/share-link-data';

@Injectable({
  providedIn: 'root'
})
export class ShareFileService extends AzureService {

  private static readonly DRIVE_URL = ShareFileService.AZURE_URL + 'drive/';
  private static readonly ROOT = 'root:/';
  private static readonly CONTENT = '/content';
  private static readonly ROOT_CHILDREN = 'root/children';
  private static readonly ITEMS = 'items/';
  private static readonly CREATE_LINK = '/createLink';
  private static readonly OPERATOR = ':';
  public static readonly FORCE_DELETE_DRIVE_ITEM = 'bypass-shared-lock';

  private shareDirName = 'Nexia';

  private static createNexiaFolder(name: string): any {
    const folder: any = {};
    folder['name'] = name;
    folder['folder'] = {};
    folder['@microsoft.graph.conflictBehavior'] = 'fail';
    return folder;
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) msalGuardConfig: MsalGuardConfiguration,
    broadcastService: MsalBroadcastService,
    msAuthService: MsalService,
    httpClient: HttpClient,
    translateService: TranslateService,
    authenticationService: AuthenticationService,
    configAction: ConfigurationActions
  ) {
    super(msalGuardConfig, broadcastService, msAuthService, httpClient, translateService, authenticationService, configAction);
    const configShareDirName = this.configAction.get('application.AZURE_MSAL_SHARE_DIR_NAME');
    this.shareDirName = !!configShareDirName && configShareDirName.length ? configShareDirName : this.shareDirName;
  }

  public checkOfficeLogin(): Observable<AuthenticationResult> {
    return this.checkLogin();
  }

  public checkLoginAndUpsertShareFolder(): Observable<boolean> {
    const isDone: Subject<boolean> = new Subject<boolean>();
    this.checkLogin().pipe(
      switchMap((response: AuthenticationResult) => {
        if (response) {
          return this.createDriveFolderIfDoesNotExist();
        } else {
          // TODO treat login out
           throw new Error('Failed to login');
        }
      })
    ).subscribe((response: any) => {
      console.log(response);
      isDone.next(true);
      isDone.complete();
    }, error => {
      console.error('error: ', error);
      if (error.status === 404) {
        // TODO folder allready created
      }
      if (error.message === 'user_cancelled: User cancelled the flow.') {
        // TODO user canceled the azure connection
      }
      isDone.next(false);
      isDone.complete();
    });
    return isDone.asObservable();
  }

  public checkloginAndCheckinFile(driveFileId: string): Observable<File | null> {
   return this.checkLogin().pipe(
      switchMap((response: AuthenticationResult) => {
        if (response) {
          return this.checkinFile(driveFileId);
        } else {
          // TODO treat login out
          throw new Error('Failed to login');
        }
      }),
     catchError((error) => {
       if (error.message === 'user_cancelled: User cancelled the flow.') {
         // TODO user canceled the azure connection
       }
       return throwError(error);
     })
    );
  }

  public addFileToDriveAndCreateShareLink(file: BlobFile): Observable<any> {
    let fileId = '';
    const headers = new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '');
    let request: Observable<HttpResponse<any>>;
    if (file.fileSize >= ShareFileService.BIG_FILE_SIZE) {
      const url = ShareFileService.DRIVE_URL + ShareFileService.ROOT + this.shareDirName + '/' + file.filename + ShareFileService.OPERATOR + ShareFileService.CREATE_UPLOAD_SESSION;
      const bodyObject: any = {
        item: ShareFileService.createMsDriveBodyItem()
      };
      const uploadSessionObject: UploadSessionObject = {
        file,
        headers,
        url,
        bodyItem: bodyObject
      };
      request = this.uploadFileBySession(uploadSessionObject);
    } else {
      request = this.uploadFileToDrive(file, headers);
    }
    return request.pipe(
      switchMap((response: HttpResponse<any>) => {
        fileId = response.body.id;
        return this.createShareLink(fileId);
      }),
      catchError((error) => {
        // TODO treat error for upload file on drive
        console.error(error);
        return throwError(error);
      }),
      map((shareLinkData: any) => {
        return {
          fileId,
          shareLink: shareLinkData.link.webUrl
        } as ShareLinkData;
      })
    );
  }

  private checkinFile(driveFileId: string): Observable<File | null> {
    return forkJoin([this.getDriveFile(driveFileId), this.getDriveItem(driveFileId)])
      .pipe(
        switchMap(([blob, driveItem]: [HttpResponse<Blob>, HttpResponse<any>]) => {
          console.log(blob);
          console.log(driveItem);
          return of(new File([blob.body], driveItem.body.name , {type: blob.body.type}));
        }),
        catchError((error: HttpErrorResponse) => {
          return of(null);
        })
      );
  }

  private getDriveFile(driveFileId: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId + ShareFileService.CONTENT, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  private getDriveItem(driveFileId: string): Observable<HttpResponse<any>> {
    return this.httpClient.get(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId, {
      observe: 'response'
    });
  }

  public deleteDriveItem(driveFileId: string): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders()
      .set('prefer', ShareFileService.FORCE_DELETE_DRIVE_ITEM)
      .set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.httpClient.delete(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId, {
      headers,
      observe: 'response'
    });
  }

  private createDriveFolderIfDoesNotExist(): Observable<any> {
    const folder = ShareFileService.createNexiaFolder(this.shareDirName);
    return this.getDriveFolder().pipe(
      catchError((error: HttpErrorResponse) => {
        const parsedError: any = JSON.parse(error.error);
        if (error.status === 404 && parsedError.error.code === 'itemNotFound') {
          return of(null);
        }
      }),
      switchMap((driveFolder) => {
        if (driveFolder) {
          return of(JSON.parse(driveFolder.body));
        } else {
          return this.httpClient.post(ShareFileService.DRIVE_URL + ShareFileService.ROOT_CHILDREN, folder, {
            responseType: 'text',
            observe: 'response'
          }).pipe(
            map((response: HttpResponse<any>) => JSON.parse(response.body))
          );
        }
      })
    );
  }

  private createShareLink(fileId: string): Observable<any> {
    const shareLinkData = {
      type: 'edit',
      scope: 'organization'
    };
    return this.httpClient.post(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + fileId + ShareFileService.CREATE_LINK, shareLinkData);
  }

  private uploadFileToDrive(file: BlobFile, headers: HttpHeaders): Observable<HttpResponse<any>> {
    return this.httpClient.put(ShareFileService.DRIVE_URL + ShareFileService.ROOT + this.shareDirName + '/' + file.filename + ShareFileService.OPERATOR + ShareFileService.CONTENT,
      file.blob, { headers: headers, observe: 'response' }
    );
  }

  private getDriveFolder(): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.httpClient.get(ShareFileService.DRIVE_URL + ShareFileService.ROOT + this.shareDirName, {
      headers: headers,
      responseType: 'text',
      observe: 'response'
    });
  }
}
