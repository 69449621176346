import { Reducer } from 'redux';

import { PayloadAction } from '../../../../store/payload-action';
import { RouterPathInfo } from '../../../models/router-path-info';

export class RouterPathState {
  datas: RouterPathInfo;
  loading: boolean;
  error: any;
}

const ROUTER_PATH_INITIAL_STATE: RouterPathState = {
  datas: {
    history: [],
    closeRoute: '/search'
  },
  loading: false,
  error: null,
};

export class RouterPathConstant {

  static readonly LOAD_ROUTER_PATH_START = 'LOAD_ROUTER_PATH_START';
  static readonly LOAD_ROUTER_PATH_SUCCEEDED = 'LOAD_ROUTER_PATH_SUCCEEDED';
  static readonly LOAD_ROUTER_PATH_FAILED = 'LOAD_ROUTER_PATH_FAILED';
  static readonly REMOVE_ALL_SUCCEEDED = 'REMOVE_ALL_SUCCEEDED';

}

export const routerPathReducer: Reducer<RouterPathState> = (state: RouterPathState = ROUTER_PATH_INITIAL_STATE, action: PayloadAction<RouterPathInfo>): RouterPathState => {
  switch (action.type) {
    case RouterPathConstant.LOAD_ROUTER_PATH_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RouterPathConstant.LOAD_ROUTER_PATH_SUCCEEDED:
      const stateRouterPathInfoCopy = {
        ...state.datas
      };
      stateRouterPathInfoCopy.history = [...stateRouterPathInfoCopy.history, ...action.payload.history];
      if (!!action.payload.closeRoute) {
        stateRouterPathInfoCopy.closeRoute = action.payload.closeRoute;
      }
      return {
        ...state,
        datas: stateRouterPathInfoCopy,
        loading: false,
        error: null,
      };
    case RouterPathConstant.LOAD_ROUTER_PATH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RouterPathConstant.REMOVE_ALL_SUCCEEDED:
      return {
        ...state,
        datas: {
          history: [],
          closeRoute: '/search'
        },
        loading: false,
        error: null,
      };
  }
  return state;
};
