import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../../store/model';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { Team } from '../../../../models/team';
import { CurrentTeamConstant, currentTeamReducer, CurrentTeamState } from '../reducer/current-team.reducer';

@Injectable({
  providedIn: 'root'
})
export class CurrentTeamAction {
  private localStore: ObservableStore<CurrentTeamState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CURRENT_TEAM], currentTeamReducer);
  }

  public loadCurrentTeamStarted(): void {
    this.localStore.dispatch({
      type: CurrentTeamConstant.LOAD_CURRENT_TEAM_START
    });
  }

  public loadCurrentTeamSucceeded(team: Team): void {
    this.localStore.dispatch({
      type: CurrentTeamConstant.LOAD_CURRENT_TEAM_SUCCEEDED,
      payload: team
    });
  }

  public loadCurrentTeamFailed(error: any) {
    this.localStore.dispatch({
      type: CurrentTeamConstant.LOAD_CURRENT_TEAM_FAILED,
      error: error
    });
  }

  public removeCurrentTeam() {
    this.localStore.dispatch({
      type: CurrentTeamConstant.REMOVE_CURRENT_TEAM
    });
  }
}
