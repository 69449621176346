import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../store/model';
import { MenuActionConstant, menuReducer, MenuState } from '../reducer/menu.reducer';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';
import { MenuItem } from '../../../models/menu-item';

@Injectable({
  providedIn: 'root'
})
export class MenuAction {
  private localStore: ObservableStore<MenuState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.MENU_ITEMS], menuReducer);
  }

  public loadStarted(): void {
    this.localStore.dispatch({
      type: MenuActionConstant.LOAD_MENU_ITEMS_START
    });
  }

  public loadSucceeded(menuItems: MenuItem[]): void {
    this.localStore.dispatch({
      type: MenuActionConstant.LOAD_MENU_ITEMS_SUCCEEDED,
      payload: menuItems
    });
  }

  public loadFailed(error: any) {
    this.localStore.dispatch({
      type: MenuActionConstant.LOAD_MENU_ITEMS_FAILED,
      error: error
    });
  }
}
