<div *ngIf="!!currentMaintenanceInfo" class="zone" [style.height]="'80px'" (click)="onClose()">
  <ng-container [ngSwitch]="currentMaintenanceInfo.type">
    <h3 class="maintenanceInfo-title" *ngSwitchCase="MaintenanceType.MAINTENANCE_PRE">
      <span *ngIf="translateMaintenanceMsgWithDate">{{ currentMaintenanceInfo.message | translate:{enterMaintenanceDate: enterMaintenanceDate} }}</span>
      <span *ngIf="!translateMaintenanceMsgWithDate">{{ currentMaintenanceInfo.message | translate:{enterMaintenanceHour: enterMaintenanceHour} }}</span>
    </h3>
    <h3 class="maintenanceInfo-title" *ngSwitchCase="MaintenanceType.MAINTENANCE_ON">{{ currentMaintenanceInfo.message | translate }}</h3>
    <h3 class="maintenanceInfo-title" *ngSwitchCase="MaintenanceType.MAINTENANCE_OFF">
      {{ currentMaintenanceInfo.message | translate:{finishedMaintenanceHour: finishedMaintenanceHour}  }}
    </h3>
  </ng-container>
</div>
