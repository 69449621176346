import { Component, OnInit } from '@angular/core';

import { AlertModalService } from '../../service/alert-modal.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  error: any = null;

  constructor(
    private alertService: AlertModalService
  ) { }

  ngOnInit() {
  }

  isDisplayed() {
    const flag = this.alertService.hasError();
    if ( flag && this.error === null ) {
      this.error = this.alertService.getError();
    }
    return flag;
  }

  onAbort() {
    this.alertService.releaseError();
    this.error = null;
  }

  onConfirm(url: string) {
    this.alertService.releaseError();
    this.error = null;
    if ( !!url ) {
      location.replace(url);
    }
  }

}
