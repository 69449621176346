import { Status } from './status';

export class FlatTreeNode {
  constructor(public value: string,
              public label: string,
              public level = 1,
              public expandable = false,
              public parent: FlatTreeNode = null,
              public isLoading = false,
              public expanded = false,
              public readOnlyValue = false,
              public newEntry = false,
              public moreThanVisible = false,
              public objectStatus: Status = Status.ACTIVE,
              public page: number = 0
  ) {
  }
}
