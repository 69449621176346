import { NgModule } from '@angular/core';

import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

@NgModule({
  providers: [],
  declarations: [AuthCallbackComponent],
})
export class AuthenticationModule {
  constructor() { }
}
