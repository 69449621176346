<div class="tooltipContainer-titleBody">
  <ng-container *ngIf="(!isTwoRows); else twoRows">
    <span class="tooltip-text oneRow-title">
        {{tooltipTitle}}
    </span>

    <span class="tooltip-text oneRow-body">
        {{value}}
    </span>
  </ng-container>
</div>

<ng-template #twoRows>
  <p class="tooltip-text twoRows-title">
    {{tooltipTitle}}
  </p>
  <p class="tooltip-text twoRows-body">
    {{value}}
  </p>
</ng-template>
