import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


import { AuthenticationService } from './../authentication.service';

@Component({
  selector: 'app-auth-callback',
  template: ''
})
export class AuthCallbackComponent implements OnInit, OnDestroy {

  subs = [];
  token;


  constructor(
    private activeRoute: ActivatedRoute,
    private actions: AuthenticationService
  ) { }

  ngOnInit() {
    if (this.activeRoute.fragment) {
      this.subs.push(this.activeRoute.fragment.subscribe(fragment => {
        const f = fragment.match(/^(.*?)&/);
        if (f) {
          const token = f[1].replace('access_token=', '');
          this.token = token;
          this.actions.validateAuthToken(token);
        }
      }));
    } else {
      this.subs.push(this.activeRoute.queryParams.subscribe(
        params => {
          const token = params['access_token'];
          if (!token) {
            return;
          }
          this.token = token;
          this.actions.validateAuthToken(token);
        }
      ));
    }
  }

  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }

}
