import { Reducer } from 'redux';
import { PayloadAction } from '../../../../store/payload-action';
import { TaskForm } from '../../../models/task-form';

export interface TaskFormState {
  datas: TaskForm;
  loading: boolean;
  error: any;
}

const VIEW_INITIAL_STATE: TaskFormState = {
  datas: null,
  loading: false,
  error: null,
};

export class TaskFormConstant {
  static readonly ADD_TASK_FORM_START = '[TASK_FORM].ADD_TASK_FORM_START';
  static readonly ADD_TASK_FORM_SUCCEEDED = '[TASK_FORM].ADD_TASK_FORM_SUCCEEDED';
  static readonly ADD_TASK_FORM_FAILED = '[TASK_FORM].ADD_TASK_FORM_FAILED';

  static readonly REMOVE_TASK_FORM_SUCCEEDED = '[TASK_FORM].REMOVE_TASK_FORM_SUCCEEDED';
}

export const taskFormReducer: Reducer<TaskFormState> = (state: TaskFormState = VIEW_INITIAL_STATE, action: PayloadAction<TaskForm>): TaskFormState => {
  switch (action.type) {
    case TaskFormConstant.ADD_TASK_FORM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TaskFormConstant.ADD_TASK_FORM_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case TaskFormConstant.ADD_TASK_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TaskFormConstant.REMOVE_TASK_FORM_SUCCEEDED:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
  }
  return state;
};
