import { Reducer } from 'redux';
import { PayloadAction } from '../../../../store/payload-action';
import { MenuItem } from '../../../models/menu-item';


export interface MenuState {
  datas: MenuItem[];
  loading: boolean;
  error: any;
}
const MENU_INITIAL_STATE: MenuState = {
  datas: [],
  loading: false,
  error: null,
};

export class MenuActionConstant {
  static readonly LOAD_MENU_ITEMS_START = 'LOAD_MENU_ITEMS_START';
  static readonly LOAD_MENU_ITEMS_SUCCEEDED = 'LOAD_MENU_ITEMS_SUCCEEDED';
  static readonly LOAD_MENU_ITEMS_FAILED = 'LOAD_MENU_ITEMS_FAILED';
}

export const menuReducer: Reducer<MenuState> = (state: MenuState = MENU_INITIAL_STATE, action: PayloadAction<MenuItem[]>) => {
  switch (action.type) {
    case MenuActionConstant.LOAD_MENU_ITEMS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MenuActionConstant.LOAD_MENU_ITEMS_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case MenuActionConstant.LOAD_MENU_ITEMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
