import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { RegionItemI18n } from '../../../../models/region-item-i18n';
import { FlatTreeNode } from '../../../../models/flat-tree';
import { ThesaurusService } from '../../../../services/thesaurus/thesaurus.service';
import { Field } from '../../../../models/field';
import { SearchResultEmitter } from '../../components/thesaurus-tree/thesaurus-tree.component';
import { DataVisibility } from '../../../../models/data-visibility';

export class ThesaurusModalExchangeData {
  exchangeAction: ExchangeAction;
  newRegionItems: RegionItemI18n[];
  oldRegionItem: RegionItemI18n;
  treeNode: FlatTreeNode;

  constructor(exchangeAction: ExchangeAction, newRegionItems: RegionItemI18n[], oldRegionItem: RegionItemI18n) {
    this.exchangeAction = exchangeAction;
    this.newRegionItems = newRegionItems;
    this.oldRegionItem = oldRegionItem;
  }
}

export interface ThesaurusModalDialogData {
  thesaurusRootCode: string;
  regionEntrypointCode: string;
  selectedRegionItems: RegionItemI18n[][];
  maxSelection: number;
  exchangeData: Subject<ThesaurusModalExchangeData>;
  autopostage: boolean;
  modalTitle: string;
  visibility: DataVisibility;
  showSearchBtn: boolean;
  field: Field;
}

export enum ExchangeAction {
  ADD = 'add',
  REMOVE = 'remove'
}

@Component({
  selector: 'app-thesaurus-modal',
  templateUrl: './thesaurus-modal.component.html',
  styleUrls: ['./thesaurus-modal.component.scss']
})
export class ThesaurusModalComponent implements OnInit {

  public loading = true;
  public showNoSearchResult = false;
  public searchResultEmitter: SearchResultEmitter = null;
  public currentLanguage: string;
  constructor(
    public dialogRef: MatDialogRef<ThesaurusModalComponent>,
    private thesaurusService: ThesaurusService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ThesaurusModalDialogData
  ) { }

  ngOnInit() {
    this.currentLanguage = this.translateService.currentLang;
    this.loading = false;
  }

  public simpleSelection(node: FlatTreeNode): void {
    if ( this.data.visibility !== DataVisibility.READONLY) {
      const regionItemToRemove: RegionItemI18n = this.getRegionItemToRemove(node);
      if (!!regionItemToRemove) {
        // remove the region
        this.data.exchangeData.next(new ThesaurusModalExchangeData(ExchangeAction.REMOVE, [], regionItemToRemove));
        this.data.selectedRegionItems = [];
      } else {
        // add region to ADD
        this.addRegionItem(this.data.thesaurusRootCode, node.value, false);
      }
      // we need to set a timeout in order to have the time to receive the data in the subscription
      setTimeout(() => {
        this.dialogRef.close();
      }, 100);
    }
  }

  private searchForRegionItemByTreeNodeParent(node: FlatTreeNode): RegionItemI18n {
    let regionItemToRemove: RegionItemI18n = null;
    const parentNode: FlatTreeNode = node.parent;
    if (!!parentNode) {
      regionItemToRemove = this.getRegionItemToRemove(parentNode);
      if (!!regionItemToRemove) {
        return regionItemToRemove;
      } else {
        return this.searchForRegionItemByTreeNodeParent(parentNode);
      }
    } else {
      return regionItemToRemove;
    }
  }

  public multipleSelection(exchangeData: ThesaurusModalExchangeData): void {
    if ( this.data.visibility !== DataVisibility.READONLY) {
      switch (exchangeData.exchangeAction) {
        case ExchangeAction.ADD:
          if (this.data.autopostage) {
            // check for parent
            const searchedRegionItemToRemove: RegionItemI18n = this.searchForRegionItemByTreeNodeParent(exchangeData.treeNode);
            if (!!searchedRegionItemToRemove) {
              this.removeRegionItem(searchedRegionItemToRemove);
            }
          }
          this.addRegionItem(this.data.thesaurusRootCode, exchangeData.treeNode.value, true);
          break;
        case ExchangeAction.REMOVE:
          this.removeRegionItem(this.getRegionItemToRemove(exchangeData.treeNode));
          break;
      }
    }
  }

  public searchResultEvent(searchResultEmitter: SearchResultEmitter): void {
    if (!searchResultEmitter.closeModal) {
      this.searchResultEmitter = searchResultEmitter;
      this.showNoSearchResult = true;
    } else {
      this.dialogRef.close();
    }
  }

  public closeNoSearchResult(): void {
    this.searchResultEmitter = null;
    this.showNoSearchResult = false;
  }

  private removeRegionItem(regionItemToRemove: RegionItemI18n): void {
    this.data.exchangeData.next(new ThesaurusModalExchangeData(ExchangeAction.REMOVE, [], regionItemToRemove));
    let foundIndex = -1;
    this.data.selectedRegionItems.forEach((regionItems: RegionItemI18n[], index) => {
      if (foundIndex >= 0) {
        return;
      }
      if (regionItems[regionItems.length - 1].value === regionItemToRemove.value) {
        foundIndex = index;
      }
    });
    if (foundIndex >= 0) {
      this.data.selectedRegionItems.splice(foundIndex, 1);
    }
  }

  private getRegionItemToRemove(node: FlatTreeNode): RegionItemI18n {
    let regionItemToRemove: RegionItemI18n = null;
    if (this.data.selectedRegionItems.length) {
      this.data.selectedRegionItems.forEach((regionItems: RegionItemI18n[]) => {
        if (!regionItems.length) {
          return;
        }
        if (regionItems[regionItems.length - 1].value === node.value) {
          regionItemToRemove = regionItems[regionItems.length - 1];
        }
        if (!!regionItemToRemove) {
          return;
        }
      });
    }
    return regionItemToRemove;
  }

  private addRegionItem(thesaurusRootCode: string, nodeValue: string, isMultiple: boolean): void {
    this.thesaurusService.getThesaurusItemsWithStatusByLastThesaurusItemCode(thesaurusRootCode, nodeValue).subscribe((regionItems: RegionItemI18n[]) => {
      this.data.exchangeData.next(new ThesaurusModalExchangeData(ExchangeAction.ADD, regionItems, null));
      if (isMultiple) {
        this.data.selectedRegionItems = [...this.data.selectedRegionItems, regionItems];
      } else {
        this.data.selectedRegionItems = [regionItems];
      }
    });
  }

}
