import { CdxFile } from './cdx-file';
import { EsFile } from './EsFile';
import { EsAuthor } from './EsAuthor';

export class CdxAttachment {
  cdx_id: string;
  cdx_doc_id: string;
  cdx_author: EsAuthor;
  cdx_modification_author: EsAuthor;
  cdx_creation_date: number;
  cdx_modification_date: number;
  cdx_title: string;
  cdx_file: EsFile;
  cdx_file_type: string;
  cdx_status: string;
  cdx_doc_version: number;
  cdx_thumbnail: CdxFile;
}
