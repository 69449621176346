// import { indexBy, prop } from 'ramda';

export function dynamicReducers(state = {}, action) {
  switch (action.type) {
    case 'RESET':
    return {};
    case 'RESET_AFTER_MAINTENANCE':
      action.payload.forEach((storeKey: string) => {
        state[storeKey] = undefined;
      });
    return state;
    default: return state;
  }
 }
