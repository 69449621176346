import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastaService } from 'ngx-toasta';
import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';
import { TaskFormAction } from '../action/task-form.action';
import { TaskForm } from '../../../models/task-form';
import { TaskFormValue, TaskFormValueType } from '../../../models/task-form-value';
import { TaskFormField, TaskFormFieldType } from '../../../models/task-form-field';
import { Url } from '../../../models/url';

@Injectable({
  providedIn: 'root'
})
export class TaskFormService {
  /* tslint:disable-next-line */

  constructor(
    private taskFormAction: TaskFormAction,
    private httpClient: HttpClient,
    private configAction: ConfigurationActions,
    private translateService: TranslateService,
    private toastaService: ToastaService
  ) { }

  private getTaskFormMockUrl(): string { // TODO remove API mock
    return `${this.configAction.get('application.API_MOCK_URL')}/assets/mock/task/taskForm.json`;
  }

  private getTaskFormValuesMockUrl(): string { // TODO remove API mock
    return `${this.configAction.get('application.API_MOCK_URL')}/assets/mock/task/taskFormValues.json`;
  }

  private mergeTaskFormWithTaskValues(taskForm: TaskForm, taskFormValues: {[key: string]: TaskFormValue}): TaskForm {
    taskForm.components.forEach((taskFormField: TaskFormField) => {
      const taskFormValue: TaskFormValue = taskFormValues[taskFormField.key];
      if (!!taskFormValue) {
        switch (taskFormValue.type) {
          case TaskFormValueType.STRING:
          case TaskFormValueType.INTEGER:
          case TaskFormValueType.BOOLEAN:
          case TaskFormValueType.OBJECT:
            taskFormField.value = taskFormValue.value;
            break;
        }
      } else {
        switch (taskFormField.type) {
          case TaskFormFieldType.TEXTFIELD:
          case TaskFormFieldType.NUMBER:
          case TaskFormFieldType.SELECT:
            taskFormField.value = taskFormField.defaultValue ? taskFormField.defaultValue : null;
            break;
          case TaskFormFieldType.CHECKBOX:
            taskFormField.value = taskFormField.defaultValue ? taskFormField.defaultValue : false;
            break;
        }
      }
    });
    return taskForm;
  }

  public getTaskForm(taskId: string): void {
    try {
      this.taskFormAction.loadTaskFormStart();
      const taskFormRequest$: Observable<TaskForm> = this.httpClient.get<TaskForm>(
        Url.getCamundaProtectedApiBaseUrl(this.configAction) + Url.TASK + taskId + '/' + Url.DEPLOYED_FORM
      );
      // const taskFormRequest$: Observable<TaskForm> = this.httpClient.get<TaskForm>(this.getTaskFormMockUrl());
      const taskFormValuesRequest$: Observable<{[key: string]: TaskFormValue}> = this.httpClient.get<{[key: string]: TaskFormValue}>(
        Url.getCamundaProtectedApiBaseUrl(this.configAction) + Url.TASK + taskId + '/' + Url.FORM_VARIABLES
      );
      // const taskFormValuesRequest$: Observable<{[key: string]: TaskFormValue}> = this.httpClient.get<{[key: string]: TaskFormValue}>(this.getTaskFormValuesMockUrl());
      forkJoin([taskFormRequest$, taskFormValuesRequest$])
        .subscribe(([taskForm, taskFormValues]: [TaskForm, {[key: string]: TaskFormValue}]) => {
          this.taskFormAction.loadTaskFormSucceeded(this.mergeTaskFormWithTaskValues(taskForm, taskFormValues));
      },
          (error: HttpErrorResponse) =>
          this.taskFormAction.loadTaskFormFailed(error)
          );
    } catch (err) {
      console.error('error', err);
      this.taskFormAction.loadTaskFormFailed(err);
    }
  }

  public saveTaskForm(processId: string, objectToSave: any): void {
    console.log('save', objectToSave);
    try {
      this.httpClient.post(Url.getCamundaProtectedApiBaseUrl(this.configAction) + Url.PROCESS_INSTANCE + processId + '/' + Url.VARIABLES, objectToSave)
        .subscribe(() => {
            console.log('task form variables saved');
            this.toastaService.info(this.translateService.instant('TOASTER_MESSAGES.TASK_FORM.SAVE_FORM_VARIABLES_SUCCEEDED'));
          },
          (error: HttpErrorResponse) => {
            console.error('task form variables failed to be saved');
            this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.TASK_FORM.SAVE_FORM_VARIABLES_FAILED'));
          }
        );
    } catch (error) {
      this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.TASK_FORM.SAVE_FORM_VARIABLES_FAILED'));
      console.error('error', error);
    }
  }

  public validateTaskForm(taskId: string, objectToValidate: any): void {
    console.log('validate', objectToValidate);
    try {
      this.httpClient.post(Url.getCamundaProtectedApiBaseUrl(this.configAction) + Url.TASK + taskId + '/' + Url.COMPLETE, objectToValidate)
        .subscribe(() => {
            console.log('task form completed');
            this.toastaService.info(this.translateService.instant('TOASTER_MESSAGES.TASK_FORM.COMPLETE_SUCCEEDED'));
          },
          (error: HttpErrorResponse) => {
            console.error('task form failed to be completed');
            this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.TASK_FORM.COMPLETE_TASK_FAILED'));
          }
        );
    } catch (error) {
      this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.TASK_FORM.COMPLETE_TASK_FAILED'));
      console.error('error', error);
    }
  }
}
