

export enum AccessFunction {
  INDEX = 'INDEX',
  INDEXENTITY = 'INDEXENTITY',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  HARDDELETE = 'HARDDELETE',
  READCOMMENT = 'READCOMMENT',
  CREATECOMMENT = 'CREATECOMMENT',
  DELETECOMMENT = 'DELETECOMMENT',
  UPDATECOMMENT = 'UPDATECOMMENT',
  READATTACHMENT = 'READATTACHMENT',
  CREATEATTACHMENT = 'CREATEATTACHMENT',
  DELETEATTACHMENT = 'DELETEATTACHMENT',
  UPDATEATTACHMENT = 'UPDATEATTACHMENT',
  READHISTORY = 'READHISTORY',
  ADMINCOMMENT = 'ADMINCOMMENT',
  ADMINATTACHMENT = 'ADMINATTACHMENT',
  INDEX_TEAM = 'INDEX_TEAM',
  INDEX_TEAM_ALL = 'INDEX_TEAM_ALL',
  INDEX_TEAM_ADMIN = 'INDEX_TEAM_ADMIN',
  TEAM = 'TEAM',
  TEAM_ALL = 'TEAM_ALL',
  TEAM_ADMIN = 'TEAM_ADMIN',
  READCRITICAL = 'READCRITICAL'
}
export class Access {
  domain: string;
  region: string;
  family: string;
  docTypes: string[];
}
