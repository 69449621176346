import { Injectable } from '@angular/core';
import { LoadableThesaurus, LoadableThesaurusItem } from '../../../models/loadable-thesaurus';
import { FlatTreeNode } from '../../../models/flat-tree';
import { Status } from '../../../models/status';

@Injectable()
export class TreeService {

  public fromThesaurus(th: LoadableThesaurus): FlatTreeNode {
    const level = 0;
    const rootKey = Object.keys(th.items)[0];
    const root: LoadableThesaurusItem = th.items[rootKey];
    const hasChildren = root.hasChildren || (root.items != null && Object.keys(root.items).length > 0);
    const parentValue = null;

    const roValue = root.status !== Status.ACTIVE;

    return new FlatTreeNode(root.value, root.label, level, hasChildren, parentValue, false, false, roValue, false, false, root.status);
  }

  public fromItem(thNode: LoadableThesaurusItem, parentTreeNode: FlatTreeNode): FlatTreeNode {
    const level = parentTreeNode == null ? 0 : parentTreeNode.level + 1;
    const hasChildren = thNode.hasChildren || (thNode.items != null && Object.keys(thNode.items).length > 0);

    const parentValue = parentTreeNode;

    const roValue = thNode.status !== Status.ACTIVE;

    return new FlatTreeNode(thNode.value, thNode.label, level, hasChildren, parentValue, false, false, roValue, false, false, thNode.status);
  }


}
