import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Utils } from '../../utils/utils';
import { Url } from '../../models/url';
import { ConfigurationActions } from '../../../modules/configuration/configuration.actions';

@Injectable()
export class I18nService {
  private observableResponse: Subject<boolean> = new Subject<boolean>();
  constructor(
    private configurationAction: ConfigurationActions,
    private httpClient: HttpClient,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  public loadI18n(): Observable<boolean> {
    this.loadTranslations(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.loadTranslations(params.lang);
    });
    return this.observableResponse.asObservable();
  }

  private loadTranslations(lang: string) {
    if (!Utils.notNullAndNotUndefined(lang)) {
      this.httpClient.get<any>(Url.getProtectedApiBaseUrl(this.configurationAction) + 'languages', { observe: 'response' }).subscribe((response: HttpResponse<any>) => {
        let defaultLanguage = 'fr';
        response.body.forEach((languageItem: any) => {
          if (languageItem.defaultLanguage) {
            defaultLanguage = languageItem.code;
            return;
          }
        });
        const currentLanguage: string = response.headers.get('content-language');
        this.translateService.setDefaultLang(defaultLanguage);
        this.translateService.use(currentLanguage);
        this.document.documentElement.lang = currentLanguage;
      }, (error) => {
        this.observableResponse.next(false);
      });
    } else {
      this.httpClient.get<any>(Url.getProtectedApiBaseUrl(this.configurationAction) + 'i18n/' + lang, { observe: 'response' })
        .subscribe((httpResponse: HttpResponse<any>) => {
          console.log(httpResponse.body);
          this.translateService.setTranslation(lang, httpResponse.body, true);
          this.observableResponse.next(true);
        }, (error) => {
          this.observableResponse.next(false);
        });
    }
  }
}
