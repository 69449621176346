import { FromJson } from './fromJson';
import { DomainRegion } from './domain-region';
import { DomainRegionEntrypoint } from './domain-region-entrypoint';

export class DomainItem extends FromJson {
  label: string;
  text: string;
  code: string;
  entityType: string;
  region: DomainRegion;
  entrypoint: DomainRegionEntrypoint;

  static fromJson<T>(json: any, ctor: (new () => T)): T {
    const ws = super.call(json, ctor);
    ws.text = !!json.text ? json.text : ws.label;
    return ws;
  }
}
