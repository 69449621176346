import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastaService } from 'ngx-toasta';
import { Injectable } from '@angular/core';
import { ConfigurationActions } from '../../../../../modules/configuration/configuration.actions';
import { Field } from '../../../../models/field';
import { IAppState } from '../../../../../store/model';
import { Url } from '../../../../models/url';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { GedFieldState } from '../../reducer/ged/ged-cfg-field.reducer';
import { GedCfgFieldAction } from '../../action/ged/ged-cfg-field.action';
import { AbstractCfgFieldService } from '../abstract-cfg-field.service';

@Injectable({
  providedIn: 'root'
})
export class GedCfgFieldService extends AbstractCfgFieldService {

  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.GED_CFG_FIELD]) gedCfgFields$: Observable<GedFieldState>;

  constructor(
    http: HttpClient,
    configAction: ConfigurationActions,
    ngRedux: NgRedux<IAppState>,
    toastaService: ToastaService,
    private gedCfgFieldAction: GedCfgFieldAction
  ) {
    super(http, configAction, ngRedux, toastaService);
  }

  protected getApiBaseUrl(): string {
    return Url.getProtectedApiBaseUrl(this.configAction) + Url.FIELDS;
  }

  protected getCfgFieldStore(): Observable<GedFieldState> {
    return this.gedCfgFields$;
  }

  protected cfgFieldActionLoadStarted(): void {
    this.gedCfgFieldAction.loadStarted();
  }
  protected cfgFieldActionLoadSucceeded(val: {[key: string]: Field}): void {
    this.gedCfgFieldAction.loadSucceeded(val);
  }
  protected cfgFieldActionLoadFailed(error: any): void {
    this.gedCfgFieldAction.loadFailed(error);
  }
  protected cfgFieldActionRemoveAllStarted(): void {
    this.gedCfgFieldAction.removeAllStarted();
  }
  protected cfgFieldActionRemoveAllSucceeded(): void {
    this.gedCfgFieldAction.removeAllSucceeded();
  }
  protected getCfgFieldStoreState(): GedFieldState {
    return this.ngRedux.getState().dynamicSubStores.gedCfgField;
  }


  public get(fieldCode: string): Field {
    const fieldsState: GedFieldState = this.getCfgFieldStoreState();
    return this._getStoreFieldByCode(fieldsState, fieldCode);
  }

  /**
   * @deprecated DEPRECATED : use FieldService.removeSuffixFromPath
   * remove suffix .18n, .code, .value, etc.
   * @param path
   */
  public checkFieldPathPattern(path: string): string {
    return GedCfgFieldService.removeSuffixFromPath(path);
  }
}
