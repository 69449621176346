import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NxI18nPipePipe } from './nxI18nPipe.pipe';

@NgModule({
  declarations: [NxI18nPipePipe],
  imports: [
    CommonModule
  ],
  exports: [NxI18nPipePipe]
})
export class NxI18nPipeModule { }
