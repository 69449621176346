import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AbstractSearchService } from '../abstract-search.service';
import { GedCfgFieldService } from '../../../../../core/redux/field/service/ged/ged-cfg-field.service';
import { LocaleService } from '../../../../../core/services/locale/locale.service';

@Injectable({
  providedIn: 'root'
})
export class GedSearchService extends AbstractSearchService {

  constructor(
    translateService: TranslateService,
    cfgFieldService: GedCfgFieldService,
    localeService: LocaleService
  ) {
    super(translateService, cfgFieldService, localeService);
  }
}
