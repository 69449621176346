import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';

import { FlatTreeNode } from '../../models/flat-tree';
import { LoadableThesaurus, LoadableThesaurusItem } from '../../models/loadable-thesaurus';
import { LoadableThesaurusService } from './service/loadable-thesaurus.service';
import { TreeService } from './service/tree.service';
import { RegionItemI18n } from '../../models/region-item-i18n';

export class TreeDataSource implements DataSource<FlatTreeNode> {

  static LOAD_MORE_NODE = '_LOAD_MORE_';

  dataChange = new BehaviorSubject<FlatTreeNode[]>([]);


  get data(): FlatTreeNode[] {
    return this.dataChange.value;
  }

  set data(value: FlatTreeNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private thesaurusRoot: LoadableThesaurus,
    private treeControl: FlatTreeControl<FlatTreeNode>,
    private loadableThesaurusService: LoadableThesaurusService,
    private treeService: TreeService
  ) {
  }

  connect(collectionViewer: CollectionViewer): Observable<FlatTreeNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<FlatTreeNode>).added ||
        (change as SelectionChange<FlatTreeNode>).removed) {
        this.handleTreeControl(change as SelectionChange<FlatTreeNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<FlatTreeNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  private cleanChildrenInLocalModel(node: FlatTreeNode): void {
    const index = this.data.indexOf(node);
    // dispose nodes from memory
    let count = 0;
    for (let i = index + 1; i < this.data.length
    && this.data[i].level > node.level; i++, count++) {
    }
    this.data.splice(index + 1, count);
  }

  private findNode(value: string): FlatTreeNode {
    const localData = this.data;
    for (const node of localData) {
      if (node.value === value) {
        return node;
      }
    }
    return null;
  }

  public openPath(regionPath: RegionItemI18n[], index: number = 0, nodeParrentExpanded = false): Promise<boolean> {
    // length - 1 -> 'cause do not OPEN the SELECTED node

    if (index >= regionPath.length - 1 && !nodeParrentExpanded) {
      return new Promise<boolean>((resolve, reject) => {
        resolve(true);
      });
    }

    const nodeToOpen: FlatTreeNode = this.findNode(regionPath[index].value);
    if (nodeToOpen === null) {
      if (!nodeParrentExpanded) {
        return new Promise<boolean>((resolve, reject) => {
          resolve(false);
        });
      } else {
        const parrentNode: FlatTreeNode = this.findNode(regionPath[index - 1].value);
        if (parrentNode.moreThanVisible) {
          return new Promise<boolean>((resolve, reject) => {
            resolve(this.loadMoreChilds(this.data.find((loadMoreNode: FlatTreeNode) => loadMoreNode.value === TreeDataSource.LOAD_MORE_NODE), regionPath[index].value));
          });
        }
      }
    }

    if (nodeToOpen.expanded) {
      return this.openPath(regionPath, index + 1, true);
    }

    return new Promise((resolve, reject) => {
      const nextNodeValue: string = !!regionPath[index + 1] ? regionPath[index + 1].value : null;

      this.toggleNode(nodeToOpen, true, nextNodeValue).then((resp) => {
        if (!resp) {
          return resolve(false);
        }

        resolve(this.openPath(regionPath, index + 1));

      }).catch((err) => reject(false));

    });

  }


  /**
   * Toggle the node, remove from display list
   */
  public toggleNode(node: FlatTreeNode, expand: boolean, nextNodeToSearchValue: string = null): Promise<boolean> {
    const index = this.data.indexOf(node);

    if (index < 0) {
      return new Promise((resolve, reject) => {
        reject(false);
      });
    }

    if (!expand) {
      // Collapsing a Node

      // dispose nodes from memory
      this.cleanChildrenInLocalModel(node);

      node.expanded = false;

      this.dataChange.next(this.data);

      return new Promise((resolve, reject) => {
        resolve(true);
      });
    } else {
      // Expanding a Node
      node.isLoading = true;

      // clean anyway ...
      this.cleanChildrenInLocalModel(node);

      return new Promise((resolve, reject) => {
        // const item = this._thesaurus.getItem( this.thesaurusRootCode, node.value );
        this.loadableThesaurusService.getItem(this.thesaurusRoot, node.value).then(
          (item) => {
            if (item.hasChildren === false) {
              console.log('toggleNode no children');
              node.isLoading = false;
              return resolve(false);
            }

            const children = item.items;
            const nodes = [];
            for (const key in children) {
              if (children[key] === null) {
                continue;
              }

              const child = children[key];
              nodes[child.localSort] = this.treeService.fromItem(child, node);
            }

            node.expanded = true;
            node.isLoading = false;
            node.moreThanVisible = item.hasMoreChildrenThanVisible;
            let loadMoreNode: FlatTreeNode = null;
            if (node.moreThanVisible) {
              // add a 'load more marker' node
              loadMoreNode = new FlatTreeNode(TreeDataSource.LOAD_MORE_NODE, '', node.level + 1, false, node, false, false, true);
              nodes.push(loadMoreNode);
            }

            this.data.splice(index + 1, 0, ...nodes);
            this.treeControl.expand(node);
            this.dataChange.next(this.data);

            if (!!nextNodeToSearchValue && node.moreThanVisible) {
              const nextNodeToSearchFor: FlatTreeNode = nodes.find((nodeToSearchFor: FlatTreeNode) => nodeToSearchFor.value === nextNodeToSearchValue);
              if (!nextNodeToSearchFor) {
                resolve(this.loadMoreChilds(loadMoreNode, nextNodeToSearchValue));
              } else {
                resolve(true);
              }
            } else {
              resolve(true);
            }

          }
        ).catch(err => {
          reject(false);
        });
      });
    }
  }

  public loadMoreChilds(loadMoreNode: FlatTreeNode, nextValueToCheck = null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const index = this.data.indexOf(loadMoreNode);
      loadMoreNode.parent.isLoading = true;
      this.loadableThesaurusService.getItem(this.thesaurusRoot, loadMoreNode.parent.value, loadMoreNode.page + 1).then((item: LoadableThesaurusItem) => {

        const children = item.items;
        const nodes = [];
        for (const key in children) {
          if (children[key] === null) {
            continue;
          }

          const child = children[key];
          nodes[child.localSort] = this.treeService.fromItem(child, loadMoreNode.parent);
        }

        loadMoreNode.parent.isLoading = false;
        loadMoreNode.parent.moreThanVisible = item.hasMoreChildrenThanVisible;
        if (loadMoreNode.parent.moreThanVisible) {
          // add a 'load more marker' node
          loadMoreNode.page = loadMoreNode.page + 1;
          nodes.push(loadMoreNode);
        }
        this.data.splice(index, 1, ...nodes);
        this.dataChange.next(this.data);

        if (!!nextValueToCheck && !nodes.find((nodeToSearchFor: FlatTreeNode) => nodeToSearchFor.value === nextValueToCheck)) {
          resolve(this.loadMoreChilds(loadMoreNode, nextValueToCheck));
        } else {
          resolve(true);
        }
      });
    });
  }
}
