export class FromJson {

  static fromJson<T>(json: any, ctor: (new () => T)): T {
    const result = new ctor();
    Object.keys(json).forEach((key) => {
      result[key] = json[key];
    });
    return result;
  }
}
