import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../../store/model';
import { SearchItem } from '../../../../models/search-item';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { TaskSearchItemsActionConstant, taskSearchItemsReducer, TaskSearchItemsState } from '../reducer/task-search-items.reducer';

@Injectable({
  providedIn: 'root'
})
export class TaskSearchItemsAction {

  private localStore: ObservableStore<TaskSearchItemsState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.TASK_SEARCH_ITEMS], taskSearchItemsReducer);
  }

  public addSearchItemStarted() {
    this.localStore.dispatch({
      type: TaskSearchItemsActionConstant.ADD_TASK_SEARCH_ITEM_START
    });
  }

  public addSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch({
      type: TaskSearchItemsActionConstant.ADD_TASK_SEARCH_ITEM_SUCCEEDED,
      payload: searchItem
    });
  }

  public addSearchItemFailed(error: any) {
    this.localStore.dispatch({
      type: TaskSearchItemsActionConstant.ADD_TASK_SEARCH_ITEM_FAILED,
      error: error
    });
  }

  public removeSearchItemStarted() {
    this.localStore.dispatch({
      type: TaskSearchItemsActionConstant.REMOVE_TASK_SEARCH_ITEM_START
    });
  }

  public removeSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch({
      type: TaskSearchItemsActionConstant.REMOVE_TASK_SEARCH_ITEM_SUCCEEDED,
      payload: searchItem
    });
  }

  public removeSearchItemFailed(error: any) {
    this.localStore.dispatch({
      type: TaskSearchItemsActionConstant.REMOVE_TASK_SEARCH_ITEM_FAILED,
      error: error
    });
  }

  public removeAllSearchItemsSucceeded() {
    this.localStore.dispatch({
      type: TaskSearchItemsActionConstant.REMOVE_ALL_TASK_SEARCH_ITEMS_SUCCEEDED
    });
  }
}
