<div *ngIf="isDisplayed()">

  <div class="alert-main" (click)="onAbort()">
  </div>

  <div class="alert-content">
    <div class="alert-title">
      {{ error.title | translate }}
    </div>
    <div class="alert-text">
      {{ error.text | translate }}
      <br/>
      <div class="alert-button" (click)="onConfirm( error.url )">
        {{ error.button | translate }}
      </div>
    </div>
  </div>

</div>
