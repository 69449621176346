// imported in each class
import { TranslateService } from '@ngx-translate/core';
import { FieldType, SearchOperator } from './field';
import { EsAuthor } from './EsAuthor';

export enum OperatorType {
  MATCH = 'MATCH',
  TERM = 'TERM',
  RANGE = 'RANGE',
  EXISTS = 'EXISTS'
}

export interface SearchItemLabelByType {
  searchItem: SearchItem;
  userList: EsAuthor[];
  currentLanguage: string;
}

export class SearchItem {

  constructor(
    public id: number,
    public keyword: string,
    public path: string,
    public value: string,
    public canDelete: boolean = true,
    public fieldType: FieldType = null,
    public operator?: SearchOperator,
    public label?: string,
    public oldValue: string = null
  ) {
  }

  public static readonly OPERATOR_SEPARATOR = ':';
  public count?: number;
  // public authorizeSearchByDirectInput?: boolean;
  // public callAutocompletionOnDirectInput?: boolean;

  public static getOperatorType(operator: SearchOperator): OperatorType {
    let type: OperatorType;
    switch (operator) {
      case SearchOperator.gt:
      case SearchOperator.gte:
      case SearchOperator.lt:
      case SearchOperator.lte:
        type = OperatorType.RANGE;
        break;
      case SearchOperator.c:
        type = OperatorType.MATCH;
        break;
      case SearchOperator.e:
      case SearchOperator.ne:
        type = OperatorType.TERM;
        break;
      case SearchOperator.n:
      case SearchOperator.nn:
        type = OperatorType.EXISTS;
        break;
    }
    return type;
  }

  // public setSearchBehavior() {
  //   switch (this.fieldType) {
  //     case FieldType.AUTHOR:
  //       this.authorizeSearchByDirectInput = false;
  //       this.callAutocompletionOnDirectInput = true;
  //       break;
  //     case FieldType.DATE:
  //       this.authorizeSearchByDirectInput = false;
  //       this.callAutocompletionOnDirectInput = false;
  //       break;
  //     case FieldType.DECIMAL:
  //     case FieldType.INTEGER:
  //       this.authorizeSearchByDirectInput = true;
  //       this.callAutocompletionOnDirectInput = false;
  //       break;
  //     default:
  //       this.authorizeSearchByDirectInput = true;
  //       this.callAutocompletionOnDirectInput = true;
  //       break;
  //   }
  // }

  public static isCallAutocompletionOnDirectInput(searchItem: SearchItem): boolean {
    return [FieldType.DECIMAL, FieldType.INTEGER, FieldType.DATE].indexOf(searchItem?.fieldType) === -1;
  }
  public static isAuthorizeSearchByDirectInput(searchItem: SearchItem): boolean {
    return [FieldType.AUTHOR, FieldType.DATE].indexOf(searchItem?.fieldType) === -1;
  }

  public static getSearchItemLabel(searchItemLabelByType: SearchItemLabelByType, translateService: TranslateService): string {
    if (!searchItemLabelByType.searchItem) {
      return '';
    }
    if (!searchItemLabelByType.searchItem.fieldType) {
      return searchItemLabelByType.searchItem.value;
    }
    switch (searchItemLabelByType.searchItem.fieldType) {
      case FieldType.AUTHOR:
        return SearchItem.getUserFirstNameLastName(searchItemLabelByType.searchItem, searchItemLabelByType.userList);
      case FieldType.DATE:
        return SearchItem.getLocaleDate(searchItemLabelByType.searchItem, searchItemLabelByType.currentLanguage);
      case FieldType.BOOLEAN:
        return translateService.instant('SEARCH.'+searchItemLabelByType.searchItem.value);
      case FieldType.STRING: {
        if ('metadata.archive_lock.lock_information.lock_type' === searchItemLabelByType.searchItem.path) {
          return translateService.instant('SEARCH.'+searchItemLabelByType.searchItem.value);
        } else {
          return searchItemLabelByType.searchItem.value;
        }
      }
      default:
        return searchItemLabelByType.searchItem.value;
    }
  }

  private static getUserFirstNameLastName(searchItem: SearchItem, userList: EsAuthor[]): string {
    const searchedUser: EsAuthor = userList.find((user: EsAuthor) => user.id === +searchItem.value);
    return searchedUser ? EsAuthor.getUserLabel(searchedUser) : searchItem.value;
  }

  private static getLocaleDate(searchItem: SearchItem, currentLanguage: string): string {
    return new Date(searchItem.value).toLocaleDateString(currentLanguage);
  }

  public static authorizeInputText(searchItem: SearchItem): boolean {
    switch (searchItem.fieldType) {
      case FieldType.BOOLEAN:
        return false;
      default:
        return true;
    }
  }
}
