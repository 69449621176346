export class XslTransfoResult {
  result: string;
  errorMessageLocalKey: string;
  errorMessageDetail: string;
  sXml: string;
  sXsl: string;
  constructor( result: string, errorMessageLocalKey: string, errorMessageDetail: string, sXml: string, sXsl: string  ) {
    this.result = result;
    this.errorMessageLocalKey = errorMessageLocalKey;
    this.errorMessageDetail = errorMessageDetail;
    this.sXml = sXml;
    this.sXsl = sXsl;
  }
}
