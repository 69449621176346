import { DOCUMENT, Location } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router, UrlTree } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { MsalService } from '@azure/msal-angular';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as jwtDecode from 'jwt-decode';
import * as _ from 'lodash';
import { DomainItem } from '../../core/models/domain-item';

import { AccessFunction } from '../../core/models/access';
import { UserCredentials } from '../../core/models/UserCredentials';
import { AuthenticationAction } from './authentication.action';
import { Url } from '../../core/models/url';
import { IAppState } from '../../store/model';
import { StoreReducers } from '../../store/root.reducer';
import { StoreKeys } from '../../core/models/store-keys';
import { AlertModalService } from '../alert-message/service/alert-modal.service';
import { Utils } from '../../core/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public static readonly DEFAULT_LANGUAGE = 'fr';

  public static readonly APP_AUTHORIZATION_HEADER = 'Authorization';
  public static readonly AUTH_AUTHORIZATION_HEADER = 'X-Refresh-Authorization';
  public static readonly BEARER = 'Bearer ';
  public static readonly BEARER_PREFIX_LENGTH = AuthenticationService.BEARER.length;

  public static readonly SESSION_ST_AUTH_TOKEN = 'auth-token';
  public static readonly SESSION_ST_APP_TOKEN = 'app-token';
  public static readonly ROLE_WKF = 'ROLE_WKF';

  private cachedUserCredential: UserCredentials;


  ROLE_ADMIN = 'ROLE_ADMIN';
  ROLE_SUPPORT = 'ROLE_SUPPORT';


  @select([StoreReducers.CONFIGURATION, StoreKeys.APPLICATION]) application$: Observable<any>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private action: AuthenticationAction,
    private router: Router,
    private httpClient: HttpClient,
    private location: Location,
    public translate: TranslateService,
    private ngRedux: NgRedux<IAppState>,
    private alertService: AlertModalService,
    private msAuthService: MsalService
  ) {
  }

  private tokenDecode(token: string) {
    try {
      return jwtDecode(token);
    } catch (ex) {
      this.alertService.raiseDisconnectError();
      return {};
    }
  }


  /**
   * called by authenticate guard
   * @param url
   */
  public authenticate(url: string): Promise<boolean> {
    const authToken = this.getAuthorizationToken(false);
    if (!this.checkJwtToken(authToken)) {
      // Authentication token expired
      this.clearStorage();
      this.alertService.releaseError();

      this.setRedirectPage(url);
      this.redirectToAuthService(true);
      return Promise.resolve(false);
    }
    const appToken = this.getApplicationToken(false);
    if (!this.checkJwtToken(appToken)) {
      this.setRedirectPage(url);
      return this.validateAuthToken(authToken);
    }
    this.action.loadAuthenticationSucceeded(undefined);
    return Promise.resolve(true);
  }


  private checkJwtToken(token: string): boolean {
    if (!token) {
      return false;
    }
    if (this.tokenDecode(token).exp * 1000 < new Date().getTime()) {
      return false;
    }
    return true;
  }

  /**
   * called by auth call-back (AuthCallbackComponent)
   * @param authToken
   */
  public validateAuthToken(authToken): Promise<boolean>  {
    return new Promise<boolean>((resolve) => {
      try {
        this.authenticateOnApp(authToken).then(res => {
          if (res) {
            this.redirectTo();
            return resolve(true);
          }
          return resolve(false);
        }).catch(err => {
          throw err;
        });
      } catch (ex) {
        this.action.loadAuthenticationFailed(ex);
        return resolve(false);
      }
    });
  }

  private authenticateOnApp(authToken): Promise<boolean> {

    this.action.loadAuthenticationStart();
    return new Promise<boolean>((resolve) => {
      this.setAuthorizationToken(authToken);
      try {
        this.application$
          .pipe(
            filter(application => application !== undefined && application !== null && application.loaded),
            map(application => {
              // tslint:disable-next-line:no-string-literal
              let backName = application['API_BACK_NAME'];
              if (backName) {
                backName += '/';
              }
              // tslint:disable-next-line:no-string-literal
              backName = application['API_BACK_URL'] + '/' + backName + Url.PUBLIC_API + Url.LOGIN + authToken;
              return backName;
            }),
            take(1),
            switchMap(url => this.httpClient.get(url, {observe: 'response'}))
          )
          .subscribe((response: HttpResponse<any>) => {
            this.setCurrentLanguageFromHeaders(response.headers);
            this.action.loadAuthenticationSucceeded(undefined);
            // sessionStorage.setItem(AuthenticationService.SESSION_ST_APP_TOKEN, response.body.token);
            return resolve(true);
          }, (response: HttpErrorResponse) => {
            this.action.loadAuthenticationFailed(response.error);
            this.clearStorage();
            this.redirectToError(response);
            return resolve(false);
          });
      } catch (err) {
        this.action.loadAuthenticationFailed(err);
        this.clearStorage();
        this.redirectToError(err);
        return resolve(false);
      }
    });
  }

  private setCurrentLanguageFromHeaders(headers: HttpHeaders): void {
    let headersLanguage: string = headers.get('content-language');
    if (!headersLanguage) {
      headersLanguage = AuthenticationService.DEFAULT_LANGUAGE;
    } else {
      headersLanguage = headersLanguage.substring(headersLanguage.indexOf('-'));
    }
    this.translate.setDefaultLang(AuthenticationService.DEFAULT_LANGUAGE);
    this.translate.use(headersLanguage);
  }

  private redirectToAuthService(login: boolean) {
    this.application$
      .pipe(
        filter(application => application !== undefined && application !== null && application.loaded),
        take(1)
      ).subscribe(conf => {
      let url;
      if (login) {
        let redirectUri = conf['API_REDIRECT_URI'];
        if (!redirectUri) {
          redirectUri = this.document.location.origin + this.location.prepareExternalUrl('/login');
        }
        url = conf['API_LOGIN_URL'] + 'redirect_uri=' + redirectUri;
      } else {
        url = conf['API_LOGOUT_URL'] + 'post_logout_redirect_uri=' + this.document.location.origin + this.location.prepareExternalUrl('/logout');
      }
      this.document.location.href = url;
    });
  }

  private redirectTo() {
    const redirectTo = this.getRedirectPage();
    if (redirectTo) {
      this.setRedirectPage();
      if (redirectTo.includes('?')) {
        const root: string = redirectTo.substring(0, redirectTo.indexOf('?'));
        const parsedUrl: UrlTree = this.router.parseUrl(redirectTo);
        this.router.navigate([root], {queryParams: parsedUrl.queryParams});
      } else {
        this.router.navigate([redirectTo]);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  private redirectToError(error) {
    let err = error;
    if (error instanceof HttpErrorResponse) {
      if (error.error.detail) {
        err = `${error.error.status}: ${error.error.title}, ${error.error.detail}`;
      } else {
        err = error.error.message;
      }
    }
    this.router.navigate(['error'], {queryParams: {error: err}});
  }

  /***************  *****************/
  /*** ACCESSEURS sessionstorage ***/
  /**************  *****************/

  public clearStorage(): void {
    this.ngRedux.dispatch({type: 'RESET'});
    sessionStorage.clear();
  }

  public setRedirectPage(url: string = null): void {
    if (!url) {
      sessionStorage.removeItem('redirectPage');
    } else {
      sessionStorage.setItem('redirectPage', url);
    }
  }

  public getRedirectPage(): string {
    return sessionStorage.getItem('redirectPage');
  }

  public getAuthorizationToken(prefix = true): string {
    const token = sessionStorage.getItem(AuthenticationService.SESSION_ST_AUTH_TOKEN);
    if (prefix) {
      return AuthenticationService.BEARER + token;
    } else {
      return token;
    }
  }

  public setAuthorizationToken(authToken: string = null): void {
    if (!authToken) {
      sessionStorage.removeItem(AuthenticationService.SESSION_ST_AUTH_TOKEN);
    } else {
      sessionStorage.setItem(AuthenticationService.SESSION_ST_AUTH_TOKEN, authToken);
    }
  }

  public getApplicationToken(prefix = true): string {
    const token = sessionStorage.getItem(AuthenticationService.SESSION_ST_APP_TOKEN);
    if (prefix) {
      return AuthenticationService.BEARER + token;
    } else {
      return token;
    }
  }

  public setApplicationToken(appToken: string = null): void {
    if (sessionStorage.getItem(AuthenticationService.SESSION_ST_APP_TOKEN) === appToken) {
      return;
    }
    this.cachedUserCredential = null; // modification du token -> clear du userCredential mis en cache
    if (!appToken) {
      sessionStorage.removeItem(AuthenticationService.SESSION_ST_APP_TOKEN);
    } else {
      sessionStorage.setItem(AuthenticationService.SESSION_ST_APP_TOKEN, appToken);
    }
  }

  public logout() {
    this.clearStorage();
    this.action.logoutSucceeded();
    this.redirectToAuthService(false);
  }

  // unused
  public logoutAzure(): void {
    const rootUrl = window.location.origin;
    this.msAuthService.logoutRedirect({
      postLogoutRedirectUri: rootUrl
    });
  }

  public getUserCredentials(): UserCredentials {
    if (!this.cachedUserCredential) {
      const token = this.getApplicationToken(false);
      if (!token) {
        console.error('no app token to decode');
        return;
      }
      const loggedInUser: any = this.tokenDecode(token);
      this.cachedUserCredential = new UserCredentials(loggedInUser.id, loggedInUser.firstname, loggedInUser.lastname, loggedInUser.sub, loggedInUser.roles);
    }
    return _.cloneDeep(this.cachedUserCredential);
  }

  public getObjectAccessFunctions(token: string): AccessFunction[] {
    if (!Utils.notNullAndNotUndefined(token)) {
      return [];
    }
    return this.tokenDecode(token).functions;
  }

  public hasObjectAccessFunction(token: string, accessFunction: AccessFunction): boolean {
    return this.getObjectAccessFunctions(token).indexOf(accessFunction) > -1;
  }


  /************************/
  /*** TOKEN APPLICATIF ***/
  /***********************/

  private getGlobalAccessFunctions(): { [key: string]: string[] } {
    return this.tokenDecode(this.getApplicationToken()).functions;
  }

  private getGlobalAuthoritiesFunctions(): { [key: string]: string[] } {
    return this.tokenDecode(this.getApplicationToken()).authorities;
  }

  private getGlobalRolesFunctions(): string[] {
    return this.tokenDecode(this.getApplicationToken()).roles;
  }

  private checkForGlobalAccessByFunction(access: AccessFunction, domainItem: DomainItem = null): boolean {
    const globalAccessFunctions = this.getGlobalAccessFunctions();
    if (!!globalAccessFunctions && !!globalAccessFunctions[access]) {
      if (!domainItem) {
        return true;
      } else {
        return globalAccessFunctions[access].some((domain: string) => domain === domainItem.code);
      }
    }
    return false;
  }

  public checkForGlobalAccessByRole(role: string): boolean {
    const globalRolesFunctions = this.getGlobalRolesFunctions();
    if (!!globalRolesFunctions && globalRolesFunctions.indexOf(role) !== -1) {
      return true;
    }
    return false;
  }

  public checkForRoleAdmin(): boolean {
    return this.checkForGlobalAccessByRole(this.ROLE_ADMIN);
  }

  public checkForIndex(): boolean {
    return this.checkForGlobalAccessByFunction(AccessFunction.INDEX);
  }

  public checkForIndexEntity(entityCode: string): boolean {
    const domainItems: DomainItem[] = this.ngRedux.getState().dynamicSubStores.domains.datas;
    const currentDomain: DomainItem = domainItems.find((domainItem: DomainItem) => domainItem.entityType === entityCode);
    return this.checkForIndexInDomain(currentDomain);
  }

  public checkForIndexInDomain(domain: DomainItem): boolean {
    return !!domain ? this.checkForGlobalAccessByFunction(AccessFunction.INDEX, domain) : false;
  }

  // INDEXATION TEAM access

  public checkForIndexTeamManagement(): boolean {
    return this.checkIfHasTeamAccessByAccessFunction([AccessFunction.INDEX_TEAM, AccessFunction.INDEX_TEAM_ALL, AccessFunction.INDEX_TEAM_ADMIN]);
  }
  public checkForIndexTeamCreation(): boolean {
    return this.checkIfHasTeamAccessByAccessFunction([AccessFunction.INDEX_TEAM_ALL, AccessFunction.INDEX_TEAM_ADMIN]);
  }


  // DOMAIN TEAM access

  public checkForDomainTeamManagement(currentDomainCode: string): boolean {
    if (!currentDomainCode) {
      return false;
    }
    return this.checkIfHasTeamAccessByAccessFunction([AccessFunction.TEAM, AccessFunction.TEAM_ALL, AccessFunction.TEAM_ADMIN], currentDomainCode);
  }
  public checkForDomainTeamCreation(currentDomainCode: string): boolean {
    if (!currentDomainCode) {
      return false;
    }
    return this.checkIfHasTeamAccessByAccessFunction([AccessFunction.TEAM_ALL, AccessFunction.TEAM_ADMIN], currentDomainCode);
  }

  private checkIfHasTeamAccessByAccessFunction(accesses: AccessFunction[], currentDomainCode: string = null): boolean {
    let hasAccess = false;
    const globalAccessFunctions = this.getGlobalAuthoritiesFunctions();
    accesses.forEach((access: AccessFunction) => {
      if (hasAccess) {
        return;
      }
      if (!currentDomainCode) {
        hasAccess = !!globalAccessFunctions[access];
      } else {
        hasAccess = !!globalAccessFunctions[access] && globalAccessFunctions[access].some((domainCode: string) => domainCode === currentDomainCode);
      }
    });
    return hasAccess;
  }
}
