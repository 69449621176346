import { ClickOutsideModule } from 'ng-click-outside';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TooltipModule } from '../tooltip/tooltip.module';
import { NxI18nPipeModule } from '../nxI18nPipe/nxI18nPipe.module';
import { ButtonComponent } from './button/button.component';
import { CloseButtonComponent } from './close-button/close-button.component';
import { PrivacyButtonComponent } from './privacy-button/privacy-button.component';
import { ShowHideWindowComponent } from './show-hide/show-hide-window.component';
import { VersionButtonComponent } from './version-button/version-button.component';
import { ObjectLinkButtonComponent } from './object-link-button/object-link-button.component';
import { VersionRestoreButtonComponent } from './version-restore-button/version-restore-button.component';
import { NexiaBasketButtonComponent } from './nexia-basket-button/nexia-basket-button.component';
import { NexiaBasketObjectsActionsButtonComponent } from './nexia-basket-objects-actions-button/nexia-basket-objects-actions-button.component';


@NgModule({
  declarations: [
    ButtonComponent,
    CloseButtonComponent,
    PrivacyButtonComponent,
    ShowHideWindowComponent,
    VersionButtonComponent,
    ObjectLinkButtonComponent,
    VersionRestoreButtonComponent,
    NexiaBasketButtonComponent,
    NexiaBasketObjectsActionsButtonComponent
  ],
    imports: [
        // we added entityModule here in order to be seen by dataModule/linkBlock
        CommonModule,
        TooltipModule,
        TranslateModule,
        NxI18nPipeModule,
        RouterModule.forChild( [] ),
        ClickOutsideModule,
        MatIconModule,
        MatMenuModule
    ],
  // providers: [
  //   {provide: MAT_DATE_LOCALE, useValue: 'fr'},
  //   {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
  //   {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  // ],
  exports: [
    ButtonComponent,
    CloseButtonComponent,
    PrivacyButtonComponent,
    ShowHideWindowComponent,
    VersionButtonComponent,
    ObjectLinkButtonComponent,
    VersionRestoreButtonComponent,
    NexiaBasketButtonComponent,
    NexiaBasketObjectsActionsButtonComponent
  ]
})
export class ButtonModule { }
