import { Observable, SubscriptionLike } from 'rxjs';
import { select } from '@angular-redux/store';
import { filter } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TitleService } from './core/services/title/title.service';
import { ScreenWidthService } from './core/services/responsive/screen-width.service';
import { CurrentContextState } from './core/redux/current-context/reducer/current-context.reducer';
import { CurrentModule } from './core/models/current-module';
import { StoreReducers } from './store/root.reducer';
import { StoreKeys } from './core/models/store-keys';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CURRENT_CONTEXT]) currentContext$: Observable<CurrentContextState>;

  @HostBinding('class.mobile') mobile = false;
  @HostBinding('class.desktop') desktop = false;

  title = 'Nexia';
  public screenWidth: number;
  private subs: SubscriptionLike[] = [];
  constructor(
    @Inject(DOCUMENT) public document,
    private titleService: TitleService,
    private screenWidthService: ScreenWidthService,
    public translate: TranslateService
  ) {
    // translate.setDefaultLang('fr');
    // translate.use('fr');
  }
  ngOnInit() {
    // sets the Browser Page title
    this.subs.push(this.titleService.title.subscribe((title) => {
      this.document.title = title;
    }));

    this.subs.push(this.screenWidthService.screenWidth$.subscribe((screenWidth) => {
      let desktop = false;
      let mobile = false;
      this.screenWidth = screenWidth;
      if (this.screenWidth > 1023) {
        desktop = true;
      } else {
        mobile = true;
      }
      this.desktop = desktop;
      this.mobile = mobile;
    }));
    // this.subs.push(this.translate.get('GLOBAL.app_title').subscribe((response: string) => {
    //   this.title = response;
    //   this.titleService.setTitle(this.title);
    // }));

    this.subs.push(this.translate.onLangChange.pipe(
      filter((langChangeEvent: LangChangeEvent) => !!langChangeEvent.lang && !!langChangeEvent.translations)
    ).subscribe((langChangeEvent: LangChangeEvent) => {
      this.title = this.translate.instant('GLOBAL.app_title');
      this.titleService.setTitle(this.title);
    }));

    this.subs.push(this.currentContext$.pipe(
      filter((currentContext: CurrentContextState) => !!currentContext && !!currentContext.datas && !!currentContext.datas.currentModule && !currentContext.loading),
    ).subscribe((currentContextState: CurrentContextState) => {
      let color = '';
      let rgb_accent_color = '';
      switch (currentContextState.datas.currentModule) {
        case CurrentModule.GED:
          color = '#ff6255';
          rgb_accent_color = '255, 98, 85';
          break;
        case CurrentModule.WORKFLOW:
          color = '#35bab8';
          rgb_accent_color = '53, 186, 184';
          break;
      }
      document.documentElement.style.setProperty('--accent_color', color);
      document.documentElement.style.setProperty('--rgb_accent_color', rgb_accent_color);
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: SubscriptionLike) => sub.unsubscribe());
  }

}
