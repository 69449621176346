import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastaModule, ToastaService } from 'ngx-toasta';
import { ToasterComponent } from './components/toaster.component';
import {ErrorMessageFormater} from './service/errorMessageFormater';

@NgModule({
  imports: [
    CommonModule,
    ToastaModule.forRoot(),
  ],
  declarations: [ToasterComponent],
  providers: [ToastaService, ErrorMessageFormater],
  exports: [ToasterComponent]
})
export class ToasterModule {

}
