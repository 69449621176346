import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ButtonMenuComponent } from './components/button-menu/button-menu.component';
import { CapitalizeFirstLetterModule } from '../capitalize-firstLetter/capitalize-firstLetter.module';

@NgModule({
  declarations: [
    MenuComponent,
    MenuItemComponent,
    ButtonMenuComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    ClickOutsideModule,
    TranslateModule,
    CapitalizeFirstLetterModule
  ],
  exports : [
    MenuComponent,
    MenuItemComponent,
    ButtonMenuComponent
  ]
})
export class MenuModule { }
