import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgxNearbyModule } from 'ngx-nearby';
import { TranslateModule } from '@ngx-translate/core';
import { EntityModule } from '../entity/entity.module';
import { CardModule } from '../card/card.module';
import { NexiaObjectsNavButtonComponent } from './nexia-objects-nav-button/nexia-objects-nav-button.component';
import { NexiaObjectsCarouselComponent } from './nexia-objects-carousel/nexia-objects-carousel.component';
import { NexiaObjectsNavigationComponent } from './nexia-objects-navigation/nexia-objects-navigation.component';

@NgModule({
  declarations: [
    NexiaObjectsNavButtonComponent,
    NexiaObjectsCarouselComponent,
    NexiaObjectsNavigationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    EntityModule,
    NgxNearbyModule,
    RouterModule.forChild([]),
    CardModule
  ],
  exports: [
    NexiaObjectsNavButtonComponent,
    NexiaObjectsCarouselComponent,
    NexiaObjectsNavigationComponent
  ]
})
export class NavigationModule { }
