import { Reducer } from 'redux';

const INITIAL_STATE = {};

export const configurationReducer: Reducer<any> = (state= INITIAL_STATE, action) => {
  const configFile = action.configFile;
  const newState = {...state};
  switch (action.type) {
    case 'LOAD_CONFIG_START':
      newState[configFile] = {
        loading: true,
        loaded: false,
        error: null
      };
      return newState;
    case 'LOAD_CONFIG_SUCCEEDED':
      newState[configFile] = {
        ...action.payload,
        loading: false,
        loaded: true,
        error: null
      };
      return newState;
    case 'LOAD_CONFIG_FAILED':
      newState[configFile] = {
          ...state.configFile,
          loading: false,
          loaded: true,
          error: action.payload
        };
      return newState;
  }
  return state;
};
