import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../store/model';
import { PayloadAction } from '../../../../store/payload-action';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';
import { CriticalData, CriticalDataActionConstant, criticalDataReducer, CriticalDataState } from '../reducer/critical-data.reducer';

@Injectable({
  providedIn: 'root'
})
export class CriticalDataAction {
  public static readonly _ALL = '_all';
  private localStore: ObservableStore<CriticalDataState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CRITICAL_DATA], criticalDataReducer);
  }

  public loadCriticalStart(): void {
    this.localStore.dispatch(<PayloadAction<CriticalData>> {
      type: CriticalDataActionConstant.LOAD_CRITICAL_STARTED,
    });
  }

  public addCriticalFieldToStore(criticalFieldCode: string): void {
    const payload: CriticalData = new CriticalData();
    payload.visibleCriticalFields = [criticalFieldCode];
    this.localStore.dispatch(<PayloadAction<CriticalData>> {
      type: CriticalDataActionConstant.LOAD_CRITICAL_DATA_SUCCEEDED,
      payload: payload
    });
  }

  public addViewCriticalFieldsToStore(viewCriticalFieldsCode: string[]): void {
    const payload: CriticalData = new CriticalData();
    payload.viewCriticalFields = viewCriticalFieldsCode;
    this.localStore.dispatch(<PayloadAction<CriticalData>> {
      type: CriticalDataActionConstant.LOAD_VIEW_CRITICAL_DATA_SUCCEEDED,
      payload: payload
    });
  }

  public addAllToVisibleCriticalFieldsStore(criticalFields: string[]): void {
    const payload: CriticalData = new CriticalData();
    payload.visibleCriticalFields = criticalFields;
    this.localStore.dispatch(<PayloadAction<CriticalData>> {
      type: CriticalDataActionConstant.LOAD_CRITICAL_DATA_ALL_SUCCEEDED,
      payload: payload
    });
  }

  public addVisibleCriticalPdfToStore(isVisible: boolean): void {
    const payload: CriticalData = new CriticalData();
    payload.visibleCriticalPdf = isVisible;
    this.localStore.dispatch(<PayloadAction<CriticalData>> {
      type: CriticalDataActionConstant.LOAD_CRITICAL_PDF_SUCCEEDED,
      payload: payload
    });
  }

  public removeAllFromVisibleCriticalFieldsStore(): void {
    this.localStore.dispatch(<PayloadAction<CriticalData>> {
      type: CriticalDataActionConstant.REMOVE_CRITICAL_DATA_SUCCEEDED
    });
  }

  public removeViewCriticalFieldsStore(): void {
    this.localStore.dispatch(<PayloadAction<CriticalData>> {
      type: CriticalDataActionConstant.REMOVE_VIEW_CRITICAL_DATA_SUCCEEDED
    });
  }

  public unsetVisibleCriticalFieldsStore(): void {
    this.localStore.dispatch(<PayloadAction<CriticalData>> {
      type: CriticalDataActionConstant.SET_CRITICAL_DATA_TO_NULL_SUCCEEDED
    });
  }
}
