import { Reducer } from 'redux';

import { PayloadAction } from '../../../../store/payload-action';

export class TableFilterState {
  datas: {[key: string]: any};
  loading: boolean;
  error: any;
}

const TABLE_FILTER_INITIAL_STATE: TableFilterState = {
  datas: null,
  loading: false,
  error: null
};

export class TableFilterConstant {
  static readonly ERROR_START = 'ERROR_START';

  static readonly LOAD_TABLE_FILTER_START = '[TableFilter].LOAD_TABLE_FILTER_START';
  static readonly LOAD_TABLE_FILTER_SUCCEEDED = '[TableFilter].LOAD_TABLE_FILTER_SUCCEEDED';
  static readonly LOAD_TABLE_FILTER_FAILED = '[TableFilter].LOAD_TABLE_FILTER_FAILED';
  static readonly REMOVE_TABLE_FILTER = '[TableFilter].REMOVE_TABLE_FILTER';
  static readonly REMOVE_TABLE_FILTER_BY_KEY = '[TableFilter].REMOVE_TABLE_FILTER_BY_KEY';

}

export const tableFilterReducer: Reducer<TableFilterState> = (
  state: TableFilterState = TABLE_FILTER_INITIAL_STATE, action: PayloadAction<{[key: string]: any} | string>
) => {
  switch (action.type) {
    case TableFilterConstant.LOAD_TABLE_FILTER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TableFilterConstant.LOAD_TABLE_FILTER_SUCCEEDED:
      return {
        ...state,
        datas: action.payload as {[key: string]: any},
        loading: false,
        error: null,
      };
    case TableFilterConstant.LOAD_TABLE_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TableFilterConstant.REMOVE_TABLE_FILTER_BY_KEY:
      const oldFilterStore: {[key: string]: any} = {...state.datas};
      let addNewFilterStore = false;
      let newFilterStore: {[key: string]: any} = null;
      if (!!oldFilterStore && Object.keys(oldFilterStore).length && !!oldFilterStore[action.payload as string]) {
        Object.keys(oldFilterStore).forEach((oldFilterStoreKey: string) => {
          if (oldFilterStoreKey !== action.payload) {
            if (!newFilterStore) {
              newFilterStore = {};
            }
            newFilterStore[oldFilterStoreKey] = oldFilterStore[oldFilterStoreKey];
          }
        });
        addNewFilterStore = true;
      }
      return {
        ...state,
        datas: addNewFilterStore ? newFilterStore : oldFilterStore,
        loading: false,
        error: null,
      };
      case TableFilterConstant.REMOVE_TABLE_FILTER:
        return {
          ...state,
          datas: null,
          loading: false,
          error: null,
        };
  }
  return state;
};
