
export class StringUtils {


  public static capitalizeFirst(chaine: string): string {
    if (chaine != null) {
      return chaine.charAt(0).toUpperCase() + chaine.slice(1);
    }
    return null;
  }
}
