import { FromJson } from './fromJson';

export enum BlockType {
  FIELD = 'FIELD',
  LINK = 'LINK',
  OPTION = 'OPTION'
}

export enum BlockDataVisibility {
  READ = 'READ',
  WRITE = 'WRITE',
  HIDDEN = 'HIDDEN'
}

export class Block extends FromJson {
  code: string;
  datas: any[];
  type: BlockType;
  min: number;
  max: number;
  view_id: string;
  displayRegion: boolean;
}
