import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select } from '@angular-redux/store';
import { CfgDocumentInfoAction } from '../action/cfg-document-info.action';
import { Url } from '../../../models/url';
import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class CfgDocumentInfoService {

  @select([StoreReducers.CONFIGURATION, StoreKeys.APPLICATION]) application$: Observable<any>;

  constructor(
    private cfgDocumentInfoAction: CfgDocumentInfoAction,
    private http: HttpClient,
    private configAction: ConfigurationActions
  ) {}

  getCfgDocumentInfo(doctype: string) {
    this.cfgDocumentInfoAction.loadDocumentInfoStart();
      try {
        this.http.get(Url.getProtectedApiBaseUrl(this.configAction) + Url.DOMAINS + Url.INFOS + `${doctype}`,
        { observe: 'response' })
        .subscribe((response: HttpResponse<any>) => {
          const arr: string[] = response.body[doctype];
          const docInfo = {};
          docInfo[doctype] = arr;
          this.cfgDocumentInfoAction.loadDocumentInfoSucceeded(docInfo);
        }, (response: HttpErrorResponse) => {
          this.cfgDocumentInfoAction.loadDocumentInfoFailed(response.error);
        });
      } catch (error) {
        this.cfgDocumentInfoAction.loadDocumentInfoFailed(error);

      }
  }

  removeAllCfgDocumentInfo() {
    this.cfgDocumentInfoAction.removeAllDocumentInfoSucceeded();
  }
}
