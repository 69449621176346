import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { SearchResultPaginationAction } from '../action/search-result-pagination.action';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';
import { SearchResultPagination } from '../../../models/search-result-pagination';
import { ObjectType } from '../../../models/ObjectType';

@Injectable({
  providedIn: 'root'
})
export class SearchResultPaginationService {

  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.SEARCH_RESULT_PAGINATION, 'datas']) searchResultPaginationStore$: Observable< {-readonly [searchTypeKey in ObjectType]?: SearchResultPagination} >;
  private searchResultPaginationStore: {-readonly [searchTypeKey in ObjectType]?: SearchResultPagination};


  constructor(
    private searchResultActionPagination: SearchResultPaginationAction
  ) {
      this.searchResultPaginationStore$.subscribe((searchResultStorePagination) => {
        this.searchResultPaginationStore = searchResultStorePagination;
      });
  }

  public reinitSearchResultPagination(): void {
    this.searchResultActionPagination.reinitSearchResultPagination(SearchResultPagination.getInitStore());
  }

  public update(copystore: { -readonly [searchTypeKey in ObjectType]?: SearchResultPagination }) {
    this.searchResultActionPagination.updateSearchResultPagination(copystore);
  }

}
