export class InternalRoutes {
  // public urls
  static readonly DETAILS = 'details';
  static readonly COMMENTS = 'comments';
  static readonly ATTACHMENTS = 'attachments';
  static readonly HISTORY = 'history';
  static readonly EDITION = 'edition';
  static readonly LAST_ADDED_DOCUMENTS = 'last-added-documents';
  static readonly DEBUG = 'debug';
  static readonly NEW = 'new';
  static readonly FILE = 'file';
  static readonly RESULT = 'result';
  static readonly ERROR = 'error';
  static readonly LOGIN = 'login';
  static readonly SEARCH = 'search';
  static readonly ENTITIES = 'entities';
  static readonly INDEXATION = 'indexation';
  static readonly DOMAIN = 'domain';
  static readonly DASHBOARD = 'dashboard';
  static readonly DOCUMENTS = 'documents';
  static readonly DOCUMENT = 'document';
  static readonly MULTIPLE = 'multiple';
  static readonly TEAMS = 'teams';
  static readonly RECAP = 'recap';
  static readonly SUPPORT = 'support';
  static readonly INFOS = 'information';
  static readonly USERS = 'users';
  static readonly WORKFLOW = 'workflow';
  static readonly TASKS = 'tasks';
  static readonly BASKET = 'basket';

  static readonly URL_ONE = 'One';
  static readonly URL_TWO = 'Two';
  static readonly URL_THREE = 'Three';
  static readonly URL_FOUR = 'Four';
  static readonly URL_FIVE = 'Five';
  static readonly URL_SIX = 'Six';
  static readonly URL_SEVEN = 'Seven';
  static readonly URL_EIGHT = 'Eight';

}
