import { Injectable } from '@angular/core';

const ERROR_MARKER = 'cur_error';

@Injectable()
export class AlertModalService {

  raiseDisconnectError(): void {
    const url = window.location.origin + '/logout';
    this.raiseError('ERRORS.connection.disconnect.title', 'ERRORS.connection.disconnect.text', 'ERRORS.connection.disconnect.btn', url);
  }

  raiseError(title, text, buttonText, urlIfClick): void {
    sessionStorage.setItem(ERROR_MARKER, JSON.stringify({title, text, button: buttonText, url: urlIfClick}));
  }

  releaseError() {
    sessionStorage.removeItem(ERROR_MARKER);
  }

  hasError(): boolean {
    return sessionStorage.getItem(ERROR_MARKER) !== null;
  }

  getError(): any {
    if ( !this.hasError() ) { return null; }
    try {
      return JSON.parse(sessionStorage.getItem(ERROR_MARKER));
    } catch (ex) {
      return {
        title: 'Unrecoverable error',
        text: 'Error content was lost',
        button: 'GO !',
        url: '/'
      };
    }
  }

}
