import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter, flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UploadFilesData } from '../../../models/UploadFilesData';
import { HttpErrorInterceptor } from '../../../../modules/http-error-interceptor/http-error-interceptor';

export enum QueProgress {
  QUE = 'QUE',
  IN_PROGRESS = 'IN_PROGRESS'
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public static readonly MAX_REQUESTS = 3;

  private waitFile$ = new BehaviorSubject<Map<string, QueProgress>>(null);
  private waitFile: Map<string, QueProgress> = new Map<string, QueProgress>();

  constructor(
    private httpClient: HttpClient
  ) { }

  public uploadFile(file: File, randomFileName: string, url: string, byPassInterceptError = true): Observable<UploadFilesData> {
    const progressData$ = this.waitFile$.pipe(
      map((value: Map<string, QueProgress>) => {
        let i = 0;
        value.forEach((v: QueProgress) => {
          if (v === QueProgress.IN_PROGRESS) {
            i++;
          }
        });
        if (i + 1 <= UploadService.MAX_REQUESTS) {
          return value;
        } else {
          return new Map();
        }
      }),
      filter(value => value.has(randomFileName) && value.get(randomFileName) === QueProgress.QUE),
      flatMap(value => {
        this.waitFile.set(randomFileName, QueProgress.IN_PROGRESS);
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('transactionId', randomFileName);

        let headers: HttpHeaders = new HttpHeaders();
        if (byPassInterceptError) {
          headers = headers.set(HttpErrorInterceptor.BYPASS_HEADER, '');
        }

        return this.httpClient.post(url, formData, {reportProgress: true, observe: 'events', headers: headers})
        .pipe(
          map((event: HttpEvent<any>) => {
            if (event.type === HttpEventType.Response) {
              this.waitFile.delete(randomFileName);
              this.waitFile$.next(this.waitFile);
              }
              return this.getUploadProgress(event, file, randomFileName);
            })
          );
      }));
      this.waitFile.set(randomFileName, QueProgress.QUE);
      this.waitFile$.next(this.waitFile);
      return progressData$;
  }

  private getUploadProgress(event: HttpEvent<any>, file: File, randomFileName: string): UploadFilesData {
    const response =  new UploadFilesData(randomFileName, 0);
    switch (event.type) {
      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        response.loadedSize = percentDone;
        console.log(`File "${randomFileName}" is ${percentDone}% uploaded.`);
        return response;
      case HttpEventType.Response:
        response.object = event.body;
        response.loadedSize = 100;
        console.log(`File "${randomFileName}" was completely uploaded!`);
        return response;
    }
  }
}
