export const defaultOptions = {
'placement': 'top',
'delay': 0,
'z-index': 0,
'display': true,
'display-mobile': false,
'shadow': true,
'offset': 8,
'tooltipTitle': '',
'twoRows': false,
'id': false
};
