import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../store/model';
import { MaintenanceInfoActionConstant, maintenanceInfoReducer, MaintenanceInfoState } from '../reducer/maintenance-info.reducer';
import { MaintenanceInfo } from '../../../models/maintenance-info';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceInfoAction {
  private localStore: ObservableStore<MaintenanceInfoState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.MAINTENANCE_INFO], maintenanceInfoReducer);
  }

  public loadMaintenanceInfoStarted(): void {
    this.localStore.dispatch({
      type: MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_START
    });
  }

  public loadMaintenanceInfoSucceeded(maintenanceInfo: MaintenanceInfo): void {
    this.localStore.dispatch({
      type: MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_SUCCEEDED,
      payload: maintenanceInfo
    });
  }

  public loadMaintenanceInfoFailed(error: any) {
    this.localStore.dispatch({
      type: MaintenanceInfoActionConstant.LOAD_MAINTENANCE_INFO_FAILED,
      error: error
    });
  }

  public removeMaintenanceInfoSucceeded() {
    this.localStore.dispatch({
      type: MaintenanceInfoActionConstant.REMOVE_MAINTENANCE_INFO_SUCCEEDED
    });
  }
}
