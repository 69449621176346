import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../../store/model';
import { SearchItemsActionConstant, searchItemsReducer, SearchItemsState } from '../reducer/search-items.reducer';
import { SearchItem } from '../../../../models/search-item';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class SearchItemsAction {

  private localStore: ObservableStore<SearchItemsState>;

  constructor(
    private ngRedux: NgRedux<IAppState>,
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.SEARCH_ITEMS], searchItemsReducer);
  }

  public addSearchItemStarted() {
    this.localStore.dispatch({
      type: SearchItemsActionConstant.ADD_SEARCH_ITEM_START
    });
  }

  public addSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch({
      type: SearchItemsActionConstant.ADD_SEARCH_ITEM_SUCCEEDED,
      payload: searchItem
    });
  }

  public addSearchItemFailed(error: any) {
    this.localStore.dispatch({
      type: SearchItemsActionConstant.ADD_SEARCH_ITEM_FAILED,
      error: error
    });
  }

  public removeSearchItemStarted() {
    this.localStore.dispatch({
      type: SearchItemsActionConstant.REMOVE_SEARCH_ITEM_START
    });
  }

  public removeSearchItemSucceeded(searchItem: SearchItem) {
    this.localStore.dispatch({
      type: SearchItemsActionConstant.REMOVE_SEARCH_ITEM_SUCCEEDED,
      payload: searchItem
    });
  }

  public removeSearchItemFailed(error: any) {
    this.localStore.dispatch({
      type: SearchItemsActionConstant.REMOVE_SEARCH_ITEM_FAILED,
      error: error
    });
  }

  public removeAllSearchItemsSucceeded() {
    this.localStore.dispatch({
      type: SearchItemsActionConstant.REMOVE_ALL_SEARCH_ITEMS_SUCCEEDED
    });
  }
}
