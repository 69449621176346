import {HttpParameterCodec} from '@angular/common/http';

/* https://stackoverflow.com/questions/51942703/character-converting-into-space-in-httpparams-angular-6
   encode '+' in HttpParams
*/

export class CustomURLEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }
    encodeValue(key: string): string {
        return encodeURIComponent(key);
    }
    decodeKey(key: string): string {
        return decodeURIComponent(key);
     }
    decodeValue(key: string) {
        return decodeURIComponent(key);
     }
}
