import { Routes } from '@angular/router';

import { GlobalConfigGuard, AuthGard } from './app.guards';
import { ErrorComponent } from './core/ui/error/error.component';
import { AuthCallbackComponent } from './modules/authentication/auth-callback/auth-callback.component';
import { InternalRoutes } from './core/models/internal-routes';
// TODO create resolver for resultPage

export const appRoutes: Routes = [
  // routes without authentication, before other
  {
    path: InternalRoutes.ERROR,
    component: ErrorComponent,
  },
  {
    path: InternalRoutes.LOGIN,
    component: AuthCallbackComponent
  },
  // routes with authentication
  {
    path: '',
    // canActivate: [GlobalConfigGuard, AuthGard],
    canActivateChild: [GlobalConfigGuard, AuthGard],
    children: [
      {
        path: InternalRoutes.SEARCH,
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
      },
      {
        path: InternalRoutes.ENTITIES,
        children: [
          {
            path: '', redirectTo: '/' + InternalRoutes.SEARCH, pathMatch: 'full' // no entity type -> home page
          },
          {
            path: ':entityType', redirectTo: ':entityType/' + InternalRoutes.SEARCH, pathMatch: 'full' // default page for an entity type -> search page
          },
          {
            path: ':entityType/' + InternalRoutes.SEARCH,
            loadChildren: () => import('./modules/entity-search/entity-search.module').then(m => m.EntitySearchModule)
          },
          {
            path: ':entityType',
            loadChildren: () => import('./modules/entity/entity-details/entity-details.module').then(m => m.EntityDetailsModule)
          }
        ]
      },
      {
        path: InternalRoutes.WORKFLOW,
        loadChildren: () => import('./modules/workflow/workflow.module').then(m => m.WorkflowModule)
      },
      {
        path: InternalRoutes.INDEXATION,
        loadChildren: () => import('./modules/indexation/indexation.module').then(m => m.IndexationModule)
      },
      {
        path: InternalRoutes.DOMAIN,
        loadChildren: () => import('./modules/domain/domain.module').then(m => m.DomainModule)
      },
      {
        path: InternalRoutes.DASHBOARD,
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: InternalRoutes.DOCUMENTS + '/:id',
        loadChildren: () => import('./modules/document/document-details/document-details.module').then(m => m.DocumentDetailsModule)
      },
      {
        path: InternalRoutes.SUPPORT,
        loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule)
      },
      {
        path: InternalRoutes.BASKET,
        children: [
          {
            path: InternalRoutes.DOCUMENTS,
            loadChildren: () => import('./modules/basket/basket.module').then( m => m.BasketModule )
          },
          {
            path: InternalRoutes.ENTITIES,
            loadChildren: () => import('./modules/basket/basket.module').then( m => m.BasketModule )
          }
         ]
      },
      {
        path: '**', redirectTo: InternalRoutes.SEARCH
      }
    ]
  }
];
