import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '../tooltip/tooltip.module';
import { LogoNexiaComponent } from './component/logo-nexia.component';

@NgModule({
  declarations: [LogoNexiaComponent],
  imports: [
    CommonModule,
    TooltipModule,
    TranslateModule,
    RouterModule,
    MatMenuModule
  ],
  exports: [
    LogoNexiaComponent
  ]
})
export class LogoNexiaModule { }
