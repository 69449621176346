import { Reducer } from 'redux';
import { SearchItem } from '../../../../models/search-item';
import { PayloadAction } from '../../../../../store/payload-action';


export interface EntitySearchItemsState {
  datas: SearchItem[];
  loading: boolean;
  error: any;
}

const ENTITY_SEARCH_ITEMS_INITIAL_STATE: EntitySearchItemsState = {
  datas: [],
  loading: false,
  error: null,
};

export class EntitySearchItemsActionConstant {
  static readonly ADD_ENTITY_SEARCH_ITEM_START = 'ADD_ENTITY_SEARCH_ITEM_START';
  static readonly ADD_ENTITY_SEARCH_ITEM_SUCCEEDED = 'ADD_ENTITY_SEARCH_ITEM_SUCCEEDED';
  static readonly ADD_ENTITY_SEARCH_ITEM_FAILED = 'ADD_ENTITY_SEARCH_ITEM_FAILED';

  static readonly REMOVE_ENTITY_SEARCH_ITEM_START = 'REMOVE_ENTITY_SEARCH_ITEM_START';
  static readonly REMOVE_ENTITY_SEARCH_ITEM_SUCCEEDED = 'REMOVE_ENTITY_SEARCH_ITEM_SUCCEEDED';
  static readonly REMOVE_ENTITY_SEARCH_ITEM_FAILED = 'REMOVE_ENTITY_SEARCH_ITEM_FAILED';

  static readonly REMOVE_ALL_ENTITY_SEARCH_ITEMS_SUCCEEDED = 'REMOVE_ALL_ENTITY_SEARCH_ITEMS_SUCCEEDED';
}

export const entitySearchItemsReducer: Reducer<EntitySearchItemsState> = (state: EntitySearchItemsState = ENTITY_SEARCH_ITEMS_INITIAL_STATE, action: PayloadAction<SearchItem>) => {
  switch (action.type) {
    case EntitySearchItemsActionConstant.ADD_ENTITY_SEARCH_ITEM_START:
    case EntitySearchItemsActionConstant.REMOVE_ENTITY_SEARCH_ITEM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EntitySearchItemsActionConstant.ADD_ENTITY_SEARCH_ITEM_SUCCEEDED:
      const item: SearchItem = action.payload;
      const size = state.datas.length;
      const lastItemId = state.datas.length ? state.datas[state.datas.length - 1].id : 0;
      if (item.id === -1) {
        item.id = size > lastItemId ? size : lastItemId + 1;
        return {
          ...state,
          datas: state.datas.concat(item),
          loading: false,
          error: null,
        };
      } else {
        const itemIndex = state.datas.findIndex((stateItem) => stateItem.id === item.id);
        state.datas.splice(itemIndex, 1, item);
        return {
          ...state,
          loading: false,
          error: null,
        };
      }
      // const max = state.datas.reduce((m, oneItem) => Math.max(m, oneItem.id), 0) + 1;
      // item.id = max;
      // return {
      //   ...state,
      //   datas: state.datas.concat(item),
      //   loading: false,
      //   error: null,
      // };
    case EntitySearchItemsActionConstant.ADD_ENTITY_SEARCH_ITEM_FAILED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: action.payload,
      };
    case EntitySearchItemsActionConstant.REMOVE_ENTITY_SEARCH_ITEM_SUCCEEDED:
      const copy = state.datas.filter(oneItem => {
        return oneItem.id !== action.payload.id;
      });
      return {
        ...state,
        datas: copy,
        loading: false,
        error: null,
      };
    case EntitySearchItemsActionConstant.REMOVE_ENTITY_SEARCH_ITEM_FAILED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: action.error,
      };
    case EntitySearchItemsActionConstant.REMOVE_ALL_ENTITY_SEARCH_ITEMS_SUCCEEDED:
    return {
      ...state,
      datas: [],
      loading: false,
      error: null,
    };
  }
  return state;
};
