import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Txt2htmlPipe } from './txt2html.pipe';

@NgModule({
  declarations: [Txt2htmlPipe],
  imports: [
    CommonModule
  ],
  exports: [Txt2htmlPipe]
})
export class Txt2htmlModule { }
