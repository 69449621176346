import { ToastaService } from 'ngx-toasta';
import { catchError, filter, map, switchMap, take, tap, timeoutWith, withLatestFrom } from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, NavigationEnd, Router, RouterEvent, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnDestroy } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { forkJoin, Observable, of, SubscriptionLike, throwError } from 'rxjs';

import { AuthenticationService } from './modules/authentication/authentication.service';
import { I18nService } from './core/services/i18n/i18n.service';
import { MenuService } from './core/redux/menu/service/menu.service';
import { DomainItem } from './core/models/domain-item';
import { Utils } from './core/utils/utils';
import { DomainService } from './core/redux/domain/service/domain.service';
import { MaintenanceInfoService } from './core/redux/maintenance-info/service/maintenance-info.service';
import { IAppState } from './store/model';
import { StoreKeys } from './core/models/store-keys';
import { StoreReducers } from './store/root.reducer';
import { CurrentContextState } from './core/redux/current-context/reducer/current-context.reducer';
import { CurrentModule } from './core/models/current-module';
import { CurrentContextService } from './core/redux/current-context/service/current-context.service';
import { SearchResultPagination } from './core/models/search-result-pagination';
import { ObjectType } from './core/models/ObjectType';
import { SearchResultPaginationAction } from './core/redux/search-result-pagination/action/search-result-pagination.action';

export class AppLoaded {
  loaded: boolean;
  inProgress: boolean;

  constructor(loaded: boolean, inProgress: boolean) {
    this.loaded = loaded;
    this.inProgress = inProgress;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GlobalConfigGuard implements CanActivate, CanActivateChild, OnDestroy {
  @select([StoreReducers.CONFIGURATION, StoreKeys.APPLICATION, 'error']) conferror$: Observable<any>;
  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CURRENT_CONTEXT]) currentContext$: Observable<CurrentContextState>;
  private subs: SubscriptionLike[] = [];

  constructor(
    private router: Router,
    private currentContextService: CurrentContextService
  ) {
    this.subs.push(this.conferror$.subscribe(
      (conferror) => {
        if (conferror) {
          this.router.navigate(['error', { error: conferror }]);
        }
      }));
    this.subs.push(this.router.events.pipe(
      filter((event: RouterEvent) => (event instanceof NavigationEnd)),
      withLatestFrom(this.currentContext$),
    ).subscribe(([event, currentContextState]: [RouterEvent, CurrentContextState]) => {
        this.checkForCurrentModule(currentContextState);
    }));
  }

  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }

  canActivate() {
    return true; // this.configurationActions.loadConfiguration('application');
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate();
  }

  private checkForCurrentModule(currentContextState: CurrentContextState): void {
    let isWorkflow = false;
    if (this.router.url.indexOf(CurrentModule.WORKFLOW) !== -1) {
      isWorkflow = true;
    }
    if (isWorkflow) {
      if (!currentContextState || !currentContextState.datas || currentContextState.datas.currentModule !== CurrentModule.WORKFLOW) {
        this.currentContextService.setCurrentModule(CurrentModule.WORKFLOW);
      }
    } else {
      if (!currentContextState || !currentContextState.datas || currentContextState.datas.currentModule !== CurrentModule.GED) {
        this.currentContextService.setCurrentModule(CurrentModule.GED);
      }
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGard implements CanActivate, CanActivateChild, OnDestroy {
  @select([StoreReducers.CONFIGURATION, StoreKeys.APPLICATION]) config$: Observable<any>;
  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.DOMAINS, 'datas']) workspaceItemsList$: Observable<DomainItem[]>;
  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.SEARCH_RESULT_PAGINATION, 'datas']) searchResultPaginationDatas$: Observable<{-readonly [searchTypeKey in ObjectType]?: SearchResultPagination}>;

  public bootstrapApp: AppLoaded = new AppLoaded(false, false);
  private subs: SubscriptionLike[] = [];
  private storeKeysToReset: string[] = [
    StoreKeys.GED_CFG_FIELD,
    StoreKeys.WKF_CFG_FIELD,
    StoreKeys.CFG_DOCUMENT_INFO,
    StoreKeys.VIEW,
    StoreKeys.DOCUMENT_SEARCH_RESULT,
    StoreKeys.FILTERS,
    StoreKeys.LIST_INDEXATION,
    StoreKeys.DRAFT_FILTERS,
    StoreKeys.ENTITY_FILTERS,
    StoreKeys.ENTITY_SEARCH_RESULT,
    StoreKeys.DRAFT_DATAS,
    StoreKeys.DOMAINS
    // StoreKeys.CURRENT_CONTEXT,
  ];
  private firstCharge = true;

  constructor(
    private ngRedux: NgRedux<IAppState>,
    private router: Router,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private toastaService: ToastaService,
    private domainService: DomainService,
    private i18nService: I18nService,
    private maintenanceInfoService: MaintenanceInfoService,
    private searchResultPaginationAction: SearchResultPaginationAction
  ) {
    this.maintenanceInfoService.setAuthGuard(this);
  }

  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }

  private errorManagement(errorMsg: string): void {
    this.toastaService.error(this.translateService.instant(errorMsg));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      return this.config$.pipe(
        filter(conf => !!conf && !conf.loading && conf.loaded),
        map(res => {
          if (!res) {
            // Error when loading config -> error page
            this.router.navigate(['/error']);
            this.authenticationService.clearStorage();
            return false;
          } else {
            return true;
          }
        }),
        take(1)
      ).toPromise().then(value => {
        if (!value) {
          return Promise.resolve(false);
        }
        return this.authenticationService.authenticate(state.url);
      }).then((authenticated: boolean) => {
        if (authenticated && !this.bootstrapApp.loaded && !this.bootstrapApp.inProgress) {
          if (!this.firstCharge) {
            this.ngRedux.dispatch({type: 'RESET_AFTER_MAINTENANCE', payload: this.storeKeysToReset});
          }
          this.firstCharge = false;
          this.bootstrapApp.inProgress = true;
          return new Promise<boolean>((resolve) => {
            this.subs.push(this.i18nService.loadI18n().pipe(
              timeoutWith(20000, throwError(new Error('Timeout on loading translate'))),
              take(1),
              tap((response) => {
                if (!response) {
                  throwError('translate not loaded');
                }
              }),
              withLatestFrom(this.workspaceItemsList$, this.searchResultPaginationDatas$),
              map(([isLoaded, workspaceItemList, searchResultPaginationDatas]: [boolean, DomainItem[], {-readonly [searchTypeKey in ObjectType]?: SearchResultPagination}]) => {
                const requests: Observable<boolean>[] = [this.menuService.loadMenuItems()];
                if (!Utils.notNullAndNotUndefined(workspaceItemList) || !workspaceItemList.length) {
                  requests.push(this.domainService.loadWorkspaceItems());
                }
                if (!Utils.notNullAndNotUndefined(searchResultPaginationDatas)) {
                  this.searchResultPaginationAction.loadSearchResultPagination();
                }
                return requests;
              }),
              switchMap((requests: Observable<boolean>[]) => {
                return forkJoin(requests).pipe(
                  map(([a, b]) => {
                    if (Utils.notNullAndNotUndefined(a) && Utils.notNullAndNotUndefined(b)) {
                      return a && b;
                    } else if (Utils.notNullAndNotUndefined(a)) {
                      return a;
                    } else {
                      return b;
                    }
                  })
                );
              }),
              catchError((error) => {
                this.errorManagement(error.message);
                return of(false);
              })
            ).subscribe((response: boolean) => {
              this.bootstrapApp.loaded = response;
              this.bootstrapApp.inProgress = false;
              return resolve(response);
            }));
          });
        }
        return Promise.resolve(authenticated);
      });
    } catch (err) {
      // Error -> error page
      console.error('AuthGard', err);
      this.router.navigate(['/error'], {queryParams: {error: err}});
      this.authenticationService.clearStorage();
      return Promise.resolve(false);
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(childRoute, state);
  }

  public setAppLoaded(loaded: boolean): void {
    this.bootstrapApp.loaded = loaded;
  }

}
