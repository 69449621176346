import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { cfgTeamReducer, CfgTeamState, CgfTeamConstant } from '../reducer/cfg-team.reducer';
import { IAppState } from '../../../../../store/model';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { Team } from '../../../../models/team';

@Injectable({
  providedIn: 'root'
})
export class CfgTeamAction {
  private localStore: ObservableStore<CfgTeamState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CFG_TEAM], cfgTeamReducer);
  }

  public loadCfgTeamsStarted(): void {
    this.localStore.dispatch({
      type: CgfTeamConstant.ADD_MULTIPLE_CFG_TEAM_START
    });
  }

  public loadCfgTeamsSucceeded(teams: Team[]): void {
    this.localStore.dispatch({
      type: CgfTeamConstant.ADD_MULTIPLE_CFG_TEAM_SUCCEEDED,
      payload: teams
    });
  }

  public loadCfgTeamsFailed(error: any) {
    this.localStore.dispatch({
      type: CgfTeamConstant.ADD_MULTIPLE_CFG_TEAM_FAILED,
      error: error
    });
  }

  public removeAllCfgTeamsSucceeded(): void {
    this.localStore.dispatch({
      type: CgfTeamConstant.REMOVE_ALL_CFG_TEAM_SUCCEEDED
    });
  }
}
