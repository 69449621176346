import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

// TODO : take from config
const default_title = 'Nexia';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  title = new BehaviorSubject(default_title);

  setTitle(title: string) {
    if (title == null || title === undefined || title === '') {
      this.title.next(default_title);
    } else {
      this.title.next(title);
    }
  }
}
