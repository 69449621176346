import { Injectable } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { PopupRequest } from '@azure/msal-browser';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-common';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ConfigurationActions } from '../../../modules/configuration/configuration.actions';
import { AuthenticationService } from '../../../modules/authentication/authentication.service';
import { BlobFile } from '../../models/blob-file';
import { HttpErrorInterceptor } from '../../../modules/http-error-interceptor/http-error-interceptor';
import { AuthenticationInterceptor } from '../../../modules/authentication/authentication-interceptor';

export class UploadDoneItem {
  uploadDone: boolean;
  driveItem: HttpResponse<any> | null;
  constructor(uploadDone: boolean, driveItem: HttpResponse<any> | null) {
    this.uploadDone = uploadDone;
    this.driveItem = driveItem;
  }
}

export interface MsMailBodyItem {
  AttachmentItem: any;
}

export interface MsDriveBodyItem {
  item: any;
}

export interface UploadSessionObject {
  file: BlobFile;
  headers: HttpHeaders;
  url: string;
  bodyItem: MsMailBodyItem | MsDriveBodyItem;
}


@Injectable({
  providedIn: 'root'
})
export abstract class AzureService {
  private static readonly CHUNK_LENGTH = 320 * 1024;
  private static readonly MAX_NB_OF_CALLS_FOR_CHUNKS = 1000;
  protected static readonly BIG_FILE_SIZE = 4000 * 1024;
  protected static readonly EMAIL_BIG_FILE_SIZE = 3000 * 1024;
  protected static readonly AZURE_URL = 'https://graph.microsoft.com/v1.0/me/';
  protected static readonly CREATE_UPLOAD_SESSION = '/createUploadSession';

  protected static createMsDriveBodyItem(): any {
    const item: any = {};
    item['@microsoft.graph.conflictBehavior'] = 'rename';
    return item;
  }

  protected static createMsMailBodyItem(fileName: string, fileSize: number): any {
    const item: any = {};
    item['attachmentType'] = 'file';
    item['name'] = fileName;
    item['size'] = fileSize;
    item['isInline'] = false;
    return item;
  }

  protected static query(): Observable<HttpParams> {
    const httpParams = new HttpParams();
    return of(httpParams);
  }

  constructor(
    protected msalGuardConfig: MsalGuardConfiguration,
    protected broadcastService: MsalBroadcastService,
    protected msAuthService: MsalService,
    protected httpClient: HttpClient,
    protected translateService: TranslateService,
    protected authenticationService: AuthenticationService,
    protected configAction: ConfigurationActions
  ) {
  }

  protected checkLogin(): Observable<AuthenticationResult> {
    const msalGuardConfig: PopupRequest | undefined = this.msalGuardConfig.authRequest ? {...this.msalGuardConfig.authRequest} as PopupRequest : undefined;
    return this.msAuthService.loginPopup(msalGuardConfig);
  }

  protected uploadFileBySession(uploadSessionObject: UploadSessionObject): Observable<HttpResponse<any>> {
    return this.getUploadSession(uploadSessionObject).pipe(
      switchMap((uploadSession: HttpResponse<any>) => {
        const uploadUrl: string = uploadSession.body.uploadUrl;
        return this.uploadChunks(uploadSessionObject.file, uploadUrl);
      }),
      filter((uploadDoneItem: UploadDoneItem) => uploadDoneItem.uploadDone && !!uploadDoneItem.driveItem),
      map((uploadDoneItem: UploadDoneItem) => uploadDoneItem.driveItem)
    );
  }

  private getUploadSession(uploadSessionObject: UploadSessionObject): Observable<HttpResponse<any>> {
    return this.httpClient.post(uploadSessionObject.url, uploadSessionObject.bodyItem, {
      headers: uploadSessionObject.headers,
      observe: 'response'
    });
  }

  private uploadChunks(file: BlobFile, uploadUrl: string): Observable<UploadDoneItem> {
    const uploadDone: Subject<UploadDoneItem> = new Subject<UploadDoneItem>();
    return this._uploadChunks(file, uploadUrl, 0, AzureService.CHUNK_LENGTH, uploadDone);
  }

  private _uploadChunks(file: BlobFile, uploadUrl: string, position = 0, chunkLength, uploadDone: Subject<UploadDoneItem>, nbOfCalls = 1): Observable<UploadDoneItem> {
    let chunk: ArrayBuffer;
    if (nbOfCalls >= AzureService.MAX_NB_OF_CALLS_FOR_CHUNKS) {
      console.error('Exceeded max number of calls for uploading chunk');
      throw new Error('Exceeded max number of calls for uploading chunk');
    }
    try {
      const stopByte = position + chunkLength;
      this.readFragmentAsync(file, position, stopByte).then((result: ArrayBuffer) => {
        chunk = result;
        // if (!chunk || chunk.byteLength <= 0) {
        //   uploadDone.next(new UploadDoneItem(true, null));
        //   return;
        // }

        try {
          console.log('Request sent for uploadFragmentAsync');
          this.uploadChunk(chunk, uploadUrl, position, file.fileSize).subscribe((response: HttpResponse<any>) => {
            // Check the response.
            if (response.status !== 202 && response.status !== 201 && response.status !== 200) {
              throw new Error('Put operation did not return expected response');
            }
            if (response.status === 201) {
              console.log('Reached last chunk of file.  Status code is: ' + response.status);
              uploadDone.next(new UploadDoneItem(true, response));
            } else if (response.status === 202 || response.status === 200) {
              console.log('Continuing - Status Code is: ' + response.status);
              position = Number(response.body.nextExpectedRanges[0].split('-')[0]);
              this._uploadChunks(file, uploadUrl, position, chunkLength, uploadDone, nbOfCalls++);
            }

            console.log('Response received from uploadChunk.');
            console.log('Position is now ' + position);
          }, (error: HttpErrorResponse) => {
            console.error('ERROR on uploading chunk');
            throw new Error(error.message);
          });
        } catch (e) {
          console.log('Error occured when calling uploadChunk::' + e);
          throw new Error(e);
        }
      }, (error) => {
        console.error('ERROR on reading chunk');
      });
    } catch (error) {
      console.error('ERROR on reading chunk');
    }
    return uploadDone.asObservable();
  }

  private uploadChunk(chunk: ArrayBuffer, uploadUrl: string, position: number, totalLength: number): Observable<any> {
    const max: number = position + chunk.byteLength - 1;
    const crHeader = `bytes ${position}-${max}/${totalLength}`;
    const chunkHeaders: HttpHeaders = new HttpHeaders()
      .set('Content-Length', max + '')
      .set('Content-Range', crHeader)
      .set('Content-Type', 'application/octet-stream')
      .set(HttpErrorInterceptor.BYPASS_HEADER, '')
      .set(AuthenticationInterceptor.BYPASS_HEADER, '');
    return this.httpClient.put(uploadUrl, chunk, {headers: chunkHeaders, observe: 'response'});
  }

  private readFragmentAsync(file: BlobFile, startByte: number, stopByte: number): Promise<ArrayBuffer> {
    let fragment: ArrayBuffer;
    const reader = new FileReader();
    const blob: Blob = file.blob.slice(startByte, stopByte);
    reader.readAsArrayBuffer(blob);
    return new Promise<ArrayBuffer>((resolve, reject) => {
      reader.onloadend = (event: ProgressEvent<FileReader>) => {
        if (reader.readyState === reader.DONE) {
          fragment = reader.result as ArrayBuffer;
          resolve(fragment);
        }
      };
    });
  }

  public getIsMsAlConfig(): boolean {
    const applicationConfig: any = this.configAction.get('application');
    return !!applicationConfig['AZURE_MSAL_AUTH_CLIENTID'] && !!applicationConfig['AZURE_MSAL_AUTH_AUTHORITY']
      && !!applicationConfig['AZURE_MSAL_AUTH_REDIRECT_URI'] && !!applicationConfig['AZURE_MSAL_GRAPH_API_URL']
      && !!applicationConfig['AZURE_MSAL_OUTLOOK_URL'];
  }
}
