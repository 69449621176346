import { Reducer } from 'redux';
import { PayloadAction } from '../../../../../store/payload-action';
import { Field } from '../../../../models/field';


export interface WkfFieldState {
  datas: {[key: string]: Field};
  loading: boolean;
  error: any;
}

const WKF_CFG_INITIAL_STATE: WkfFieldState = {
  datas: {},
  loading: false,
  error: null,
};

export class WkfCfgFieldConstant {
  static readonly ADD_MULTIPLE_CFG_FIELD_START = '[WKF]ADD_MULTIPLE_CFG_FIELD_START';
  static readonly ADD_MULTIPLE_CFG_FIELD_SUCCEEDED = '[WKF]ADD_MULTIPLE_CFG_FIELD_SUCCEEDED';
  static readonly ADD_MULTIPLE_CFG_FIELD_FAILED = '[WKF]ADD_MULTIPLE_CFG_FIELD_FAILED';

  static readonly REMOVE_ALL_CFG_FIELD_START = '[WKF]REMOVE_ALL_CFG_FIELD_START';
  static readonly REMOVE_ALL_CFG_FIELD_SUCCEEDED = '[WKF]REMOVE_ALL_CFG_FIELD_SUCCEEDED';
  static readonly REMOVE_ALL_CFG_FIELD_FAILED = '[WKF]REMOVE_ALL_CFG_FIELD_FAILED';


  // static readonly STORE_PATH = [StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CFG_FIELD];
  // static readonly STORE_PATH_DATAS = [...CgfFieldConstant.STORE_PATH, 'datas'];
  // static readonly STORE_PATH_ERROR = [...CgfFieldConstant.STORE_PATH, 'error'];
  // static readonly STORE_PATH_LOADING = [...CgfFieldConstant.STORE_PATH, 'loading'];
}

export const wkfCfgFieldReducer: Reducer<WkfFieldState> = (state: WkfFieldState = WKF_CFG_INITIAL_STATE, action: PayloadAction<{[key: string]: Field}>): WkfFieldState => {
  switch (action.type) {
    // ADD MULTIPLE
    case WkfCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case WkfCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_SUCCEEDED:
      return {
        ...state,
        datas: {...state.datas, ...action.payload},
        loading: false,
        error: null,
      };
    case WkfCfgFieldConstant.ADD_MULTIPLE_CFG_FIELD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      // REMOVE ALL
    case WkfCfgFieldConstant.REMOVE_ALL_CFG_FIELD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case WkfCfgFieldConstant.REMOVE_ALL_CFG_FIELD_SUCCEEDED:
      return {
        ...state,
        datas: {},
        loading: false,
        error: null,
      };
    case WkfCfgFieldConstant.REMOVE_ALL_CFG_FIELD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
