import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DomainComponent } from './components/domain.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { LogoNexiaModule } from '../logo-nexia/logo-nexia.module';

@NgModule({
  declarations: [DomainComponent],
  imports: [
    LogoNexiaModule,
    CommonModule,
    TooltipModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    DomainComponent
  ]
})
export class WorkspaceModule { }
