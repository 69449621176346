import { FromJson } from './fromJson';
import { Privacy } from './privacy';

export enum FieldType {
  AUTHOR = 'AUTHOR',
  DATE = 'DATE', STRING = 'STRING', DECIMAL = 'DECIMAL', INTEGER = 'INTEGER', BOOLEAN = 'BOOLEAN', LIST = 'LIST', THESAURUS = 'THESAURUS', XML = 'XML'

}

export enum SearchOperator {
  // c("contains", MATCH), e("equals", TERM), ne("not equals", TERM), gt("greater than", RANGE), lt("lower than", RANGE), gte("greater than or equals", RANGE), lte("lower than or equals", RANGE), n("null", EXISTS), nn("not null", EXISTS);
  gt = 'gt', // greater than
  lt = 'lt', // lower than
  gte = 'gte', // greater than or equal
  lte = 'lte', // lower than or equal
  c = 'c', // contains MATCH
  e = 'e', // equal
  ne = 'ne', // not equal
  n = 'n', // null (non défini)
  nn = 'nn', // not null
  np = 'np'
}

export namespace FieldType {
  export function getDefaultOperator(fieldType: FieldType, autocompletion = true): SearchOperator {
    if (!fieldType) {
      return ;
    }
    switch (fieldType) {
      case FieldType.DATE:
        // return autocompletion ? SearchOperator.c : SearchOperator.e;
        return SearchOperator.c;
      case FieldType.STRING:
      case FieldType.LIST:
      case FieldType.THESAURUS:
      case FieldType.DECIMAL:
      case FieldType.INTEGER:
        return autocompletion ? SearchOperator.e : SearchOperator.c;
      case FieldType.BOOLEAN:
        return SearchOperator.e;
      case FieldType.XML:
        return SearchOperator.c;
      default:
        return SearchOperator.e;
    }
  }

  export function getOperators(fieldType: FieldType, isOperatorsForValue = false): SearchOperator[] {
    if (!fieldType && isOperatorsForValue) {
      return [SearchOperator.e, SearchOperator.c];
    }
    switch (fieldType) {
      case FieldType.STRING:
        return [
          SearchOperator.e,
          SearchOperator.ne,
          SearchOperator.c
        ];
      // case FieldType.AUTHOR:
      //   break;
      case FieldType.DATE:
        return [
          // SearchOperator.e, // TODO mettre en place une saisie du temps pour activer cet opérateur
          SearchOperator.c,
          SearchOperator.gte,
          SearchOperator.lte,
          SearchOperator.lt,
          SearchOperator.gt,
          ];
      case FieldType.DECIMAL:
        return [
          SearchOperator.e,
          SearchOperator.c,
          SearchOperator.gte,
          SearchOperator.lte,
          SearchOperator.lt,
          SearchOperator.gt,
        ];
      case FieldType.INTEGER:
        return [
          SearchOperator.e,
          SearchOperator.gte,
          SearchOperator.lte,
          SearchOperator.lt,
          SearchOperator.gt,
        ];
      case FieldType.BOOLEAN:
        return [
          SearchOperator.e
          ];
      case FieldType.LIST:
        return [
          SearchOperator.e,
          SearchOperator.ne,
          SearchOperator.c
          ];
      case FieldType.THESAURUS:
        return [
          SearchOperator.e,
          SearchOperator.ne,
          SearchOperator.c
        ];
      case FieldType.XML:
        return [
          SearchOperator.c
        ];
      default:
        return [];
    }
  }

  export function getDisplayOperatorBySearchOperator(searchOperator: SearchOperator): string {
    switch (searchOperator) {
      case SearchOperator.c:
        return '&#8776;';
      case SearchOperator.e:
        return '=';
      case SearchOperator.ne:
        return '&#8800;';
      case SearchOperator.gt:
        return '&#062;';
      case SearchOperator.lt:
        return '&#060;';
      case SearchOperator.gte:
        return '&#8805;';
      case SearchOperator.lte:
        return '&#8804;';
      case SearchOperator.n:
        return 'null';
      case SearchOperator.nn:
        return 'not null';
      case SearchOperator.np:
        return '&#8776;=';
    }
  }
}


export class Field extends FromJson {

  code: string;
  type: FieldType;
  format: any;
  label: {};
  privacy: Privacy;

  static fromJson<T>(json: any, ctor: (new () => T)): T {
    const f = super.fromJson(json, ctor);
    f['type'] = FieldType[json.type as string];
    f['label'] = { // FIXME get directly string, back knows the locale
      fr : json.label,
      en : json.label
    };
    return f;
  }
}

