import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { TableFilterConstant, tableFilterReducer, TableFilterState } from '../reducer/table-filter.reducer';
import { IAppState } from '../../../../store/model';
import { FilterData } from '../../../models/filter-data';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';

@Injectable({
  providedIn: 'root'
})
export class TableFilterAction {
  private localStore: ObservableStore<TableFilterState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.TABLE_FILTER], tableFilterReducer);
  }

  public loadTableFilterStarted(): void {
    this.localStore.dispatch({
      type: TableFilterConstant.LOAD_TABLE_FILTER_START
    });
  }

  public loadTableFilterSucceeded(filterData: {[key: string]: FilterData}): void {
    this.localStore.dispatch({
      type: TableFilterConstant.LOAD_TABLE_FILTER_SUCCEEDED,
      payload: filterData
    });
  }

  public loadTableFilterFailed() {
    this.localStore.dispatch({
      type: TableFilterConstant.LOAD_TABLE_FILTER_FAILED
    });
  }

  public removeTableFilter() {
    this.localStore.dispatch({
      type: TableFilterConstant.REMOVE_TABLE_FILTER
    });
  }

  public removeTableFilterByKey(tableFilterKey: string) {
    this.localStore.dispatch({
      type: TableFilterConstant.REMOVE_TABLE_FILTER_BY_KEY,
      payload: tableFilterKey
    });
  }
}
