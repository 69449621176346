import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '../tooltip/tooltip.module';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { MobileButtonMenuComponent } from './components/mobile-button-menu/mobile-button-menu.component';
import { MenuModule } from '../menu/menu.module';
import { MobileButtonDomainSelectionComponent } from './components/mobile-button-domain-selection/mobile-button-domain-selection.component';
import { MobileButtonFilterSelectionComponent } from './components/mobile-button-filter-selection/mobile-button-filter-selection.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';

@NgModule({
  declarations: [
    MobileHeaderComponent,
    MobileButtonMenuComponent,
    MobileButtonDomainSelectionComponent,
    MobileButtonFilterSelectionComponent,
    MobileMenuComponent
  ],
  imports: [
    MenuModule,
    PerfectScrollbarModule,
    CommonModule,
    TooltipModule,
    ClickOutsideModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    MobileHeaderComponent,
    MobileButtonMenuComponent,
    MobileButtonDomainSelectionComponent,
    MobileButtonFilterSelectionComponent,
    MobileMenuComponent
  ]
})
export class MobileMenuModule { }
