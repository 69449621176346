import { Reducer } from 'redux';
import { SearchItem } from '../../../../models/search-item';
import { PayloadAction } from '../../../../../store/payload-action';


export interface TaskSearchItemsState {
  datas: SearchItem[];
  loading: boolean;
  error: any;
}

const TASK_SEARCH_ITEMS_INITIAL_STATE: TaskSearchItemsState = {
  datas: [],
  loading: false,
  error: null,
};

export class TaskSearchItemsActionConstant {
  static readonly ADD_TASK_SEARCH_ITEM_START = 'ADD_TASK_SEARCH_ITEM_START';
  static readonly ADD_TASK_SEARCH_ITEM_SUCCEEDED = 'ADD_TASK_SEARCH_ITEM_SUCCEEDED';
  static readonly ADD_TASK_SEARCH_ITEM_FAILED = 'ADD_TASK_SEARCH_ITEM_FAILED';

  static readonly REMOVE_TASK_SEARCH_ITEM_START = 'REMOVE_TASK_SEARCH_ITEM_START';
  static readonly REMOVE_TASK_SEARCH_ITEM_SUCCEEDED = 'REMOVE_TASK_SEARCH_ITEM_SUCCEEDED';
  static readonly REMOVE_TASK_SEARCH_ITEM_FAILED = 'REMOVE_TASK_SEARCH_ITEM_FAILED';

  static readonly REMOVE_ALL_TASK_SEARCH_ITEMS_SUCCEEDED = 'REMOVE_ALL_TASK_SEARCH_ITEMS_SUCCEEDED';
}

export const taskSearchItemsReducer: Reducer<TaskSearchItemsState> = (state: TaskSearchItemsState = TASK_SEARCH_ITEMS_INITIAL_STATE, action: PayloadAction<SearchItem>) => {
  switch (action.type) {
    case TaskSearchItemsActionConstant.ADD_TASK_SEARCH_ITEM_START:
    case TaskSearchItemsActionConstant.REMOVE_TASK_SEARCH_ITEM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TaskSearchItemsActionConstant.ADD_TASK_SEARCH_ITEM_SUCCEEDED:
      const max = state.datas.reduce((m, oneItem) => Math.max(m, oneItem.id), 0) + 1;
      const item: SearchItem = action.payload;
      item.id = max;
      return {
        ...state,
        datas: state.datas.concat(item),
        loading: false,
        error: null,
      };
    case TaskSearchItemsActionConstant.ADD_TASK_SEARCH_ITEM_FAILED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: action.payload,
      };
    case TaskSearchItemsActionConstant.REMOVE_TASK_SEARCH_ITEM_SUCCEEDED:
      const copy = state.datas.filter(oneItem => {
        return oneItem.id !== action.payload.id;
      });
      return {
        ...state,
        datas: copy,
        loading: false,
        error: null,
      };
    case TaskSearchItemsActionConstant.REMOVE_TASK_SEARCH_ITEM_FAILED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: action.error,
      };
    case TaskSearchItemsActionConstant.REMOVE_ALL_TASK_SEARCH_ITEMS_SUCCEEDED:
    return {
      ...state,
      datas: [],
      loading: false,
      error: null,
    };
  }
  return state;
};
