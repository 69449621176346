import { Reducer } from 'redux';

import { Aggregate } from '../../../../models/aggregate';
import { PayloadAction } from '../../../../../store/payload-action';
import { EsPage } from '../../../../models/es-page';
import { DraftDocument } from '../../../../models/DraftDocument';

export interface ListIndexationState {
  datas: EsPage<DraftDocument>;
  loading: boolean;
  loadAgg: boolean;
  error: any;
}

const CURRENT_LIST_INDEXATION_INITIAL_STATE: ListIndexationState = {
  datas: null,
  loading: false,
  loadAgg: false,
  error: null,
};

export class ListIndexationConstant {
  static readonly ERROR_START = 'ERROR_START';

  static readonly LOAD_LIST_INDEXATION_START = '[ListIndexation].LOAD_LIST_INDEXATION_START';
  static readonly LOAD_LIST_INDEXATION_SUCCEEDED = '[ListIndexation].LOAD_LIST_INDEXATION_SUCCEEDED';
  static readonly LOAD_LIST_INDEXATION_FAILED = '[ListIndexation].LOAD_LIST_INDEXATION_FAILED';

  static readonly LOAD_AGGREGATE_START = '[ListIndexation]: LOAD_AGGREGATE_START';
  static readonly LOAD_AGGREGATE_SUCCEEDED = '[ListIndexation]: LOAD_AGGREGATE_SUCCEEDED';
  static readonly LOAD_AGGREGATE_FAILED = '[ListIndexation]: LOAD_AGGREGATE_FAILED';

}

export const listIndexationReducer: Reducer<ListIndexationState> = (state: ListIndexationState = CURRENT_LIST_INDEXATION_INITIAL_STATE, action: PayloadAction<EsPage<DraftDocument>>) => {
  switch (action.type) {
    case ListIndexationConstant.LOAD_AGGREGATE_START:
      return {
        ...state,
        loading: true,
        loadAgg: true,
        error: ListIndexationConstant.ERROR_START,
      };
    case ListIndexationConstant.LOAD_LIST_INDEXATION_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ListIndexationConstant.LOAD_LIST_INDEXATION_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case ListIndexationConstant.LOAD_AGGREGATE_FAILED:
      return {
        ...state,
        loading: false,
        loadAgg: false,
        error: action.error,
      };
    case ListIndexationConstant.LOAD_LIST_INDEXATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ListIndexationConstant.LOAD_AGGREGATE_SUCCEEDED:
      const newAgg: Aggregate = action.payload.aggs[0];
      let aggs: Aggregate[] = state.datas.aggs;
      let added = false;
      aggs = aggs.map(agg => {
        if (agg.path === newAgg.path) {
          added = true;
          return newAgg;
        }
        return agg;
      });
      if (!added) {
        aggs.push(newAgg);
      }
      const copy = {...state.datas, aggs: aggs};
      return {
        ...state,
        datas: copy,
        loading: false,
        loadAgg: false,
        error: null,
      };
  }
  return state;
};
