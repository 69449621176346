import { Injectable } from '@angular/core';
import { AbstractSearchItemsService } from './abstract-search-items.service';
import { TaskSearchItemsAction } from '../../task-search/task-search-item/action/task-search-items.action';

@Injectable({
  providedIn: 'root'
})
export class TaskSearchItemsService extends AbstractSearchItemsService {

  constructor(
    private taskSearchItemAction: TaskSearchItemsAction
  ) {
    super();
  }

  public getSearchItemsAction(): TaskSearchItemsAction {
    return this.taskSearchItemAction;
  }

}
