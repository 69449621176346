import { Injectable } from '@angular/core';
import { AbstractFilterService } from './abstract-filter.service';
import { EntityFilterAction } from '../../entity-search/entity-filter/action/entity-filter.action';

@Injectable({
  providedIn: 'root'
})
export class EntityFilterService extends AbstractFilterService {

  constructor(
    private entityFilterAction: EntityFilterAction
  ) {
    super ();
  }

  public getFilterAction(): EntityFilterAction {
    return this.entityFilterAction;
  }
}
