import { ObjectStatus } from './object-status';

export class FilterData {
  pattern: string;
  sortBy: string;
  order: 'asc' | 'desc' | '';
  pageSize: number;
  pageNumber: number;
  constructor(pattern: string, sortBy: string, order: 'asc' | 'desc' | '', pageSize: number, pageNumber: number) {
    this.pattern = pattern;
    this.sortBy = sortBy;
    this.order = order;
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }
}

export class TeamsFilterData extends FilterData {
  status: ObjectStatus;
  members: number[];

  constructor(pattern: string, sortBy: string, order: 'asc' | 'desc' | '', pageSize: number, pageNumber: number, status: ObjectStatus, members: number[]) {
    super(pattern, sortBy, order, pageSize, pageNumber);
    this.status = status;
    this.members = members;
  }
}
