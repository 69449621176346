import { Reducer } from 'redux';
import { Team } from '../../../../models/team';
import { PayloadAction } from '../../../../../store/payload-action';

export interface CfgTeamState {
  datas: Team[];
  loading: boolean;
  error: any;
}

const CFG_TEAM_INITIAL_STATE: CfgTeamState = {
  datas: [],
  loading: false,
  error: null,
};

export class CgfTeamConstant {
  static readonly ADD_MULTIPLE_CFG_TEAM_START = 'ADD_MULTIPLE_CFG_TEAM_START';
  static readonly ADD_MULTIPLE_CFG_TEAM_SUCCEEDED = 'ADD_MULTIPLE_CFG_TEAM_SUCCEEDED';
  static readonly ADD_MULTIPLE_CFG_TEAM_FAILED = 'ADD_MULTIPLE_CFG_TEAM_FAILED';

  static readonly REMOVE_ALL_CFG_TEAM_SUCCEEDED = 'REMOVE_ALL_CFG_TEAM_SUCCEEDED';
}

export const cfgTeamReducer: Reducer<CfgTeamState> = (state: CfgTeamState = CFG_TEAM_INITIAL_STATE, action: PayloadAction<Team[]>): CfgTeamState => {
  switch (action.type) {
    // ADD MULTIPLE
    case CgfTeamConstant.ADD_MULTIPLE_CFG_TEAM_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CgfTeamConstant.ADD_MULTIPLE_CFG_TEAM_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case CgfTeamConstant.ADD_MULTIPLE_CFG_TEAM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      // REMOVE ALL
    case CgfTeamConstant.REMOVE_ALL_CFG_TEAM_SUCCEEDED:
      return {
        ...state,
        datas: [],
        loading: false,
        error: null,
      };
  }
  return state;
};
