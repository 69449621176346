import { Reducer } from 'redux';

import { PayloadAction } from '../../../../../store/payload-action';

export class IndexationDocTypesState {
  datas: Map<string, string[]>;
  loading: boolean;
  error: any;
}

const INDEXATION_DOC_TYPES_INITIAL_STATE: IndexationDocTypesState = {
  datas: new Map<string, string[]>(),
  loading: false,
  error: null,
};

export class IndexationDocTypesConstant {

  static readonly LOAD_INDEXATION_DOC_TYPES_START = '[IndexationDocTypes].LOAD_INDEXATION_DOC_TYPES_START';
  static readonly LOAD_INDEXATION_DOC_TYPES_SUCCEEDED = '[IndexationDocTypes].LOAD_INDEXATION_DOC_TYPES_SUCCEEDED';
  static readonly LOAD_INDEXATION_DOC_TYPES_FAILED = '[IndexationDocTypes].LOAD_INDEXATION_DOC_TYPES_FAILED';

}

export const indexationDocTypesReducer: Reducer<IndexationDocTypesState> = (state: IndexationDocTypesState = INDEXATION_DOC_TYPES_INITIAL_STATE, action: PayloadAction<Map<string, string[]>>) => {
  switch (action.type) {
    case IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case IndexationDocTypesConstant.LOAD_INDEXATION_DOC_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
  }
  return state;
};
