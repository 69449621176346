import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { ConfigurationActions } from '../../../../modules/configuration/configuration.actions';
import { Url } from '../../../models/url';
import { View } from '../../../models/view';
import { ViewAction } from '../action/view.action';
import { IAppState } from '../../../../store/model';
import { ViewState } from '../reducer/view.reducer';

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  /* tslint:disable-next-line */

  constructor(
    private viewAction: ViewAction,
    private httpClient: HttpClient,
    private configAction: ConfigurationActions,
    private ngRedux: NgRedux<IAppState>
  ) { }

  public getRecapViewForDomain(): void {
    try {
      this.viewAction.loadViewStart();
      this.httpClient.get(Url.getProtectedApiBaseUrl(this.configAction) + Url.VIEW + Url.RECAP + 'domain')
        .subscribe((view: View) => {
          const views: {[key: string]: View} = {};
          views[view.documentTypeCode] = view;
          this.viewAction.loadViewSucceeded(views);
      },
          (error: HttpErrorResponse) =>
          this.viewAction.loadViewFailed(error)
          );
    } catch (err) {
      console.error('error', err);
      this.viewAction.loadViewFailed(err);
    }
  }

  public getRecapView(docType: string, force: boolean = false): void {
    try {
      if (!force && this.get(docType)) {
        return;
      }
      this.viewAction.loadViewStart();
      this.httpClient.get(Url.getProtectedApiBaseUrl(this.configAction) + Url.VIEW + Url.RECAP + docType)
        .subscribe((view: View) => {
          const views: {[key: string]: View} = {};
          views[view.documentTypeCode] = view;
          this.viewAction.loadViewSucceeded(views);
      },
          (error: HttpErrorResponse) =>
          this.viewAction.loadViewFailed(error)
          );
    } catch (err) {
      console.error('error', err);
      this.viewAction.loadViewFailed(err);
    }
  }

  public get(documentType: string) {
    const viewState: ViewState = this.ngRedux.getState().dynamicSubStores.view;
    if (!!viewState && !!viewState.datas) {
      return viewState.datas[documentType] && !!viewState.datas[documentType].disposition && viewState.datas[documentType].disposition.length > 0;
    }
    return null;
  }

}
