import { Reducer } from 'redux';
import { PayloadAction } from '../../../../store/payload-action';
import { CdxComment } from '../../../models/cdx-comment';

export class CurrentCommentContainer {
  currentComment: CdxComment;
  inError: boolean;

  constructor(currentComment: CdxComment, inError: boolean) {
    this.currentComment = currentComment;
    this.inError = inError;
  }
}

export class CurrentCommentState {
  datas: CurrentCommentContainer;
  loading: boolean;
  error: any;
}

const CURRENT_COMMENT_INITIAL_STATE: CurrentCommentState = {
  datas: null,
  loading: false,
  error: null,
};

export class CurrentCommentConstant {

  static readonly LOAD_CURRENT_COMMENT_START = '[CdxComment]: LOAD_CURRENT_COMMENT_START';
  static readonly LOAD_CURRENT_COMMENT_SUCCEEDED = '[CdxComment]: LOAD_CURRENT_COMMENT_SUCCEEDED';
  static readonly LOAD_CURRENT_COMMENT_FAILED = '[CdxComment]: LOAD_CURRENT_COMMENT_FAILED';

  static readonly REMOVE_CURRENT_COMMENT_SUCCEEDED = '[CdxComment]: REMOVE_SUCCEEDED';

}

export const currentCommentReducer: Reducer<CurrentCommentState> = (state: CurrentCommentState = CURRENT_COMMENT_INITIAL_STATE, action: PayloadAction<CurrentCommentContainer>) => {
  switch (action.type) {
    case CurrentCommentConstant.LOAD_CURRENT_COMMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CurrentCommentConstant.LOAD_CURRENT_COMMENT_SUCCEEDED:
      return {
        ...state,
        datas: action.payload,
        loading: false,
        error: null,
      };
    case CurrentCommentConstant.LOAD_CURRENT_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CurrentCommentConstant.REMOVE_CURRENT_COMMENT_SUCCEEDED:
      return {
        ...state,
        datas: null,
        loading: false,
        error: null,
      };
  }
  return state;
};
