import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { CurrentContextAction } from '../action/current-context.action';
import { IAppState } from '../../../../store/model';
import { CurrentContextState } from '../reducer/current-context.reducer';

@Injectable({
  providedIn: 'root'
})
export class CurrentContextService {

  private static colors = new Map<string, string>();

  static getEntityTypeColor(typeDoc: string): string {
    if (!typeDoc) {
      return '';
    }
    if (this.colors.get(typeDoc) != null) {
      return this.colors.get(typeDoc);
    }
    let hash = 0;
    for (let i = 0; i < typeDoc.length; i++) {
      hash = typeDoc.charCodeAt(i) + ((hash << 5) - hash);
    }
    let c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    c = '00000'.substring(0, 6 - c.length) + c;
    this.colors.set(typeDoc, '#' + c);
    return this.colors.get(typeDoc);
  }

  constructor(
    private currentContextAction: CurrentContextAction,
    private ngRedux: NgRedux<IAppState>
  ) {
  }

  public getEntityTypeColor(): string {
    return CurrentContextService.getEntityTypeColor(this.getCurrentEntityType());
  }

  public getCurrentEntityType(): string {
    const currentContextState: CurrentContextState = this.ngRedux.getState().dynamicSubStores.currentContext;
    if (!!currentContextState && currentContextState.datas.currentEntityType) {
      return currentContextState.datas.currentEntityType;
    }
    return null;
  }

  public getCurrentDomain(): string {
    const currentContextState: CurrentContextState = this.ngRedux.getState().dynamicSubStores.currentContext;
    if (!!currentContextState && currentContextState.datas.currentDomain) {
      return currentContextState.datas.currentDomain;
    }
    return null;
  }

  public getCurrentModule(): string {
    const currentContextState: CurrentContextState = this.ngRedux.getState().dynamicSubStores.currentContext;
    if (!!currentContextState && currentContextState.datas.currentModule) {
      return currentContextState.datas.currentModule;
    }
    return null;
  }

  public setCurrentEntityType(entityType: string): void {
    this.currentContextAction.loadCurrentEntityType(entityType);
  }

  public clearCurrentEntityType(): void {
    this.currentContextAction.removeCurrentEntityType();
  }

  public setCurrentDomain(currentDomain: string): void {
    this.currentContextAction.loadCurrentDomain(currentDomain);
  }

  public setCurrentModule(currentModule: string): void {
    this.currentContextAction.loadCurrentModule(currentModule);
  }

  public clearCurrentDomain(): void {
    this.currentContextAction.removeCurrentDomain();
  }

  public clearCurrentModule(): void {
    this.currentContextAction.removeCurrentModule();
  }

}
