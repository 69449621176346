import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { Reducer } from 'redux';
import { IAppState } from '../../../../store/model';
import { GedCfgFieldConstant, GedFieldState } from '../reducer/ged/ged-cfg-field.reducer';
import { WkfCfgFieldConstant, WkfFieldState } from '../reducer/wkf/wkf-cfg-field.reducer';
import { Field } from '../../../models/field';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractCfgFieldAction {
  protected localStore: ObservableStore<GedFieldState | WkfFieldState>;

  constructor(
    protected ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore(this.getCfgFieldStorePath(), this.getCfgFieldReducer());
  }

  protected abstract getCfgFieldStorePath(): string[];
  protected abstract getCfgFieldReducer(): Reducer<GedFieldState | WkfFieldState>;

  protected _dispatchType(dispatchType: GedCfgFieldConstant | WkfCfgFieldConstant): void {
    this.localStore.dispatch({
      type: dispatchType
    });
  }
  protected _dispatchSuccess(dispatchType: GedCfgFieldConstant | WkfCfgFieldConstant, payload: {[key: string]: Field}): void {
    this.localStore.dispatch({
      type: dispatchType,
      payload: payload
    });
  }
  protected _dispatchFail(dispatchType: GedCfgFieldConstant | WkfCfgFieldConstant, error: any): void {
    this.localStore.dispatch({
      type: dispatchType,
      error: error
    });
  }
}
