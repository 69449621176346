import { Injectable } from '@angular/core';
import { AbstractFilterService } from './abstract-filter.service';
import { DraftFilterAction } from '../../indexation/draft-filter/action/draft-filter.action';

@Injectable({
  providedIn: 'root'
})
export class DraftFilterService extends AbstractFilterService {

  constructor(
    private draftFilterAction: DraftFilterAction
  ) {
    super ();
  }

  public getFilterAction(): DraftFilterAction {
    return this.draftFilterAction;
  }
}
