import { EsPage } from './es-page';
import { CdxComment } from './cdx-comment';
import { ActivityDiff, CdxActivity } from './cdx-activity';
import { CdxAttachment } from './cdx-attachment';
import { CdxTask } from './cdx-task';

export class TaskDetails {
  details: CdxTask;
  comments: CdxComment[];
  attachments: CdxAttachment[];
  activities: EsPage<CdxActivity>;
  token: string;
  displayInfoBlock?: boolean;
  loadPdf?: boolean;
  currentActivityDiff: ActivityDiff;
}
