import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { RouterPathAction } from '../action/router-path.action';
import { RouterPathInfo } from '../../../models/router-path-info';
import { MenuService } from '../../menu/service/menu.service';
import { CurrentContextService } from '../../current-context/service/current-context.service';

@Injectable({
  providedIn: 'root'
})
export class RouterPathService implements OnDestroy {
private subs: Subscription[] = [];
constructor(
  private routerPathAction: RouterPathAction,
  private currentContextService: CurrentContextService,
  private menuService: MenuService,
  public router: Router
  ) { }

  onInit() {
    this.subs.push(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        const routerPathInfo: RouterPathInfo = new RouterPathInfo();
        routerPathInfo.history = !!urlAfterRedirects ? [urlAfterRedirects] : [];
        if (!!urlAfterRedirects && urlAfterRedirects.includes('result')) {
          routerPathInfo.closeRoute = urlAfterRedirects;
        }
        this.menuService.calcAccessToDomainTeamsByCurrentDomain(this.currentContextService.getCurrentDomain());
        this.addPath(routerPathInfo);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  addPath(routerPathInfo: RouterPathInfo) {
    try {
      this.routerPathAction.loadRouterPathStart();
      if (routerPathInfo.history[0] === undefined || routerPathInfo.history[0] === null) {
        throw new ErrorEvent('path cannot be null or undefined');
      }
      this.routerPathAction.loadRouterPathSucceeded(routerPathInfo);
    } catch (error) {
      this.routerPathAction.loadRouterPathFailed(error);
    }
  }

  removeAll() {
    this.routerPathAction.removeAll();
  }
}
