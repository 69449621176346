import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { Utils } from '../../../utils/utils';
import { CriticalDataAction } from '../action/critical-data.action';
import { CriticalData } from '../reducer/critical-data.reducer';
import { IAppState } from '../../../../store/model';

@Injectable({
  providedIn: 'root'
})
export class CriticalDataService {

  constructor(
    private criticalDataAction: CriticalDataAction,
    private ngRedux: NgRedux<IAppState>
  ) {}

  public addCriticalFieldToStore(criticalFieldCode: string): void {
    this.criticalDataAction.loadCriticalStart();
    this.criticalDataAction.addCriticalFieldToStore(criticalFieldCode);
  }

  public addViewCriticalFieldsToStore(viewCriticalFields: string[]): void {
    this.criticalDataAction.loadCriticalStart();
    this.criticalDataAction.addViewCriticalFieldsToStore(viewCriticalFields);
  }

  public addAllToVisibleCriticalFieldsStore(): void {
    this.criticalDataAction.loadCriticalStart();
    let visibleCriticalFields: string[] = [];
    const criticalData: CriticalData = this.ngRedux.getState().dynamicSubStores.criticalData.datas;
    if (criticalData.viewCriticalFields && criticalData.viewCriticalFields.length) {
      visibleCriticalFields = criticalData.viewCriticalFields;
    }
    this.criticalDataAction.addAllToVisibleCriticalFieldsStore(visibleCriticalFields);
  }

  public addVisibleCriticalPdfToStore(isVisible: boolean): void {
    this.criticalDataAction.loadCriticalStart();
    this.criticalDataAction.addVisibleCriticalPdfToStore(isVisible);
  }

  public removeAllFromVisibleCriticalFieldsStore(): void {
    this.criticalDataAction.removeAllFromVisibleCriticalFieldsStore();
  }

  public removeViewCriticalFieldsStore(): void {
    this.criticalDataAction.removeViewCriticalFieldsStore();
  }

  public unsetVisibleCriticalFieldsStore(): void {
    this.criticalDataAction.unsetVisibleCriticalFieldsStore();
  }

  public updateReadCriticalFieldValueAllDone(criticalData: CriticalData): boolean {
    if (!criticalData) {
      return false;
    }
    if (!criticalData.viewCriticalFields || !criticalData.viewCriticalFields.length) {
      return Utils.notNullAndNotUndefined(criticalData.visibleCriticalPdf) ? criticalData.visibleCriticalPdf : true;
    }
    if (!criticalData.visibleCriticalFields || !criticalData.visibleCriticalFields.length) {
      return false;
    }
    let allVisible = true;
    if (Utils.notNullAndNotUndefined(criticalData.visibleCriticalPdf)) {
      criticalData.viewCriticalFields.forEach((viewCritFieldCode: string) => {
        if (!allVisible) {
          return;
        }
        allVisible = criticalData.visibleCriticalFields.some((visibleCritFieldCode: string) => visibleCritFieldCode === viewCritFieldCode) && criticalData.visibleCriticalPdf;
      });
      return allVisible;
    }
    criticalData.viewCriticalFields.forEach((viewCritFieldCode: string) => {
      if (!allVisible) {
        return;
      }
      allVisible = criticalData.visibleCriticalFields.some((visibleCritFieldCode: string) => visibleCritFieldCode === viewCritFieldCode);
    });
    return allVisible;
  }
}
