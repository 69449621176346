import { ObjectType } from './ObjectType';

export class SearchResultPagination {
  public static DEFAULT_PAGE_SIZE = 24;
  public static DEFAULT_SORT_DIRECTION = '';
  sortKey: string;
  sortLabel: string;
  sortDirection: string;
  pageSize: number;

  constructor(  sortKey: string, sortLabel: string, sortDirection: string, pageSize: number) {
    this.sortKey = sortKey;
    this.sortLabel = sortLabel;
    this.sortDirection = sortDirection;
    this.pageSize = pageSize;
  }
  public static getNewDefaultSearchResultPagination(): SearchResultPagination {
    return new SearchResultPagination('', '', SearchResultPagination.DEFAULT_SORT_DIRECTION, SearchResultPagination.DEFAULT_PAGE_SIZE);
  }
  public static getNewDefaultSearchResultPagination4Draft(): SearchResultPagination {
    return new SearchResultPagination('cdx_creation_date', '', SearchResultPagination.DEFAULT_SORT_DIRECTION, SearchResultPagination.DEFAULT_PAGE_SIZE);
  }

  public static getInitStore(): { -readonly [searchTypeKey in ObjectType]?: SearchResultPagination } {
    return  {
      DOCUMENT: SearchResultPagination.getNewDefaultSearchResultPagination(),
      ENTITY: SearchResultPagination.getNewDefaultSearchResultPagination(),
      DRAFT: SearchResultPagination.getNewDefaultSearchResultPagination4Draft(),
      TASK: SearchResultPagination.getNewDefaultSearchResultPagination()
    };
  }
}
