import { Injectable } from '@angular/core';
import { OperatorType, SearchItem } from '../../../models/search-item';
import { Aggregate } from '../../../models/aggregate';
import { EntityFilterAction } from '../../entity-search/entity-filter/action/entity-filter.action';
import { DraftFilterAction } from '../../indexation/draft-filter/action/draft-filter.action';
import { FilterAction } from '../../document-search/document-filter/action/filter.action';
import { TaskFilterAction } from '../../task-search/task-filter/action/task-filter.action';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractFilterService {

  protected abstract getFilterAction(): EntityFilterAction | FilterAction | DraftFilterAction | TaskFilterAction;

  public addFilter(filterItem: SearchItem, curAggregate: Aggregate) {
    const filterAction: FilterAction | EntityFilterAction | DraftFilterAction | TaskFilterAction = this.getFilterAction();
    if (!filterItem || !curAggregate) {
      console.error('filterItem / current aggregate is not defined');
      return;
    }
    if (SearchItem.getOperatorType(filterItem.operator) === OperatorType.RANGE) {
      filterAction.updateFilterStarted();
      try {
        filterAction.updateFilterSucceeded(filterItem, curAggregate);
      } catch (error) {
        filterAction.updateFilterFailed(error);
      }
    } else {
      filterAction.addFilterStarted();
      try {
        filterAction.addFilterSucceeded(filterItem, curAggregate);
      } catch (error) {
        filterAction.addFilterFailed(error);
      }
    }
  }

  public removeAllFilters() {
    const filterAction: FilterAction | EntityFilterAction | DraftFilterAction | TaskFilterAction = this.getFilterAction();
    filterAction.removeAllFiltersSucceeded();
  }

  public removeFiltersAndKeepLastAgg(): void {
    const filterAction: FilterAction | EntityFilterAction | DraftFilterAction | TaskFilterAction = this.getFilterAction();
    filterAction.removeFiltersAndKeepLastAgg();
  }

  public removeFilter(filterItem: SearchItem) {
    const filterAction: FilterAction | EntityFilterAction | DraftFilterAction | TaskFilterAction = this.getFilterAction();
    filterAction.removeFilterStarted();
    try {
      filterAction.removeFilterSucceeded(filterItem);
    } catch (e) {
      filterAction.removeFilterFailed(e);
    }
  }

}
