import * as _ from 'lodash';
import { select } from '@angular-redux/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, iif, Observable, of, OperatorFunction, pipe } from 'rxjs';
import { flatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { SearchItem } from '../../../models/search-item';
import { EsPage } from '../../../models/es-page';
import { Aggregate } from '../../../models/aggregate';
import { CdxDocument } from '../../../models/cdx-document';
import { StoreReducers } from '../../../../store/root.reducer';
import { StoreKeys } from '../../../models/store-keys';
import { ThesaurusService } from '../../../services/thesaurus/thesaurus.service';
import { Entity } from '../../../models/Entity';
import { EntityFilterDatas } from '../../entity-search/entity-filter/reducer/entity-filter.reducer';
import { SearchPath } from '../../../models/search-path';
import { InternalRoutes } from '../../../models/internal-routes';
import { DraftDocument } from '../../../models/DraftDocument';
import { FieldType, SearchOperator } from '../../../models/field';
import { AggregateOptions, AggregateParamsBuilder, AggregateRequestParams } from '../../../models/aggregate-request-params';
import { SearchResultPagination } from '../../../models/search-result-pagination';
import { SearchResultPaginationSort } from '../../../models/search-result-pagination-sort';
import { ObjectType } from '../../../models/ObjectType';
import { CdxTask } from '../../../models/cdx-task';
import { FilterDatas } from '../../document-search/document-filter/reducer/filter.reducer';
import { TaskFilterDatas } from '../../task-search/task-filter/reducer/task-filter.reducer';
import { AbstractCfgFieldService } from '../../field/service/abstract-cfg-field.service';
import { GedFieldState } from '../../field/reducer/ged/ged-cfg-field.reducer';
import { DraftFilterDatas } from '../../indexation/draft-filter/reducer/draft-filter.reducer';
import { CustomURLEncoder } from '../../../utils/CustomURLEncoder';

export enum AggregateQueryType {
  NUMERIC = 'numeric',
  DATE = 'date',
  TERM = 'term'
}

export enum SearchOperatorParam {
  NOT_EQ = 'ne',
  EQ = 'eq'
}

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractSearchResultService {

  public static readonly AGGREGATE_DEFAULT_SIZE = 5;
  public static readonly AGGREGATE_DEFAULT_CARDINALITY = true;
  public static readonly AGGREGATE_MAX_SIZE = 50;
  public static readonly BUCKETS_SIZE = 20;
  public static readonly AGGREGATE_SIZE_STEP = 5;
  public static readonly DOCUMENT_PAGE_SIZE = 24;
  public static readonly TASK_PAGE_SIZE = 24;
  public static readonly ENTITY_PAGE_SIZE = 24;
  public static readonly DOCUMENT_MAX_SIZE = 200;
  public static readonly AGGS_DELIMITTER = '|';
  public static readonly DESC = 'desc';
  public static readonly ASC = 'asc';

  public static readonly AGGREGATE_MISSING = 'missing';
  private pageSize ;

  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CURRENT_CONTEXT, 'datas', 'currentDomain']) currentDomain$: Observable<string>;
  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.GED_CFG_FIELD]) gedCfgFields$: Observable<GedFieldState>;
  @select([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.SEARCH_RESULT_PAGINATION, 'datas']) searchResultPaginationStore$: Observable< {-readonly [searchTypeKey in ObjectType]?: SearchResultPagination} >;

  private searchResultPaginationStore: {[searchTypeKey: string]: SearchResultPagination};
  private sortKey: string;
  private sortDirection: string;

  /**
   * Check if at least one param is search type
   * @param params
   *
   * return true if key are keyword - value (cdx_datas.numfact=33) or query param (_q=Paris)
   */
  private static hasRequestItem(params: HttpParams): boolean {
    const keys = params.keys();
    for (let index = 0; index < keys.length; index++) {
      if (keys[index] === '_q' || keys[index].indexOf('_') !== 0) {
        return true;
      }
    }
    return false;
  }

  protected static addItemPath(path: string, itemPath: string[]): string {
    return path + ':' + itemPath.toString();
    // return path + ':' + JSON.stringify(itemPath);
  }

  constructor(
    protected httpClient: HttpClient,
    protected cfgFieldService: AbstractCfgFieldService,
    protected logger: NGXLogger,
    protected thesaurusService: ThesaurusService
  ) {
    this.subscribeToSearchResultpaginationStore();
  }

  protected abstract getServiceType(): InternalRoutes;
  protected abstract getObjectType(): ObjectType;
  protected abstract getSearchItem$(): Observable<SearchItem[]>;
  protected abstract getFilterDatas$(): Observable<FilterDatas | EntityFilterDatas | DraftFilterDatas | TaskFilterDatas>;
  protected abstract getSearchListResult$(): Observable<EsPage<CdxDocument | Entity | DraftDocument | CdxTask>>;
  protected abstract getEmptyEspage(): EsPage<CdxDocument | Entity | DraftDocument | CdxTask>;
  protected abstract getSearchBaseUrl(): string;
  protected abstract getAggBaseUrl(): string;

  public abstract search(): void;
  public abstract filter(): void;
  public abstract loadAggregate(path: string, opts: AggregateOptions, checkPath?: boolean): void;
  public abstract searchDrafts(pageNumber, keepLastAgg): void;

  public abstract setNewPageToStore(page: EsPage<CdxDocument | Entity | CdxTask>);

  private subscribeToSearchResultpaginationStore(): void {
    const objectType: ObjectType = this.getObjectType();
    this.searchResultPaginationStore$.subscribe((searchResultPaginationStore) => {
      this.searchResultPaginationStore = searchResultPaginationStore;
      if (this.searchResultPaginationStore && this.searchResultPaginationStore[objectType] && this.searchResultPaginationStore[objectType].pageSize) {
        this.pageSize = this.searchResultPaginationStore[objectType].pageSize;
      } else {
        this.pageSize = AbstractSearchResultService.DOCUMENT_PAGE_SIZE;
      }
    });
  }

  protected _search(): Observable<EsPage<CdxDocument | Entity | DraftDocument | CdxTask>> {
    return this.query()
      .pipe(
        this.workspaceParams(),
        this.searchItemsParams(),
        this.filterItemsParams(),
        this.sortByKeyAndDirection(),
        this.page(0),
        // this.currentAggregateParams(), // we want to reload aggs list, from founded typedocs
        // this.log(),
        this.request()
      );
  }

  protected _getDocumentsByEntityId(entityTypeCode: string, entityId: string): Observable<EsPage<CdxDocument>> {
    const path: string = SearchPath.PREFIX_CDX_LINKS + '.' + entityTypeCode + '.' + SearchPath.META_CDX_ID;
    return this.query()
      .pipe(
        this.workspaceParams(),
        this.addKeyValueParams(path, entityId),
        this.sort(SearchPath.META_CDX_CREATION_DATE, AbstractSearchResultService.DESC),
        this.page(),
        this.noAggregateParams(),
        // this.log(),
        this.request(),
        map((response) => response as EsPage<CdxDocument>)
      );
  }

  protected _filter(): Observable<EsPage<CdxDocument | Entity | CdxTask>> {
    return this.query()
      .pipe(
        this.workspaceParams(),
        this.searchItemsParams(),
        this.filterItemsParams(),
        this.sortByKeyAndDirection(),
        this.page(0),
        this.currentAggregateParams(),
        // this.log(),
        this.request()
      );
  }

  protected _gotoPage(pageNum: number): Observable<EsPage<CdxDocument | Entity | CdxTask>> {
    return this.query()
      .pipe(
        this.workspaceParams(),
        this.searchItemsParams(),
        this.filterItemsParams(),
        this.sortByKeyAndDirection(),
        this.page(pageNum),
        this.noAggregateParams(),
        // this.log(),
        this.request()
      );
  }

  protected _loadAggregate(path: string, opts: AggregateOptions, checkPath = false): Observable<Aggregate> {
    return this.query().pipe(
      this.workspaceParams(),
      this.searchItemsParams(),
      checkPath ? this.filterItemsParams(path) : this.filterItemsParams(),
      this.requestAggregate(path, opts)
    );
  }

  public loadMoreAggregate(agg: Aggregate): void {
    const opts = {
      size: this.getSizeFromAggregate(agg)
    } as AggregateOptions;

    this.loadAggregate(agg.path, opts, true);
  }

  protected getSizeFromAggregate(agg: Aggregate): number {
    let size = agg.buckets.length;
    size = size - (size % AbstractSearchResultService.AGGREGATE_SIZE_STEP) + AbstractSearchResultService.AGGREGATE_SIZE_STEP;
    return size;
  }

  private request(): OperatorFunction<HttpParams, EsPage<CdxDocument | Entity>> {
    return switchMap(params => {
      if (AbstractSearchResultService.hasRequestItem(params)) {
        return this.httpClient.get<any>(this.getSearchBaseUrl(), { params : params});
      } else {
        return of(this.getEmptyEspage());
      }
    });
  }

  protected buildAggregateParams(path: string, options: AggregateOptions): Observable<AggregateRequestParams> {

    const searchpath: SearchPath = new SearchPath(path);
    const aggregateParamsBuilder = new AggregateParamsBuilder()
      .path(searchpath)
      .options(options);

    const aggregateParamsObservable: Observable<AggregateRequestParams> =
      iif(() => searchpath.isMeta,
        of(aggregateParamsBuilder),
        this.cfgFieldService.loadFields(searchpath.code)
          .pipe(
            map(fields => {
              aggregateParamsBuilder.fieldType(fields[searchpath.code].type);
              return aggregateParamsBuilder;
            })
          ))
        .pipe(
          map(builder => builder.build())
        );
    return aggregateParamsObservable;
  }

  protected requestAggregate(path: string, opts: AggregateOptions): OperatorFunction<HttpParams, Aggregate> {
    return switchMap(params => {
      return this.buildAggregateParams(path, opts)
        .pipe(
          flatMap(aggReqParams => this._requestAggregate(aggReqParams, params))
        );

    });
  }

  private _requestAggregate(aggReqParams: AggregateRequestParams, params: HttpParams): Observable<Aggregate> {
    switch (this.getServiceType()) {
      case InternalRoutes.DOCUMENTS:
      case InternalRoutes.ENTITIES:
        if (AbstractSearchResultService.hasRequestItem(params)) {
          return this.httpClient.get<Aggregate>(this.getAggBaseUrl() + aggReqParams.getAggregatePath(), { params : params});
        } else {
          return of();
        }
      case InternalRoutes.INDEXATION:
        return this.httpClient.get<Aggregate>(this.getAggBaseUrl() + aggReqParams.getAggregatePath(), { params : params});
    }
  }

  log<T>(message?: string): OperatorFunction<T, T> {
    return tap(e => this.logger.info('AbstractSearchResultService: ', message, e));
  }

  public workspaceParams(): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      withLatestFrom(this.currentDomain$),
      map(([params, currentDomainCode]) => {
        if (!!currentDomainCode) {
          params = params.append('_domains', currentDomainCode);
        }
        return params;
      })
    );
  }

  public searchItemsParams(pathToCheck = null): OperatorFunction<HttpParams, HttpParams> {
    let searchPathToCheck: string;
    if (pathToCheck) {
      searchPathToCheck  = new SearchPath(pathToCheck).getPathWithoutSuffix();
    }
    return pipe(
      withLatestFrom(this.getSearchItem$()),
      map(([params, searchItems]) => {
        if (!!searchItems) {
          const _q: SearchItem[] = [];
          searchItems.forEach((searchItem: SearchItem) => {
            if (!searchItem.path) {
              _q.push(searchItem);
            } else {
              const path = searchItem.path;
              const searchPath = searchItem.path ? new SearchPath(searchItem.path) : undefined;
              if (searchItem.value) {
                if (searchPath?.getPathWithoutSuffix() === searchPathToCheck) {
                  if (![FieldType.DATE, FieldType.INTEGER, FieldType.DECIMAL].includes(searchItem.fieldType)) { // Champs ne gérant pas l'opérateur ne
                    params = params.append(path + ':' + SearchOperatorParam.NOT_EQ, searchItem.value);
                  }
                } else if (searchItem.operator) {
                  params = params.append(path + ':' + searchItem.operator, searchItem.value);
                } else {
                  params = params.append(path, searchItem.value);
                }
              }
            }
          });
          if (_q.length > 0) {
            _q.forEach((searchItem: SearchItem) => {
              params = params.append('_q', searchItem.operator ? searchItem.operator + ':' + searchItem.value : searchItem.value);
            });
            // params = params.append('_q', _q.join(','));
          }
        }
        return params;
      })
    );
  }

  public filterItemsParams(path = null): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      // withLatestFrom(this.filterItems$),
      withLatestFrom(this.getFilterDatas$()),
      map(([params, filterDatas]) => {
        if (!!filterDatas && !!filterDatas.filterItems && filterDatas.filterItems.length > 0) {
          filterDatas.filterItems.forEach((filterItem: SearchItem) => {
            if (!filterItem.operator) {
              if (!path || (!!path && path !== filterItem.path)) {
                params = params.append(filterItem.path, filterItem.value);
              }
            } else {
              if (!path || (!!path && path !== filterItem.path)) {
                params = params.append(filterItem.path + ':' + filterItem.operator, filterItem.value);
              }
            }
          });
        }
        return params;
      })
    );
  }

  protected currentAggregateParams(): OperatorFunction<HttpParams, HttpParams> {
    return pipe(
      withLatestFrom(this.getSearchListResult$(), this.gedCfgFields$),
      map(([params, page, gedFields]) => {
        const clonePage: EsPage<CdxDocument | Entity | DraftDocument> = _.cloneDeep(page); // in order to not change the path of the aggs by ref
        const cloneParams: HttpParams = _.cloneDeep(params); // in order to not duplicate our params
        if (!!page && !!page.aggs) {
          const aggRequestParams$: Observable<AggregateRequestParams>[] = [];
          clonePage.aggs.forEach((agg: any) => {
            const min = cloneParams.get(agg.path + SearchItem.OPERATOR_SEPARATOR + SearchOperator.gte);
            const max = cloneParams.get(agg.path + SearchItem.OPERATOR_SEPARATOR + SearchOperator.lte);
            const itemPath: string[] = agg.itemPath;
            const aggregateOptions: AggregateOptions = new AggregateOptions();
            aggregateOptions.size = AbstractSearchResultService.AGGREGATE_DEFAULT_SIZE;
            if (!!min && !!max) {
              aggregateOptions.min = min;
              aggregateOptions.max = max;
            }
            if (!!itemPath) {
              aggregateOptions.itempath = itemPath;
            }
            aggRequestParams$.push(this.buildAggregateParams(agg.path, aggregateOptions));
          });
          return [params, combineLatest(aggRequestParams$)];
        }
        return [params, of([])];
      }),
      switchMap(([params, aggRequestParams$]: [HttpParams, Observable<AggregateRequestParams[]>]) => {
        return aggRequestParams$.pipe(
          map((aggRequestParams: AggregateRequestParams[]) => {
            const aggs: string[] = [];
            aggRequestParams.forEach((aggRequestParam: AggregateRequestParams) => {
              aggs.push(aggRequestParam.getAggregatePath());
            });
            if (aggs.length > 0) {
              params = params.set('_aggs', aggs.join(AbstractSearchResultService.AGGS_DELIMITTER));
            }
            return params;
          })
        );
      })
    );
  }

  private noAggregateParams(): OperatorFunction<HttpParams, HttpParams> {
    return map(params => params.set('_aggs', ''));
  }

  protected addPageCoord(params: HttpParams, limit: number = AbstractSearchResultService.DOCUMENT_PAGE_SIZE, offset: number = 0) {
    return params
      .set('_limit', '' + Math.min(limit, AbstractSearchResultService.DOCUMENT_MAX_SIZE))
      .set('_offset', '' + offset);
  }

  public coordParams(limit: number = AbstractSearchResultService.DOCUMENT_PAGE_SIZE, offset: number = 0): OperatorFunction<HttpParams, HttpParams> {
    return map(params => this.addPageCoord(params, limit, offset));
  }

  protected page(pageNumber: number = 0): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
            const limit = this.getPageSize();
            const offset = limit * Math.floor(pageNumber);
            return this.addPageCoord(params, limit, offset);
          });
  }

  protected query(): Observable<HttpParams> {
    const httpParams = new HttpParams({encoder: new CustomURLEncoder() });
    return of(httpParams);
  }

  private addKeyValueParams(path: string, value: string): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      return params.append(path, value);
    });
  }

  protected sort(sort: any, order: any): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      if (!!sort) {
        if (!!order) {
          sort = sort + ':' + order;
        }
        return params.append('_sort', sort);
      }
    });
  }

  protected sortByKeyAndDirection(): OperatorFunction<HttpParams, HttpParams> {
    if ( !!this.searchResultPaginationStore ) {
      const objectType: ObjectType = this.getObjectType();
      this.sortKey = this.searchResultPaginationStore[objectType].sortKey;
      this.sortDirection = this.searchResultPaginationStore[objectType].sortDirection;
    }
    return map((params) => {
      if (!!this.sortKey) {

        let sort = '';
        let order = AbstractSearchResultService.DESC;
        if (!!this.sortDirection)  {
          order = this.sortDirection;
        }
        sort = this.sortKey + ':' + order;

        params = params.append('_sort', sort);
      }

      return params;
    });
  }


  private getPageSize(): number {
    this.pageSize = AbstractSearchResultService.DOCUMENT_PAGE_SIZE;
    if ( !!this.searchResultPaginationStore ) {
      const objectType: ObjectType = this.getObjectType();
      this.pageSize = this.searchResultPaginationStore[objectType].pageSize;
    }
    return this.pageSize;
  }

  public changePageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.updateContent();
  }

  public changeSort(sort: SearchResultPaginationSort): void {
    this.sortKey = sort.sortKey;
    this.sortDirection = sort.sortDirection;
    this.updateContent();
  }

  private updateContent(): void {
    const objectType: ObjectType = this.getObjectType();
    if (objectType !== ObjectType.DRAFT) {
      this.filter();
    } else {
      this.search();
    }
  }
}
