import { ToastaService } from 'ngx-toasta';
import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { TranslateService } from '@ngx-translate/core';
import { Reducer } from 'redux';
import { DocumentDetailsConstant, DocumentDetailsState } from '../reducer/document/document-details.reducer';
import { EntityDetailsConstant, EntityDetailsState } from '../reducer/entity/entity-details.reducer';
import { TaskDetailsConstant, TaskDetailsState } from '../reducer/task/task-details.reducer';
import { IAppState } from '../../../../store/model';
import { DocumentDetails } from '../../../models/document-details';
import { EntityDetails } from '../../../models/entity-details';
import { TaskDetails } from '../../../models/task-details';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDetailsAction {
  protected localStore: ObservableStore<DocumentDetailsState | EntityDetailsState | TaskDetailsState>;

  constructor(
    protected ngRedux: NgRedux<IAppState>,
    protected translateService: TranslateService,
    protected toastaService: ToastaService,
  ) {
    this.localStore = this.ngRedux.configureSubStore(this.getDetailsStorePath(), this.getDetailsReducer());
  }

  protected abstract getDetailsStorePath(): string[];
  protected abstract getDetailsReducer(): Reducer<DocumentDetailsState | EntityDetailsState | TaskDetailsState>;

  protected _dispatchType(dispatchType: DocumentDetailsConstant | EntityDetailsConstant | TaskDetailsConstant): void {
    this.localStore.dispatch({
      type: dispatchType
    });
  }
  protected _dispatchSuccess(dispatchType: DocumentDetailsConstant | EntityDetailsConstant | TaskDetailsConstant, details: DocumentDetails | EntityDetails | TaskDetails): void {
    this.localStore.dispatch({
      type: dispatchType,
      payload: details
    });
  }
  protected _dispatchFail(dispatchType: DocumentDetailsConstant | EntityDetailsConstant | TaskDetailsConstant, error: any, showToasta = true): void {
    this.localStore.dispatch({
      type: dispatchType,
      error: error
    });
    if (showToasta) {
      this.toastaService.error(this.translateService.instant('TOASTER_MESSAGES.' + dispatchType));
    }
  }
}
