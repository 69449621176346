import { combineReducers } from 'redux';
import { composeReducers, defaultFormReducer } from '@angular-redux/form';
import { routerReducer } from '@angular-redux/router';

import { dynamicReducers } from './dynamic.reducer';
import { configurationReducer } from '../modules/configuration/configuration.reducer';

// Define the global store shape by combining our application's
// reducers together into a given structure.
export const rootReducer = composeReducers(
  defaultFormReducer(),
  combineReducers({
    router: routerReducer,
    configuration: configurationReducer,
    dynamicSubStores: dynamicReducers
  }));

export enum StoreReducers {
  ROUTER = 'router',
  CONFIGURATION = 'configuration',
  DYNAMIC_SUB_STORES = 'dynamicSubStores'
}
