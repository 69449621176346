import { HttpHeaders } from '@angular/common/http';
import { ToastaService } from 'ngx-toasta';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { TranslateService } from '@ngx-translate/core';
import { Reducer } from 'redux';

import { EsPage } from '../../../../models/es-page';

import { IAppState } from '../../../../../store/model';
import { CdxAttachment } from '../../../../models/cdx-attachment';
import { ActivityDiff, CdxActivity } from '../../../../models/cdx-activity';
import { CdxComment } from '../../../../models/cdx-comment';
import { CdxDocument } from '../../../../models/cdx-document';
import { DocumentDetailsConstant, documentDetailsReducer, DocumentDetailsState } from '../../reducer/document/document-details.reducer';
import { DocumentDetails } from '../../../../models/document-details';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { AbstractDetailsAction } from '../abstract-details.action';
import { Utils } from '../../../../utils/utils';
import { Metadata } from '../../../../models/metadata';

@Injectable({
  providedIn: 'root'
})
export class DocumentDetailsAction extends AbstractDetailsAction {

  constructor(
    ngRedux: NgRedux<IAppState>,
    translateService: TranslateService,
    toastaService: ToastaService
  ) {
    super(ngRedux, translateService, toastaService);
  }

  protected getDetailsStorePath(): string[] {
    return [StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.DOCUMENT_DETAILS];
  }

  protected getDetailsReducer(): Reducer<DocumentDetailsState> {
    return documentDetailsReducer;
  }

  public loadDetailsStart(): void {
    this._dispatchType(DocumentDetailsConstant.LOAD_DETAILS_START);
  }

  public loadNexiaObjectDetailsStart(): void {
    this._dispatchType(DocumentDetailsConstant.LOAD_DOC_DETAILS_START);
  }

  public loadMetaStart(): void {
    this._dispatchType(DocumentDetailsConstant.LOAD_META_START);
  }

  public loadCurrentActivityDiffStart(): void {
    this._dispatchType(DocumentDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_START);
  }

  public loadDetailsSucceeded(document: CdxDocument, comments: CdxComment[], attachments: CdxAttachment[], activities: EsPage<CdxActivity>): void {
    if (!document && !comments && !attachments && !activities) {
      this.loadDetailsFailed('document, comments, attachments and activities cannot be null or undefined');
      return;
    }
    const currentDocumentDetails: DocumentDetails = {
      details: document,
      comments: comments,
      attachments: attachments,
      activities: activities,
      token: document.token,
      currentActivityDiff: new ActivityDiff()
    };
    this._dispatchSuccess(DocumentDetailsConstant.LOAD_DETAILS_SUCCEEDED, currentDocumentDetails);
  }


  public loadNexiaObjectDetailsSucceeded(updateDetailsData: CdxDocument, loadPdf = false): void {
    const payload = new DocumentDetails();
    payload.details = updateDetailsData;
    payload.loadPdf = loadPdf;
    this._dispatchSuccess(DocumentDetailsConstant.LOAD_DOC_DETAILS_SUCCEEDED, payload);
  }

  public loadMetaSucceeded(meta: Metadata): void {
    const payload = new DocumentDetails();
    payload.details = new CdxDocument();
    payload.details.metadata = meta;
    this._dispatchSuccess(DocumentDetailsConstant.LOAD_META_SUCCEEDED, payload);
  }

  public loadCurrentActivityDiffSucceeded(currentActivityDiff: ActivityDiff): void {
    const payload = new DocumentDetails();
    payload.currentActivityDiff = currentActivityDiff;
    this._dispatchSuccess(DocumentDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED, payload);
  }

  public loadDetailsFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.LOAD_DETAILS_FAILED, error);
  }

  public loadNexiaObjectDetailsFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.LOAD_DOC_DETAILS_FAILED, error);
  }

  public loadMetaFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.LOAD_META_FAILED, error);
  }

  public loadCurrentActivityDiffFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_FAILED, error);
  }

  public addCommentsStart(): void {
    this._dispatchType(DocumentDetailsConstant.ADD_COMMENT_START);
  }

  public addCommentsFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.ADD_COMMENT_FAILED, error);
  }

  public updateCommentsStart(): void {
    this._dispatchType(DocumentDetailsConstant.UPDATE_COMMENT_START);
  }

  public updateCommentsFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.UPDATE_COMMENT_FAILED, error);
  }

  public loadCommentStart(): void {
    this._dispatchType(DocumentDetailsConstant.LOAD_COMMENT_START);
  }

  public loadCommentSucceeded(comment: CdxComment): void {
    if (!comment) {
      this.loadCommentFailed('Comment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.comments = [comment];
    this._dispatchSuccess(DocumentDetailsConstant.LOAD_COMMENT_SUCCEEDED, payload);
  }

  public loadCommentFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.LOAD_COMMENT_FAILED, error);
  }

  public deleteCommentStart(): void {
    this._dispatchType(DocumentDetailsConstant.DELETE_COMMENT_START);
  }

  public deleteCommentFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.DELETE_COMMENT_FAILED, error);
  }

  public addReplyStart(): void {
    this._dispatchType(DocumentDetailsConstant.ADD_REPLY_START);
  }

  public addReplyFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.ADD_REPLY_FAILED, error);
  }

  public updateReplyStart(): void {
    this._dispatchType(DocumentDetailsConstant.UPDATE_REPLY_START);
  }

  public updateReplyFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.UPDATE_REPLY_FAILED, error);
  }

  public deleteReplyStart(): void {
    this._dispatchType(DocumentDetailsConstant.DELETE_REPLY_START);
  }

  public deleteReplyFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.DELETE_REPLY_FAILED, error);
  }

  public loadHistoryStart(): void {
    this._dispatchType(DocumentDetailsConstant.LOAD_HISTORY_START);
  }

  public loadHistorySucceeded(activities: EsPage<CdxActivity>): void {
    if (!activities) {
      this.loadHistoryFailed('History cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.activities = activities;
    this._dispatchSuccess(DocumentDetailsConstant.LOAD_HISTORY_SUCCEEDED, payload);
  }

  public loadHistoryFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.LOAD_HISTORY_FAILED, error);
  }

  public loadAttachmentStart(): void {
    this._dispatchType(DocumentDetailsConstant.LOAD_ATTACHMENT_START);
  }

  public loadAttachmentSucceeded(attachment: CdxAttachment, tempAttachmentId: string = null): void {
    if (!attachment) {
      this.loadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.attachments = [attachment];
    if (tempAttachmentId) {
      const attachmentToBeChanged: CdxAttachment = new CdxAttachment();
      attachmentToBeChanged.cdx_id = tempAttachmentId;
      payload.attachments.push(attachmentToBeChanged);
    }
    this._dispatchSuccess(DocumentDetailsConstant.LOAD_ATTACHMENT_SUCCEEDED, payload);
  }

  public loadAttachmentFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.LOAD_ATTACHMENT_FAILED, error);
  }

  public uploadAttachmentStart(): void {
    this._dispatchType(DocumentDetailsConstant.UPLOAD_ATTACHMENT_START);
  }

  public uploadAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.uploadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(DocumentDetailsConstant.UPLOAD_ATTACHMENT_SUCCEEDED, payload);
  }

  public uploadAttachmentFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.UPLOAD_ATTACHMENT_FAILED, error);
  }

  public deleteAttachmentStart(): void {
    this._dispatchType(DocumentDetailsConstant.DELETE_ATTACHMENT_START);
  }

  public deleteAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.deleteAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(DocumentDetailsConstant.DELETE_ATTACHMENT_SUCCEEDED, payload);
  }

  public deleteAttachmentFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.DELETE_ATTACHMENT_FAILED, error);
  }

  public updateAttachmentStart(): void {
    this._dispatchType(DocumentDetailsConstant.UPDATE_ATTACHMENT_START);
  }

  public updateAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.updateAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new DocumentDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(DocumentDetailsConstant.UPDATE_ATTACHMENT_SUCCEEDED, payload);
  }

  public updateAttachmentFailed(error: any): void {
    this._dispatchFail(DocumentDetailsConstant.UPDATE_ATTACHMENT_FAILED, error);
  }

  public removeAll(): void {
    this._dispatchType(DocumentDetailsConstant.REMOVE_ALL_SUCCEEDED);
  }

  public updateToken(headers: HttpHeaders): void {
    const token = headers.get('Object-Authorization');
    if (Utils.notNullAndNotUndefined(token)) {
      const payload = new DocumentDetails();
      payload.token = token;
      this._dispatchSuccess(DocumentDetailsConstant.UPDATE_TOKEN, payload);
    }
  }

  public showHideInfoBlockSucceeded(isVisible: boolean): void {
    const payload = new DocumentDetails();
    payload.displayInfoBlock = isVisible;
    this._dispatchSuccess(DocumentDetailsConstant.LOAD_DISPLAY_BLOCK_SUCCEEDED, payload);
  }
}
