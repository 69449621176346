import { Injectable } from '@angular/core';
import { NgRedux, ObservableStore } from '@angular-redux/store';
import { IAppState } from '../../../../../store/model';
import { StoreReducers } from '../../../../../store/root.reducer';
import { StoreKeys } from '../../../../models/store-keys';
import { CurrentTeamUsersPageConstant, currentTeamUsersPageReducer, CurrentTeamUsersPageState } from '../reducer/current-team-users-page.reducer';
import { Page } from '../../../../models/page';
import { TeamUser } from '../../../../models/team-user';

@Injectable({
  providedIn: 'root'
})
export class CurrentTeamUsersPageAction {
  private localStore: ObservableStore<CurrentTeamUsersPageState>;

  constructor(
    private ngRedux: NgRedux<IAppState>
  ) {
    this.localStore = this.ngRedux.configureSubStore([StoreReducers.DYNAMIC_SUB_STORES, StoreKeys.CURRENT_TEAM_USERS_PAGE], currentTeamUsersPageReducer);
  }

  public loadCurrentTeamUsersPageStarted(): void {
    this.localStore.dispatch({
      type: CurrentTeamUsersPageConstant.LOAD_CURRENT_TEAM_USERS_PAGE_START
    });
  }

  public loadCurrentTeamUsersPageSucceeded(teamPage: Page<TeamUser>): void {
    this.localStore.dispatch({
      type: CurrentTeamUsersPageConstant.LOAD_CURRENT_TEAM_USERS_PAGE_SUCCEEDED,
      payload: teamPage
    });
  }

  public loadCurrentTeamUsersPageFailed(error: any) {
    this.localStore.dispatch({
      type: CurrentTeamUsersPageConstant.LOAD_CURRENT_TEAM_USERS_PAGE_FAILED,
      error: error
    });
  }
}
